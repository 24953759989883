import { CfrApiError, PaginatedListResponse, VehicleInventory } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  useCfrApiClient,
  useQueryGetListRequest,
  RequestParams,
} from 'hooks/cfr-api';

export const useVehicleInventoryList = (
  params: RequestParams
): UseQueryResult<
  PaginatedListResponse<VehicleInventory>,
  Error | CfrApiError
> => {
  const client = useCfrApiClient();

  return useQueryGetListRequest<PaginatedListResponse<VehicleInventory>>(
    client,
    'reporting/vehicle-inventory',
    params
  );
};
