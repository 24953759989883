import { CfrApiError, PaginatedListResponse } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  useCfrApiClient,
  useQueryGetListRequest,
  RequestParams,
} from 'hooks/cfr-api';
import { LoadPlan } from 'types/api/LoadPlan';

export const useLoadPlanList = (
  params: RequestParams
): UseQueryResult<PaginatedListResponse<LoadPlan>, Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetListRequest<PaginatedListResponse<LoadPlan>>(
    client,
    'reporting/load-plans',
    params
  );
};
