import { createStyles, Theme } from '@material-ui/core';

export const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(6),
      color: theme.palette.primary.main,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(4),
      color: theme.palette.grey[500],
    },
  });

export const DialogContentStyles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(6),
  },
});

export const DialogActionsStyles = (theme: Theme) => ({
  root: {
    margin: theme.spacing(5),
    padding: theme.spacing(1),
  },
});
