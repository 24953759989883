import { useEffect, useState } from 'react';
import theme from 'styles/theme';
import { throttle } from 'utils';

export type Breakpoint = 'mobile' | 'tablet' | 'desktop';

const getBreakpoint = (width: number): Breakpoint | undefined => {
  const {
    breakpoints: { values: breakpointsValues },
  } = theme;

  if (width < breakpointsValues.md) {
    return 'mobile';
  } else if (width < breakpointsValues.lg) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

export const useBreakpoint = (): Breakpoint | undefined => {
  if (typeof window === 'undefined') return;

  const [breakpoint, setBreakpoint] = useState<Breakpoint | undefined>(() =>
    getBreakpoint(document.documentElement.clientWidth)
  );

  useEffect(() => {
    const checkWindowWidth = throttle(() => {
      setBreakpoint(getBreakpoint(document.documentElement.clientWidth));
    });

    window.addEventListener('resize', checkWindowWidth);
    return () => window.removeEventListener('resize', checkWindowWidth);
  }, []);

  return breakpoint;
};
