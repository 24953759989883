import { TableContainer } from 'components/table';
import { ModuleName, VehicleInventory } from 'types';
import { useMemo, FC, useState, useEffect } from 'react';
import { Column } from 'react-table';
import { useVehicleInventoryList } from 'hooks/cfr-api';
import {
  vehicleInventoryColumns,
  vehicleInventoryCustomColumns,
} from './vehicleInventoryColumns';
import { CustomColumns } from '../../../constants';

const modelDataPk = 'Txnouuid';

interface VehiclesOverview {
  getItemsCount: (count?: number) => void;
}

export const VehiclesOverview: FC<VehiclesOverview> = ({ getItemsCount }) => {
  const [itemsCount, setItemsCount] = useState<number | undefined>();

  useEffect(() => {
    getItemsCount(itemsCount);
  }, [itemsCount]);

  const columns = useMemo(
    (): Column<VehicleInventory>[] => vehicleInventoryColumns,
    []
  );

  return (
    <TableContainer<VehicleInventory>
      columns={columns}
      customColumns={vehicleInventoryCustomColumns}
      moduleName={ModuleName.VEHICLE_INVENTORY}
      useListHook={useVehicleInventoryList}
      onDataCountLoad={setItemsCount}
      rowDataPk={modelDataPk}
      hiddenColumns={[CustomColumns.TRACKING_DETAILS]}
    />
  );
};
