import { FC, Context, createContext } from 'react';
import axios, { AxiosInstance } from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_LG_API_ENDPOINT || '',
  headers: {
    CompanyID: process.env.REACT_APP_LG_API_COMPANY_ID || '',
    Token: process.env.REACT_APP_LG_API_TOKEN || '',
  },
});

interface AxiosInstanceType {
  client: AxiosInstance;
}

const LgApiContext: Context<AxiosInstanceType> =
  createContext<AxiosInstanceType>({
    client,
  });

const LgApiProvider: FC = ({ children }) => (
  <LgApiContext.Provider
    value={{
      client,
    }}
  >
    {children}
  </LgApiContext.Provider>
);

export { LgApiContext, LgApiProvider };
