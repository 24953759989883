import { useCfrApiClient } from 'hooks/cfr-api';
import { useState } from 'react';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const extractFileName = (contentDispositionValue: string) => {
  let filename = '';
  if (
    contentDispositionValue &&
    contentDispositionValue.indexOf('attachment') !== -1
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionValue);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

const saveAs = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

const open = (data: Blob, fileName: string, contentType: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: contentType })
  );
  window.open(url);
};

export interface UseDownloadFileResponse {
  isSuccess?: boolean;
  isError?: boolean;
  isLoading: boolean;
  error: Error | null;
  download(url: string, fileName?: string): Promise<Blob>;
}

export enum DownloadType {
  OPEN = 'open',
  SAVE = 'save',
}

export const useDownloadFile = (
  type: DownloadType = DownloadType.SAVE,
  httpClient?: AxiosInstance
): UseDownloadFileResponse => {
  const client = httpClient || useCfrApiClient();
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [isError, setIsError] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | Error>(null);

  const processError = (error: AxiosError) => {
    setIsError(true);
    setIsSuccess(false);
    setIsLoading(false);
    setError(error);
  };

  const processResponse = (response: AxiosResponse, fileName?: string) => {
    setIsSuccess(true);
    setIsError(false);
    setIsLoading(false);
    fileName =
      fileName || extractFileName(response.headers['content-disposition']);

    if (type == DownloadType.SAVE) {
      saveAs(response.data, fileName);
    } else if (type == DownloadType.OPEN) {
      open(response.data, fileName, response.headers['content-type']);
    }

    return response.data;
  };

  return {
    isSuccess,
    isError,
    isLoading,
    error,
    download: (url: string, fileName?: string) => {
      setIsLoading(true);

      return client
        .get<Blob>(url, {
          responseType: 'blob',
        })
        .then((response) => processResponse(response, fileName))
        .catch(processError);
    },
  };
};
