import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Checkbox } from 'components/form/Checkbox';

export const Wrapper = styled.div`
  width: 100%;
  height: 180px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0 0 4px rgba(30, 44, 101, 0.05);
  position: relative;

  & img {
    object-fit: cover;
    overflow: hidden;
    border: none;
  }
`;

export const Text = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledCheckbox = styled(Checkbox)`
  .MuiIconButton-label > span {
    background: ${({ theme }) => theme.palette.common.white};
  }
`;
