export const isString = (value: unknown): value is string =>
  typeof value === 'string';

export const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean';

export const isArray = (value: unknown): value is [] => value instanceof Array;

export const isNullable = <T>(
  value: T | null | undefined
): value is null | undefined => value === null || value === undefined;
