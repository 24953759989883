import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const doNotCapitalizeFirstLaterIn = [
  'A',
  'THE',
  'AT',
  'ON',
  'TO',
  'UNDER',
  'IN',
  'FROM',
  'FOR',
  'AND',
];

export const formatStatusesDescription = (description: string): string =>
  capitalizeFirstLetter(
    description
      .split(/[- ]/)
      .map((el) => {
        if (!doNotCapitalizeFirstLaterIn.includes(el))
          return capitalizeFirstLetter(el.toLowerCase());
        return el.toLowerCase();
      })
      .join(' ')
  );
