import { UseQueryResult } from 'react-query/types/react/types';
import { CfrApiError, DashboardWidget } from 'types';
import { useCfrApiClient, useQueryGetRequest } from 'hooks/cfr-api/base';

export const useDashboardWidget = (
  widgetName: string,
  enabled: boolean
): UseQueryResult<DashboardWidget, Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<DashboardWidget>(
    client,
    `reporting/dashboard/widgets/${widgetName}/`,
    { enabled: enabled }
  );
};
