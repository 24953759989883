import { Box, Typography, Link } from '@material-ui/core';
import { Icon, IconName, Tooltip } from 'components/common';
import { NavLink as RouterLink } from 'react-router-dom';
import { FC, useRef } from 'react';
import { Wrapper } from './MenuItem.styles';

export interface MenuItemProps {
  icon: IconName;
  text: string;
  link: string;
  hasText?: boolean;
  iconSize?: string;
  hasTooltip?: boolean;
}

export const MenuItem: FC<MenuItemProps> = ({
  link,
  icon,
  text,
  hasText = true,
  iconSize = '2rem',
  hasTooltip = false,
}) => {
  const menuItemRef = useRef(null);

  return (
    <Wrapper $hasText={hasText}>
      <Tooltip
        placement="right"
        title={text}
        arrow={true}
        disableFocusListener={!hasTooltip}
        disableTouchListener={!hasTooltip}
        disableHoverListener={!hasTooltip}
      >
        <Link
          ref={menuItemRef}
          to={link}
          exact={true}
          activeClassName="selected"
          component={RouterLink}
        >
          <Box
            display="flex"
            width="100%"
            py={4}
            px={hasText ? 7 : 4}
            alignItems="center"
            justifyContent={hasText ? 'flex-start' : 'center'}
            color="common.white"
          >
            <Icon name={icon} mr={hasText ? 3 : 0} fontSize={iconSize} />
            {hasText && (
              <Typography variant="body1" color="inherit">
                {text}
              </Typography>
            )}
          </Box>
        </Link>
      </Tooltip>
    </Wrapper>
  );
};
