import styled, { css } from 'styled-components';
import {
  minWidth,
  maxWidth,
  MinWidthProps,
  MaxWidthProps,
} from 'styled-system';
import { CircularProgress } from '@material-ui/core';

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  overflow-x: visible;
  height: 100%;
`;

export const Shadow = styled.div<{ isHidden: boolean }>`
  position: absolute;
  content: '';
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  height: 100%;
  width: 60px;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.mobileStepper};
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

interface CellProps extends MaxWidthProps, MinWidthProps {
  left?: number;
}

export const Cell = styled.td<CellProps>`
  ${maxWidth}
  ${minWidth}

  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  line-height: 1.6rem;
  height: 48px;
  padding: ${({ theme }) => theme.spacing(1 / 2, 4, 1 / 2, 1)};
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  text-align: left;
  background-color: ${({ theme }) => theme.palette.background.paper};
  transition: background-color 0.25s;
  cursor: pointer;
  word-break: break-word;

  ${({ left }) =>
    left !== undefined
      ? css`
          left: ${left}px;
        `
      : ''}
`;

export const Th = styled(Cell)`
  cursor: default;
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  vertical-align: top;
`;

const CellHover = css`
  ${Cell} {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

export const Tr = styled.tr<{ isActive?: boolean }>`
  ${({ isActive }) => (isActive ? CellHover : '')}
  &:hover {
    ${CellHover}
  }

  &:last-child {
    ${Cell} {
      border-bottom: none;
    }
  }
`;

export const TableStyles = styled.div<{ withShadow?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 65vh;

  table {
    border-spacing: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.info.light};

    thead {
      background: ${({ theme }) => theme.palette.common.white};
      position: sticky;
      top: 0;
      z-index: 10;

      & > tr {
        position: relative;
        z-index: 10;
      }
    }
`;

export const FullWidthTable = styled(TableStyles)`
  tbody {
    display: table-row-group;
  }
`;

export const StickyTable = styled(TableStyles)`
  tbody {
    display: block;
  }

  thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    height: auto;
    display: block;
  }

  .sticky {
    position: sticky;
    z-index: 2;

    &.withShadow {
      box-shadow: 3px 0 2px ${({ theme }) => theme.palette.grey[200]};
    }
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(244, 246, 250, 0.35);
  z-index: 5;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 48%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
