import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './Tooltip.styles';

export interface TooltipStyleProps {
  borderRadius?: number;
  width?: number;
}
/*
 * Tooltip component is using findDOMNode which is deprecated in React.StrictMode.
 * Using Tooltip component we will warning in the console about it with a React.StrictMode.
 * This is can be possible solution, but need to investigate more https://material-ui.com/ru/api/root-ref/.
 */
export const Tooltip: FC<TooltipProps & TooltipStyleProps> = ({
  children,
  borderRadius,
  width,
  ...props
}) => {
  const classes = useStyles({ borderRadius, width });

  return (
    <MuiTooltip classes={classes} {...props}>
      {children}
    </MuiTooltip>
  );
};
