import { Predicate } from 'fp-ts/lib/function';

export const isTrue: Predicate<boolean> = (value) => value === true;

export const isFalse: Predicate<boolean> = (value) => value === false;

export const isPositive: Predicate<number> = (value) => value > 0;

export const isEmptyString: Predicate<string> = (value) => value.length === 0;

export const isEmptyArray: Predicate<[]> = (value) => value.length === 0;

export const noLeadingTrailingWhiteSpaces: Predicate<string> = (value) =>
  value === value.trim();

export const isAlphabeticalAndNumerical: Predicate<string> = (value) =>
  /^[A-Za-z0-9, ]+$/.test(value);

export const isAlphabetical: Predicate<string> = (value) =>
  /^[A-Za-z, ]+$/.test(value);

export const isPhoneNumberPattern: Predicate<string> = (value) =>
  /^[+]*[0-9][0-9]*$/.test(value);

export const isNumeric: Predicate<string> = (value) => !isNaN(Number(value));

export const isEmailPattern: Predicate<string> = (value) =>
  /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}/.test(value);

export const isPasswordPattern: Predicate<string> = (value) =>
  /^(?=.*?[А-ЯA-Z])(?=.*?[!$%*@]).{8,}$/.test(value);

export const isNamePattern: Predicate<string> = (value) =>
  /[A-Za-z]{2,32}/.test(value);
