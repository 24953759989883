import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Icon, IconName } from 'components';

export interface FormErrorProps {
  error: string;
}

export const FormError: FC<FormErrorProps> = ({ error }) => (
  <Box display="flex" alignItems="start" color="error.main">
    <Icon mt="1px" mr={1} fontSize={17} name={IconName.exclamationCircle} />
    <Typography variant="body2" component="p">
      {error}
    </Typography>
  </Box>
);
