import { Box, Typography } from '@material-ui/core';
import { Button, Icon, IconName } from 'components/common';
import { FC, useContext } from 'react';
import { Wrapper } from './ContactUs.styles';
import { ContactFormContext } from 'context';

export const ContactUs: FC = () => {
  const { isContactFormOpen, setIsContactFormOpen } =
    useContext(ContactFormContext);

  return (
    <Wrapper>
      <Box display="flex" width="100%" pb={4}>
        <Icon pr={2} name={IconName.envelope} />
        <Typography variant="subtitle1">Do you have a question?</Typography>
      </Box>
      <Box
        bgcolor="common.white"
        color="primary.main"
        width={150}
        borderRadius={4}
      >
        <Button
          onClick={() => setIsContactFormOpen(!isContactFormOpen)}
          fullWidth={true}
          variant="outlined"
          color="inherit"
        >
          <Box color="primary.main">Send us a message</Box>
        </Button>
      </Box>
    </Wrapper>
  );
};
