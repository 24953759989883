import { createContext, FC, useEffect, useState } from 'react';
import { EmailAttachments } from 'types';
import { Row } from 'react-table';

export type AttachmentsDrawerTypes = 'upload' | 'attachments';

interface AttachmentsContextType {
  isAttachmentsDrawerOpen: boolean;
  isEmailSectionOpen: boolean;
  checkedAttachments: EmailAttachments[];
  setIsAttachmentsDrawerOpen: (open: boolean) => void;
  setIsEmailSectionOpen: (open: boolean) => void;
  handleCloseAll: () => void;
  setCheckedAttachments: (attachments: EmailAttachments[]) => void;

  isUploadDrawerOpen: boolean;
  setIsUploadDrawerOpen: (open: boolean) => void;

  activeRow?: Row;
  setActiveRow: (row?: Row) => void;
}

export const attachmentsContextDefaultValues: AttachmentsContextType = {
  isAttachmentsDrawerOpen: false,
  isEmailSectionOpen: false,
  checkedAttachments: [],
  setIsAttachmentsDrawerOpen: () => null,
  setIsEmailSectionOpen: () => null,
  handleCloseAll: () => null,
  setCheckedAttachments: () => null,

  isUploadDrawerOpen: false,
  setIsUploadDrawerOpen: () => null,

  activeRow: undefined,
  setActiveRow: () => null,
};

const AttachmentsContext = createContext<AttachmentsContextType>(
  attachmentsContextDefaultValues
);

const AttachmentsProvider: FC = ({ children }) => {
  const [isAttachmentsDrawerOpen, setIsAttachmentsDrawerOpen] = useState(false);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
  const [isEmailSectionOpen, setIsEmailSectionOpen] = useState(false);
  const [checkedAttachments, setCheckedAttachments] = useState<
    EmailAttachments[]
  >([]);
  const [activeRow, setActiveRow] = useState<Row | undefined>();

  const handleCloseAll = () => {
    setIsAttachmentsDrawerOpen(false);
    setIsEmailSectionOpen(false);
    setIsUploadDrawerOpen(false);
  };

  useEffect(() => {
    if (!isAttachmentsDrawerOpen && !isUploadDrawerOpen) {
      setActiveRow(undefined);
    }
  }, [isAttachmentsDrawerOpen, isUploadDrawerOpen]);

  return (
    <AttachmentsContext.Provider
      value={{
        checkedAttachments,
        isAttachmentsDrawerOpen,
        isEmailSectionOpen,
        setIsEmailSectionOpen,
        setIsAttachmentsDrawerOpen,
        handleCloseAll,
        setCheckedAttachments,

        isUploadDrawerOpen,
        setIsUploadDrawerOpen,

        activeRow,
        setActiveRow,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};

AttachmentsContext.displayName = 'AttachmentsContext';
const AttachmentsConsumer = AttachmentsContext.Consumer;

export { AttachmentsProvider, AttachmentsConsumer, AttachmentsContext };
