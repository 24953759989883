import { Badge, Box, Typography } from '@material-ui/core';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  getEmailAttachments,
  getFilesByExtension,
  setFileType,
} from './AttachmentsInfo.model';
import {
  AttachmentView,
  Button,
  Checkbox,
  DocumentUploadParams,
  DocumentUploadSettings,
  Icon,
  IconName,
} from 'components';
import { IMAGE_EXTENSIONS } from '../../../constants';
import { ButtonsContainer } from './AttachmentsInfo.styles';
import { AttachBy, Attachment, EmailAttachments } from 'types';
import { AttachmentsGrid } from './AttachmentsGrid';
import { AttachmentsContext } from 'context';
import { differenceWith, isEqual } from 'lodash/fp';
import { Documents } from './Documents';
import { DocumentsSingleUpload } from './DocumentsSingleUpload';
import { DocumentsMultipleUpload } from './DocumentsMultipleUpload';
import { DownloadType, useDownloadFile } from 'hooks/cfr-api';
import axios from 'axios';

export interface AttachmentsInfoProps {
  title?: string;
  subTitle?: string;
  attachments: Attachment[];
  hasEmailSection: boolean;
  hasPhotosSection: boolean;
  hasUploadSection: boolean;
  uploadParams?: DocumentUploadParams;
  uploadDocumentsSettings?: DocumentUploadSettings[];
}

export const AttachmentsInfo: FC<AttachmentsInfoProps> = ({
  title,
  subTitle,
  attachments,
  hasEmailSection,
  hasPhotosSection,
  hasUploadSection,
  uploadParams,
  uploadDocumentsSettings = [],
}) => {
  const [isAllChecked, setAllChecked] = useState<boolean | undefined>(
    attachments.length > 0
  );

  const {
    isEmailSectionOpen,
    setIsEmailSectionOpen,
    setCheckedAttachments,
    checkedAttachments,
  } = useContext(AttachmentsContext);

  const { photos, documents, emailAttachments } = useMemo((): {
    photos: Attachment[];
    documents: Attachment[];
    emailAttachments: EmailAttachments[];
  } => {
    const attachmentsFiltered = attachments.filter((attachment) =>
      hasUploadSection
        ? attachment.AttachBy === AttachBy.API_INTEGRATION
        : attachment.AttachBy !== AttachBy.API_INTEGRATION
    );
    const photos = getFilesByExtension(attachmentsFiltered, IMAGE_EXTENSIONS);
    const documents = (
      hasUploadSection
        ? attachmentsFiltered
        : [...differenceWith(isEqual, attachmentsFiltered, photos)]
    ).sort((a, b) => {
      const d1 = new Date(a?.AttachDate ?? '');
      const d2 = new Date(b?.AttachDate ?? '');
      return d2.getTime() === d1.getTime() ? 0 : d2 > d1 ? 1 : -1;
    });

    return {
      photos: setFileType(photos),
      documents,
      emailAttachments: getEmailAttachments(
        hasPhotosSection ? attachmentsFiltered : documents
      ),
    };
  }, [attachments]);

  useEffect(() => {
    setCheckedAttachments(attachments.length > 0 ? emailAttachments : []);
  }, []);

  const handleCheckAttachments = (
    name: string,
    link: string,
    checked: boolean
  ) => {
    setAllChecked(undefined);

    const newCheckedItems = checked
      ? [...checkedAttachments, { name, link }]
      : checkedAttachments.filter((item) => item.name !== name);

    if (newCheckedItems.length === attachments.length) {
      setAllChecked(true);
    }

    setCheckedAttachments(newCheckedItems);
  };

  const onSelectAllChangeHandler = (checked: boolean) => {
    setAllChecked(checked);
    setCheckedAttachments(checked ? emailAttachments : []);
  };

  const downloadFile = useDownloadFile(DownloadType.SAVE, axios.create());

  const downloadAttachments = () => {
    checkedAttachments.forEach((attachment) =>
      downloadFile.download(attachment.link, attachment.name)
    );
  };

  return (
    <>
      <Box minHeight={50}>
        {title && (
          <Box pr={18}>
            <Typography variant="h4" component="h2" data-testid="title">
              {title}
            </Typography>
          </Box>
        )}
        {subTitle && (
          <Box color="info.main">
            <Typography
              color="inherit"
              variant="caption"
              component="h3"
              data-testid="subtitle"
            >
              {subTitle}
            </Typography>
          </Box>
        )}
      </Box>

      <ButtonsContainer>
        <Box display="flex" alignItems="center" pr={7.5}>
          <Checkbox
            label="Select all"
            labelPlacement="start"
            onChange={(_, checked) => onSelectAllChangeHandler(checked)}
            checked={isAllChecked || false}
            disabled={!attachments.length}
          />
        </Box>

        <>
          <Box pr={3}>
            <Badge badgeContent={checkedAttachments.length} color="primary">
              <Button
                size="small"
                variant="outlined"
                disabled={checkedAttachments.length == 0}
                onClick={downloadAttachments}
              >
                Download
                <Icon pl={1} fontSize="1.8rem" name={IconName.fileDownload} />
              </Button>
            </Badge>
          </Box>

          {hasEmailSection && (
            <Badge
              badgeContent={checkedAttachments.length}
              color="primary"
              data-testid="open-email-section-btn"
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => setIsEmailSectionOpen(!isEmailSectionOpen)}
              >
                Email <Icon pl={1} fontSize="1.8rem" name={IconName.envelope} />
              </Button>
            </Badge>
          )}
        </>
      </ButtonsContainer>

      {!hasUploadSection && (
        <Documents
          documents={documents}
          handleCheck={handleCheckAttachments}
          isAllChecked={isAllChecked}
        />
      )}

      {hasUploadSection && uploadParams && !uploadDocumentsSettings?.length && (
        <DocumentsSingleUpload
          documents={documents}
          handleCheck={handleCheckAttachments}
          isAllChecked={isAllChecked}
          uploadParams={uploadParams}
        />
      )}

      {hasUploadSection &&
        uploadParams &&
        !!uploadDocumentsSettings?.length && (
          <DocumentsMultipleUpload
            documents={documents}
            handleCheck={handleCheckAttachments}
            isAllChecked={isAllChecked}
            uploadParams={uploadParams}
            uploadDocumentsSettings={uploadDocumentsSettings}
          />
        )}

      {hasPhotosSection && photos?.length > 0 && (
        <Box data-testid="photo-section">
          <AttachmentsGrid title="Photo's" filesCount={photos.length}>
            {photos.map((photo) => (
              <AttachmentView
                key={photo.FileTitle + photo.AttachDate}
                checked={isAllChecked}
                file={photo}
                handleCheck={handleCheckAttachments}
              />
            ))}
          </AttachmentsGrid>
        </Box>
      )}
    </>
  );
};
