import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../constants';

export const onAxiosRequestError = (error: string) => {
  console.error(error);
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  sessionStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  window.location.href = '/login';
};
