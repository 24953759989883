import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Switch } from 'components/form';
import { useIsDevice, useShowTooltips } from 'hooks';
import { Header } from '../../../pages/ShipmentsOverview/ShipmentsOverview.styles';

interface OverviewPageHeaderProps {
  title: string;
  itemsCount?: number;
  withBorder?: boolean;
}

export const OverviewPageHeader: FC<OverviewPageHeaderProps> = ({
  title,
  itemsCount,
  withBorder = true,
}) => {
  const [showTooltip, setShowTooltip] = useShowTooltips();
  const isMobile = useIsDevice('mobile');

  const header = (
    <Box display="flex" width={1} justifyContent="space-between" mb={3}>
      <Box display="flex" alignItems="center" maxHeight={20}>
        <Typography variant="h5" component="h2">
          {title} {!!itemsCount && `(${itemsCount})`}
        </Typography>
        {itemsCount === undefined && (
          <Box ml={2}>
            <Skeleton height={40} width={80} />
          </Box>
        )}
      </Box>
      <Switch
        disabled={isMobile}
        onClick={() => setShowTooltip(!showTooltip)}
        checked={showTooltip}
        label="Tooltips"
      />
    </Box>
  );

  if (withBorder) {
    return <Header>{header}</Header>;
  }

  return header;
};
