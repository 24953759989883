import { FC, ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { TableToolBarWrapper } from './TableToolBar.styles';

export interface TableToolBarProps {
  leftBarItems: ReactNode[];
  rightBarItems: ReactNode[];
}

export const TableToolBar: FC<TableToolBarProps> = ({
  leftBarItems,
  rightBarItems,
}) => (
  <TableToolBarWrapper>
    <Grid container justify="space-between">
      <Grid container item justify="flex-start" spacing={5} xs={7}>
        {leftBarItems.map((item) => item)}
      </Grid>

      <Grid
        container
        item
        justify="flex-end"
        alignItems="center"
        spacing={5}
        xs={5}
      >
        {rightBarItems.map((item, i) => (
          <Grid key={i} item>
            {item}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </TableToolBarWrapper>
);
