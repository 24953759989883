import { Box, Typography } from '@material-ui/core';
import { Icon, IconName } from 'components/common';
import { FC, MouseEvent } from 'react';
import { CloseButtonWrapper } from './CloseButton.styles';

interface CloseButton {
  onClose: (e: MouseEvent<HTMLDivElement>) => void;
}

export const CloseButton: FC<CloseButton> = ({ onClose }) => (
  <CloseButtonWrapper onClick={onClose}>
    <Box pr={1}>
      <Typography component="p" variant="body2">
        Close
      </Typography>
    </Box>
    <Icon fontSize="2.8rem" name={IconName.times} />
  </CloseButtonWrapper>
);
