import {
  BackgroundProps,
  BorderProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  compose,
  layout,
  color,
  space,
  background,
  border,
  grid,
  position,
  shadow,
  typography,
  flexbox,
  OverflowProps,
  BackgroundColorProps,
  OpacityProps,
} from 'styled-system';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { Theme } from '@styled-system/css';

export const system = (p: { theme: Theme }): FlattenSimpleInterpolation => css`
  &&&& {
    ${compose(
      layout,
      color,
      space,
      background,
      border,
      grid,
      position,
      shadow,
      typography,
      flexbox
    )(p)}
  }
`;

export interface ColorProps extends BackgroundColorProps, OpacityProps {
  color?: string;
}

export interface System
  extends BackgroundProps,
    BorderProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    ColorProps,
    OverflowProps,
    TypographyProps {
  // SVG color properties
  fill?: ColorProps['color'];
  stroke?: ColorProps['color'];

  // css
  css?: string;
}

export type ISystem<T = undefined> = T extends Record<string, unknown>
  ? System & Omit<Omit<T, 'color'>, 'css'>
  : System;
