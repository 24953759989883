import { ComponentType, lazy } from 'react';
import { RouteChildrenProps } from 'react-router';

export interface RouteObject {
  path: string;
  exact: boolean;
  component?: ComponentType<RouteChildrenProps<any>> | ComponentType<any>;
}

const InvoicesOverview = lazy(() => import('../pages/InvoicesOverview'));
const Layout = lazy(() => import('../components/layout/Layout'));
const VehicleInventoryOverview = lazy(
  () => import('../pages/VehicleInventoryOverview')
);
const Login = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const PasswordReset = lazy(() => import('../pages/PasswordReset'));
const PasswordResetConfirm = lazy(
  () => import('../pages/PasswordResetConfirm')
);
const PasswordChange = lazy(() => import('../pages/PasswordChange'));
const ShipmentsOverview = lazy(() => import('../pages/ShipmentsOverview'));
const OrdersOverview = lazy(() => import('../pages/OrdersOverview'));
const LoadPlansOverview = lazy(() => import('../pages/LoadPlansOverview'));

export const routes: RouteObject[] = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/password-reset',
    component: PasswordReset,
    exact: true,
  },
  {
    path: '/password_reset_confirm/:uid/:token',
    component: PasswordResetConfirm,
    exact: true,
  },
];

export const privateRoutes: RouteObject[] = [
  {
    path: '/password-change',
    component: PasswordChange,
    exact: true,
  },

  {
    path: '/',
    component: Layout,
    exact: false,
  },
];

export const pagesRoutes: RouteObject[] = [
  {
    path: '/vehicle-inventory',
    component: VehicleInventoryOverview,
    exact: true,
  },
  {
    path: '/invoices',
    component: InvoicesOverview,
    exact: true,
  },
  {
    path: '/shipments',
    component: ShipmentsOverview,
    exact: true,
  },
  {
    path: '/orders',
    component: OrdersOverview,
    exact: true,
  },
  {
    path: '/load-plans',
    component: LoadPlansOverview,
    exact: true,
  },
  {
    path: '/',
    component: Dashboard,
    exact: true,
  },
];
