import { Box, TextField, TextFieldProps } from '@material-ui/core';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { IconName } from 'components/common';
import {
  StyledIcon,
  StyledLabel,
  useStyles,
  CharsCountWrapper,
  Count,
} from './Input.styles';

export interface CustomInputProps {
  maxCharsCount?: number;
}

export const Input = forwardRef<
  HTMLDivElement,
  CustomInputProps & TextFieldProps
>(
  (
    {
      type = 'text',
      InputProps = {},
      label,
      id,
      required,
      variant = 'standard',
      multiline = false,
      maxCharsCount = 0,
      onChange,
      ...props
    },
    ref
  ) => {
    const [valueLength, setValueLength] = useState(0);
    const classes = useStyles();
    const isPassword = type === 'password';
    const isTextarea = !!maxCharsCount && multiline;

    const [inputType, setInputType] = useState<TextFieldProps['type']>(type);
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);

    const handlePasswordIconClick = () => {
      setIsPasswordHidden(!isPasswordHidden);
    };

    useEffect(() => {
      if (isPassword) {
        setInputType(isPasswordHidden ? 'password' : 'text');
      }
    }, [isPasswordHidden]);

    const inputProps = {
      ...InputProps,
      endAdornment: isPassword ? (
        <>
          {isPasswordHidden ? (
            <StyledIcon
              fontSize="2rem"
              name={IconName.eye}
              onClick={handlePasswordIconClick}
              opacity={0.3}
              data-testid="icon-eye"
            />
          ) : (
            <StyledIcon
              fontSize="2rem"
              name={IconName.eyeSlash}
              onClick={handlePasswordIconClick}
              opacity={1}
              data-testid="icon-eye-slash"
            />
          )}
        </>
      ) : (
        InputProps?.endAdornment
      ),
    };

    const handleOnChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onChange && onChange(e);
      setValueLength(e.target.value.length);
    };

    return (
      <Box display="flex" flexDirection="column" data-testid="base-input">
        {label && (
          <StyledLabel variant={variant} htmlFor={id} data-testid="input-label">
            {label}
            {required && '*'}
          </StyledLabel>
        )}
        <TextField
          id={id}
          classes={classes}
          type={inputType}
          InputProps={inputProps}
          variant={variant}
          multiline={multiline}
          ref={ref}
          {...props}
          onChange={isTextarea ? handleOnChange : onChange}
        />
        {isTextarea && (
          <CharsCountWrapper isValid={valueLength <= maxCharsCount}>
            <Count
              color="inherit"
              variant="caption"
            >{`${valueLength}/${maxCharsCount}`}</Count>
          </CharsCountWrapper>
        )}
      </Box>
    );
  }
);
