import { FC, useContext } from 'react';
import { StyledWarehouseSearchInput } from './WarehouseSearchInput.styles';
import { MultiSelectFilterContext } from 'context';
import { ModuleName } from 'types';

interface WarehouseSearchInputProps {
  fieldId: string;
  moduleName?: ModuleName;
}

export const WarehouseSearchInput: FC<WarehouseSearchInputProps> = ({
  fieldId,
  moduleName,
}) => {
  const { toggleIsOpen } = useContext(MultiSelectFilterContext);

  return (
    <StyledWarehouseSearchInput
      onClick={() => toggleIsOpen(fieldId + (moduleName ?? ''))}
      variant="outlined"
      placeholder="Search"
      disabled
    />
  );
};
