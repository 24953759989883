import { CfrApiError } from 'types';
import { FC } from 'react';
import {
  Timeline,
  TimelineItem,
  Placeholder,
  PlaceholderImage,
  DrawerCell,
} from 'components';

interface DrawerTimelineCellProps {
  title: string;
  subtitle?: string;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
  error: Error | CfrApiError | null;
  hasIcon: boolean;
  hasTitle?: boolean;
  items?: TimelineItem[];
}

export const DrawerTimelineCell: FC<DrawerTimelineCellProps> = ({
  title,
  subtitle,
  isLoading,
  isError,
  refetch,
  error,
  hasIcon,
  hasTitle,
  items,
}) => (
  <>
    <DrawerCell
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
      onClickCallback={refetch}
    >
      {items && !!items.length ? (
        <Timeline hasIcon={hasIcon} items={items} hasTitle={hasTitle} />
      ) : (
        <>
          {isError && error && 'detail' in error ? (
            <Placeholder
              variant={PlaceholderImage.error}
              title="An error occurred"
              description={error.detail}
            />
          ) : (
            <Placeholder
              variant={PlaceholderImage.noData}
              title="Nothing found"
              description="No data was found"
            />
          )}
        </>
      )}
    </DrawerCell>
  </>
);
