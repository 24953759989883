import { makeStyles, Theme } from '@material-ui/core';
import { CheckboxProps } from 'components/form/Checkbox/Checkbox';

export const useStyles = makeStyles<Theme, CheckboxProps>((theme: Theme) => ({
  root: ({ displayType }) => ({
    margin: 0,
    padding: theme.spacing(1),
    color: theme.palette.divider,
    '&.Mui-checked': {
      '&, & + .MuiFormControlLabel-label': {
        color:
          displayType === 'file'
            ? theme.palette.info.main
            : theme.palette.primary.light,
      },
    },
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.info.main,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2.4rem',
    },
  }),
  disabled: {
    color: `${theme.palette.grey[300]} !important`,
  },
  label: {
    '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.primary.light,
      paddingRight: `${theme.spacing(2)}px`,
    },
  },
  labelForFile: {
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: `${theme.shape.borderRadius}px`,
    padding: `${theme.spacing(1.2)}px`,
    background: `${theme.palette.warning.contrastText}`,
    '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
      fontSize: theme.typography.caption.fontSize,
      maxWidth: 250,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  icon: {
    borderRadius: 3,
    border: `2px solid ${theme.palette.grey[300]}`,
    width: 20,
    height: 20,
    margin: '2px',
  },
}));
