import { RefObject } from 'react';
import { Th } from '../Table.styles';
import { Box } from '@material-ui/core';
import { Icon, IconName, Tooltip } from '../../../common';
import { InfoTooltipContent } from '../../InfoTooltipContent';
import { SearchInput } from '../../SearchInput';
import { ColumnInstance } from 'react-table';
import {
  SearchValue,
  FilterOperators,
  SearchItem,
  defaultSearchOperator,
} from 'hooks/cfr-api';
import { ModuleName } from 'types';
import { SearchControl } from 'react-table-config';

const getSearchOperator = (searchControl?: SearchControl): FilterOperators => {
  switch (searchControl) {
    case 'date':
      return FilterOperators.DATE;
    case 'date-range':
      return FilterOperators.DATERANGE;
    case 'select':
      return FilterOperators.IN;
    default:
      return defaultSearchOperator;
  }
};

interface HeaderCellProps<T extends object> {
  column: ColumnInstance<T>;
  left?: number;
  classNames?: string;
  showTooltip: boolean;
  tooltipRef: RefObject<HTMLDivElement>;
  requestParamValue?: SearchValue;
  moduleName?: ModuleName;
  onSearch?(field: string, item: SearchItem): void;
}

export const HeaderCell = <T extends object>({
  column,
  left,
  classNames,
  showTooltip,
  tooltipRef,
  requestParamValue,
  moduleName,
  onSearch,
}: HeaderCellProps<T>) => {
  const width = column.width + 'px';

  return (
    <Th
      as="th"
      minWidth={width}
      maxWidth={width}
      left={left}
      className={classNames}
    >
      <Box display="flex" alignItems="center" height="2rem">
        {column.render('Header')}
        {showTooltip && column.tooltip && (
          <Tooltip
            width={200}
            title={
              <InfoTooltipContent
                title={column.tooltip.title}
                description={column.tooltip.description}
              />
            }
            placement="bottom-start"
            disableFocusListener={!showTooltip}
            disableHoverListener={!showTooltip}
            disableTouchListener={!showTooltip}
          >
            <Box display="flex" {...{ ref: tooltipRef }}>
              <Icon ml={1} fontSize="1.4rem" name={IconName.infoCircle} />
            </Box>
          </Tooltip>
        )}
      </Box>

      {(column.searchable ?? true) && requestParamValue !== undefined && (
        <SearchInput
          mt={2}
          mb={1}
          requestParamValue={requestParamValue}
          type={column.searchControl}
          searchOptions={column.searchOptions}
          fieldId={column.searchField ?? column.id}
          moduleName={moduleName}
          onChange={(value) => {
            if (typeof onSearch === 'function') {
              onSearch(column.searchField ?? column.id, {
                value,
                operator: getSearchOperator(column.searchControl),
              });
            }
          }}
        />
      )}
    </Th>
  );
};
