import { makeStyles } from '@material-ui/core/styles';
import { CustomDrawerProps } from './Drawer';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, CustomDrawerProps>(() => ({
  root: ({ width }) => ({
    width,
  }),
  paper: ({ width }) => ({
    width,
  }),
}));
