import styled from 'styled-components';
import { LogoSize } from './Logo';
import { Link } from 'react-router-dom';

export const Text = styled.p`
  margin: 0;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

interface StyledImage {
  size: LogoSize;
  $hasText: boolean;
}

export const StyledImage = styled.img<StyledImage>`
  width: ${({ size }) => (size === 'medium' ? '60px' : '36px')};
  height: ${({ size }) => (size === 'medium' ? '30px' : 'auto')};
  ${({ theme, $hasText }) =>
    $hasText &&
    ` margin-right: ${theme.spacing(2)}px;
`}
`;

export const StyledLink = styled(Link)<{ $isLight: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: ${({ $isLight, theme }) =>
    $isLight ? theme.palette.common.white : theme.palette.primary.main};
`;
