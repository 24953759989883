import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './Checkbox.styles';
import * as React from 'react';
import clsx from 'clsx';

export interface CheckboxProps {
  label?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  disabled?: boolean;
  checked?: boolean;
  value?: FormControlLabelProps['value'];
  onChange?: FormControlLabelProps['onChange'];
  indeterminate?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  displayType?: 'file';
}

export const Checkbox: FC<CheckboxProps> = ({
  label = '',
  inputRef,
  indeterminate,
  displayType,
  ...props
}) => {
  const classes = useStyles({ displayType });

  return (
    <FormControlLabel
      className={clsx(classes.label, {
        [classes.labelForFile]: displayType === 'file',
      })}
      classes={{ root: classes.root, disabled: classes.disabled }}
      label={label}
      {...props}
      onClick={(e) => e.stopPropagation()}
      control={
        <MuiCheckbox
          inputRef={inputRef}
          indeterminate={indeterminate}
          color="primary"
          className={classes.root}
          icon={<span className={classes.icon} />}
        />
      }
    />
  );
};
