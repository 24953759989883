import { MenuItemType } from '../components';
import { menuItems } from '../constants';
import { useUserProfile } from './cfr-api';
import { useEffect, useState } from 'react';
import { isPermitted } from 'utils';

export const useUserMenuItems = () => {
  const { data: user } = useUserProfile();

  const [userMenuItems, setUserMenuItems] = useState<MenuItemType[]>([]);

  useEffect(() => {
    setUserMenuItems(
      menuItems.filter((item) => isPermitted(item.user_permissions ?? [], user))
    );
  }, [user]);

  return userMenuItems;
};
