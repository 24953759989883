import { Column } from 'react-table';
import { SubTableSkeleton } from 'components/table';
import { UseQueryResult } from 'react-query/types/react/types';
import { CfrApiError } from 'types';
import { SubTable } from 'components/table/SubTable';

interface WithDetails<T extends object> {
  Details?: T[];
  Charges?: T[];
}

interface SubTableContainerProps<T extends object> {
  columns: Column<T>[];
  dataPk: string;
  columnsCount: number;
  controlColumnsCount: number;
  detailDataKey: keyof WithDetails<T>;

  useDetailHook(
    pk: string
  ): UseQueryResult<WithDetails<T>, Error | CfrApiError>;
}

export const SubTableContainer = <T extends object>({
  columns,
  dataPk,
  useDetailHook,
  columnsCount,
  controlColumnsCount,
  detailDataKey,
}: SubTableContainerProps<T>) => {
  const { data, isLoading } = useDetailHook(dataPk);
  const tableData = data?.[detailDataKey] ?? [];

  return (
    <SubTableSkeleton
      isLoading={isLoading}
      columnsCount={columnsCount}
      controlColumnsCount={controlColumnsCount}
    >
      <SubTable<T>
        data={tableData}
        columns={columns}
        columnsCount={columnsCount}
        controlColumnsCount={controlColumnsCount}
      />
    </SubTableSkeleton>
  );
};
