import { ATTACHMENT_SECTION_WIDTH } from '../../../constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(5)}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  word-break: break-all;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(14, 16)};
    width: ${ATTACHMENT_SECTION_WIDTH}px;
  }
`;
