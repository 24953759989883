import { useDownloadFile, UseDownloadFileResponse } from './useDownloadFile';
import { ModuleName } from 'types';
import { SearchRequestParams, useRequestParams } from 'hooks/cfr-api';
import { MODULE_DATA_URLS } from 'hooks/cfr-api';

export interface useExportResponse
  extends Omit<UseDownloadFileResponse, 'download'> {
  doExport(searchParams: SearchRequestParams): void;
}

export const useExport = (moduleName: ModuleName): useExportResponse => {
  const { download, error, isError, isLoading, isSuccess } = useDownloadFile();

  return {
    error,
    isError,
    isLoading,
    isSuccess,
    doExport: async (searchParams: SearchRequestParams) => {
      const queryString = useRequestParams({ search: searchParams }) || '';
      await download(`${MODULE_DATA_URLS[moduleName]}/export/${queryString}`);
    },
  };
};
