import { useState, useEffect } from 'react';
import { Breakpoint, useBreakpoint } from 'hooks/useBreakpoint';

export const useIsDevice = (breakpointDevice: Breakpoint): boolean => {
  const breakpoint = useBreakpoint();
  const [isDevice, setIsDevice] = useState(breakpoint === breakpointDevice);

  useEffect(() => {
    setIsDevice(breakpoint === breakpointDevice);
  }, [breakpoint]);

  return isDevice;
};
