import { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog as MuiDialog, Typography, Box } from '@material-ui/core';
import { DialogTitle } from './DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button } from '../Button';
import { DialogContentStyles, DialogActionsStyles } from './Dialog.styles';

const DialogContent = withStyles(DialogContentStyles)(MuiDialogContent);

const DialogActions = withStyles(DialogActionsStyles)(MuiDialogActions);

export interface CustomDialogProps {
  isOpen: boolean;
  dialogTitle: string;
  applyButtonTitle?: string;
  cancelButtonTitle?: string;
  onClose?: () => void;
  onApply?: () => void;
}

export const Dialog: FC<CustomDialogProps> = ({
  isOpen,
  dialogTitle,
  applyButtonTitle = 'Apply',
  cancelButtonTitle = 'Cancel',
  children,
  onClose,
  onApply,
}) => (
  <MuiDialog
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    open={isOpen}
    data-testid="dialog"
  >
    <DialogTitle id="customized-dialog-title" onClose={onClose}>
      {dialogTitle}
    </DialogTitle>
    <DialogContent dividers>
      <Typography gutterBottom variant="body2">
        {children}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Box width={100}>
        <Button
          onClick={onClose}
          color="default"
          fullWidth={true}
          data-testid="dialogCancelButton"
        >
          {cancelButtonTitle || 'Cancel'}
        </Button>
      </Box>
      <Box width={100}>
        <Button
          autoFocus
          onClick={onApply}
          color="primary"
          fullWidth={true}
          data-testid="dialogApplyButton"
        >
          {applyButtonTitle || 'Apply'}
        </Button>
      </Box>
    </DialogActions>
  </MuiDialog>
);
