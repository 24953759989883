import { AlertProps } from '@material-ui/lab';
import { createContext, FC, useState } from 'react';

type NotificationInfo = { status: AlertProps['severity']; text: string };

interface NotificationContextType {
  isNotificationOpen: boolean;
  notificationInfo: NotificationInfo;
  setIsNotificationOpen(open: boolean): void;
  setNotificationInfo(notificationInfo: NotificationInfo): void;
}

export const snackbarContextDefaultValues: NotificationContextType = {
  isNotificationOpen: false,
  notificationInfo: { status: 'success', text: '' },
  setNotificationInfo: () => null,
  setIsNotificationOpen: () => null,
};

const NotificationContext = createContext<NotificationContextType>(
  snackbarContextDefaultValues
);

const NotificationProvider: FC = ({ children }) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState<NotificationInfo>({
    status: 'success',
    text: '',
  });

  return (
    <NotificationContext.Provider
      value={{
        isNotificationOpen,
        notificationInfo,
        setIsNotificationOpen,
        setNotificationInfo,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContext.displayName = 'NotificationContext';
const NotificationConsumer = NotificationContext.Consumer;

export { NotificationProvider, NotificationConsumer, NotificationContext };
