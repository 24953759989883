import { FC, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { StyledInput } from './SearchInput.styles';
import { MarginProps } from 'styled-system';
import { Box } from '@material-ui/core';
import { SearchDateInput } from './SearchDateInput';
import { SearchDateRangeInput } from './SearchDateRangeInput';
import { SearchValue } from 'hooks/cfr-api';
import { SearchControl, SearchOption } from 'react-table-config';
import { WarehouseSearchInput } from './WarehouseSearchInput';
import { ModuleName } from 'types';
import { SearchSelect } from './SeachSelect';

const debounceTime = 500;

export interface SearchInputProps extends MarginProps {
  onChange(value: SearchValue): void;
  fieldId: string;
  moduleName?: ModuleName;
  requestParamValue?: SearchValue;
  type?: SearchControl;
  searchOptions?: readonly SearchOption[];
}

export const SearchInput: FC<SearchInputProps> = ({
  onChange,
  requestParamValue = '',
  type,
  fieldId,
  moduleName,
  searchOptions,
  ...props
}) => {
  const [value, setValue] = useState(requestParamValue);

  useEffect(() => {
    if (!requestParamValue && value) {
      setValue(requestParamValue);
    }
  }, [requestParamValue]);

  const debounceValueChange = debounce(
    (value: SearchValue) => onChange(value),
    debounceTime
  );

  const onValueChange = (value: SearchValue) => {
    debounceValueChange(value);
    setValue(value);
  };

  const getSearchField = () => {
    switch (type) {
      case 'date':
        return <SearchDateInput value={value} onDateChange={onValueChange} />;
      case 'date-range':
        return (
          <SearchDateRangeInput
            value={value}
            fieldId={fieldId}
            onDateChange={onValueChange}
          />
        );
      case 'warehouse':
        return (
          <WarehouseSearchInput moduleName={moduleName} fieldId={fieldId} />
        );

      case 'select':
        return (
          <SearchSelect
            value={value}
            searchOptions={searchOptions}
            onChange={(value) => {
              setValue(value);
              onChange(value);
            }}
          />
        );

      default:
        return (
          <StyledInput
            variant="outlined"
            value={value}
            placeholder="Search"
            onChange={(event) => onValueChange(event.target.value)}
          />
        );
    }
  };

  return <Box {...props}>{getSearchField()}</Box>;
};
