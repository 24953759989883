import { useState, useEffect, FC, CSSProperties } from 'react';
import { StyledList } from './PasswordValidationHint.styles';
import { Rule } from './Rule';

interface PasswordProps {
  value: string;
  valueAgain?: string;
  minLength?: number;
  iconSize?: number;
  validColor?: string;
  invalidColor?: string;
  onChange?: (isValid: boolean) => any;
  messages?: {
    [key in RuleNames]?: string;
  };
}

export type RuleNames =
  | 'length'
  | 'specialChar'
  | 'number'
  | 'capital'
  | 'match';

export interface ReactPasswordChecklistProps extends PasswordProps {
  className?: string;
  style?: CSSProperties;
  rules: readonly RuleNames[];
}

export const PasswordValidationHint: FC<ReactPasswordChecklistProps> = ({
  className,
  style,
  rules,
  value,
  valueAgain,
  minLength,
  onChange,
  messages = {},
  ...remainingProps
}) => {
  const [isValid, setIsValid] = useState(false);

  const ruleDefinitions: {
    [key in RuleNames]: { valid: boolean; message: string };
  } = {
    length: {
      valid: value.length >= (minLength || 100),
      message: messages.length || `${minLength} characters long`,
    },
    specialChar: {
      valid: /[!$%*@]/g.test(value),
      message: messages.specialChar || 'Symbol @$%!*',
    },
    number: {
      valid: /\d/g.test(value),
      message: messages.number || 'Password has a number',
    },
    capital: {
      valid: (() => {
        let i = 0;
        if (value.length === 0) {
          return false;
        }
        while (i < value.length) {
          const character = value.charAt(i);
          if (character == character.toLowerCase()) {
            // Character is lowercase, numeric, or a symbol
          } else if (character == character.toUpperCase()) {
            return true;
          }
          i++;
        }
        return false;
      })(),
      message: messages.capital || 'Uppercase letter',
    },
    match: {
      valid: value.length > 0 && value === valueAgain,
      message: messages.match || 'Passwords match',
    },
  };

  const enabledRules = rules.filter((rule) => !!ruleDefinitions[rule]);

  useEffect(() => {
    setIsValid(enabledRules.every((rule) => ruleDefinitions[rule].valid));
  }, [value, valueAgain]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(isValid);
    }
  }, [isValid]);

  return (
    <StyledList className={className} style={style}>
      {enabledRules.map((rule) => {
        const { message, valid } = ruleDefinitions[rule];

        return (
          <Rule key={rule} valid={valid} {...remainingProps}>
            {message}
          </Rule>
        );
      })}
    </StyledList>
  );
};
