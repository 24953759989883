import {
  isAlphabeticalAndNumerical,
  isAlphabetical,
  isEmailPattern,
  isEmptyString,
  isEmptyArray,
  isNamePattern,
  isNumeric,
  isPasswordPattern,
  isPhoneNumberPattern,
  noLeadingTrailingWhiteSpaces,
  isFalse,
  isPositive,
} from 'utils/predicates';
import {
  isString,
  Validation,
  valid,
  invalid,
  isNullable,
  isBoolean,
  isArray,
} from 'utils';
import { not, Predicate } from 'fp-ts/lib/function';

export enum ValidatorError {
  REQUIRED_FIELD = 'This field is required.',
  NON_EMPTY_STRING = 'Should not be empty.',
  NO_LEADING_TRAILING_WHITE_SPACES = 'Should not have leading or trailing white spaces.',
  ONLY_ALPHABETICAL_AND_NUMERICAL = 'Should have only alphabetical and numerical characters.',
  ONLY_ALPHABETICAL = 'Should have only alphabetical and numerical characters.',
  ONLY_NUMERIC = 'Should have only numeric characters.',
  INVALID_PHONE_NUMBER_PATTERN = 'Please enter a valid phone number (eg: +31000000000).',
  INVALID_EMAIL_PATTERN = 'Please enter a valid email (example@email.com).',
  INVALID_PASSWORD_PATTERN = 'Your password must have at least 8 characters. It must have at least one uppercase and one specific character from @$%!*',
  PASSWORD_MISMATCH = 'Password mismatch.',
  INVALID_NAME_PATTERN = 'Your name must be between 2 and 32 characters long. It must have only alphabetical characters',
  IS_NOT_POSITIVE = 'Should be positive.',
  NO_NAN = 'Should not be NaN.',
  LESS_THAN_150_CHARACTERS = 'Should have less than 150 characters.',
  FILE_SIZE_LESS_THAN_3MB = 'file should be less than 3 MB.',
  FILE_SIZE_LESS_THAN_25MB = 'Files size should be less than 25 MB.',
}

export type Validator<T> = (value: T) => Validation<T>;

export const fromPredicate =
  <T>(predicate: Predicate<T>, errorMessage: string): Validator<T> =>
  (value) =>
    predicate(value) ? valid(value) : invalid(errorMessage);

export const isRequiredValidator = <T>(
  value: T | null | undefined
): Validation<T> => {
  if (isNullable(value)) {
    return invalid(ValidatorError.REQUIRED_FIELD);
  } else if (isString(value) && isEmptyString(value)) {
    return invalid(ValidatorError.NON_EMPTY_STRING);
  } else if (isBoolean(value) && isFalse(value)) {
    return invalid(ValidatorError.REQUIRED_FIELD);
  } else if (isArray(value) && isEmptyArray(value)) {
    return invalid(ValidatorError.REQUIRED_FIELD);
  }
  return valid(value);
};

export const noLeadingTrailingWhiteSpacesValidator: Validator<string> =
  fromPredicate(
    noLeadingTrailingWhiteSpaces,
    ValidatorError.NO_LEADING_TRAILING_WHITE_SPACES
  );

export const isAlphabeticalAndNumericalValidator: Validator<string> =
  fromPredicate(
    isAlphabeticalAndNumerical,
    ValidatorError.ONLY_ALPHABETICAL_AND_NUMERICAL
  );

export const isAlphabeticalValidator: Validator<string> = fromPredicate(
  isAlphabetical,
  ValidatorError.ONLY_ALPHABETICAL
);

export const isNumericValidator: Validator<string> = fromPredicate(
  isNumeric,
  ValidatorError.ONLY_NUMERIC
);

export const isPhoneNumberPatternValidator: Validator<string> = fromPredicate(
  isPhoneNumberPattern,
  ValidatorError.INVALID_PHONE_NUMBER_PATTERN
);

export const isEmailPatternValidator: Validator<string> = fromPredicate(
  isEmailPattern,
  ValidatorError.INVALID_EMAIL_PATTERN
);

export const isPasswordPatternValidator: Validator<string> = fromPredicate(
  isPasswordPattern,
  ValidatorError.INVALID_PASSWORD_PATTERN
);

export const makeIsEqualToPasswordValidator =
  (password: string): Validator<string> =>
  (confirmPassword) =>
    confirmPassword === password
      ? valid(confirmPassword)
      : invalid(ValidatorError.PASSWORD_MISMATCH);

export const isNamePatternValidator: Validator<string> = fromPredicate(
  isNamePattern,
  ValidatorError.INVALID_NAME_PATTERN
);

export const isPositiveValidator: Validator<number> = fromPredicate(
  isPositive,
  ValidatorError.IS_NOT_POSITIVE
);

export const isNotNaNValidator: Validator<number> = fromPredicate(
  not(isNaN),
  ValidatorError.NO_NAN
);

export const isStringLengthLessThan150: Validator<string> = (value: string) =>
  value.length <= 150
    ? valid(value)
    : invalid(ValidatorError.LESS_THAN_150_CHARACTERS);

export const isFileSizeLessThan3MB: Validator<number> = (value: number) =>
  value <= 3145728
    ? valid(value)
    : invalid(ValidatorError.FILE_SIZE_LESS_THAN_3MB);

export const isFileSizeLessThan25MB: Validator<number> = (value: number) =>
  value <= 26214400
    ? valid(value)
    : invalid(ValidatorError.FILE_SIZE_LESS_THAN_25MB);
