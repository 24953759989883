import { ReactNode, FC } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryContext as ReactQueryProvider } from './ReactQueryContext';
import { ThemeContext as ThemeContextProvider } from './ThemeContext';
import { CfrApiProvider } from './CfrApiContext';
import { AttachmentsProvider } from './AttachmentsContext';
import { ContactFormProvider } from './ContactFormContext';
import { MultiSelectFilterProvider } from './MultiSelectFilterContext';
import { NotificationProvider } from './NotificationContext';
import { LgApiProvider } from './LgApiContext';
import { ExternalUserIdProvider } from './ExternalUserIdContext';

import theme from 'styles/theme';

interface ContextProvidersProps {
  children: ReactNode;
}

/**
 * ContextProviders wrapper component to abstract all providers into a single
 * provider that is used in the App component.
 *
 */
export const ContextProviders: FC<ContextProvidersProps> = ({
  children,
}: ContextProvidersProps) => (
  <HelmetProvider>
    <ReactQueryProvider>
      <ExternalUserIdProvider>
        <CfrApiProvider>
          <LgApiProvider>
            <ContactFormProvider>
              <MultiSelectFilterProvider>
                <NotificationProvider>
                  <AttachmentsProvider>
                    <ThemeContextProvider theme={theme}>
                      {children}
                    </ThemeContextProvider>
                  </AttachmentsProvider>
                </NotificationProvider>
              </MultiSelectFilterProvider>
            </ContactFormProvider>
          </LgApiProvider>
        </CfrApiProvider>
      </ExternalUserIdProvider>
    </ReactQueryProvider>
  </HelmetProvider>
);
