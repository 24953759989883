import { ModuleName, Filter, CfrApiError } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  useCfrApiClient,
  useQueryGetRequest,
  MODULE_DATA_URLS,
} from 'hooks/cfr-api';

export const useFilters = (
  moduleName: ModuleName
): UseQueryResult<Filter[], Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<Filter[]>(
    client,
    `${MODULE_DATA_URLS[moduleName]}/filters/`
  );
};
