import { FC, ReactNode } from 'react';
import { FlexTabPanel } from './TabPanel.styles';

export interface TabPanelProps {
  children: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <FlexTabPanel
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && <FlexTabPanel>{children}</FlexTabPanel>}
  </FlexTabPanel>
);
