import { Box, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  Img,
  useStyles,
  Container,
  StyledTypography,
  Description,
  Wrapper,
} from './NewsCarousel.styles';
import { Icon, IconName } from 'components/common';
// import { DEFAULT_REQUEST_PARAMS } from 'hooks/cfr-api/base';
// import { useNewsList } from 'hooks/cfr-api';

const slides = [
  {
    id: 1,
    title: 'Stay up to date with the latest shipping news',
    image: '/images/car.png',
    description: `Ever think about finally buying that classic car you love from back in the day, but too intimidated by the whole process? We’re here to ease some of those concerns and provide you with some tips to make the process as smooth as possible! We know that pulling the trigger on a new car purchase…`,
    link: 'https://cfrrinkens.com/news/',
  },
  {
    id: 2,
    title: 'Have a dream car? Read this.',
    image: 'images/Moto.png',
    description: `Want to stay updated? Get the latest news on the shipping industry, port
    statuses, and tips for shipping your vehicle and cargo in the CFR Classic'
    news section.`,
    link: 'https://cfrrinkens.com/news/',
  },
  {
    id: 3,
    title: 'Shipping cars to Europe? This is how you save money!',
    image: '/images/Car_red.png',
    description: `Are you planning to ship one or several cars to Europe? Import duties in Germany are more favorable compared to Holland:`,
    link: 'https://cfrrinkens.com/news/',
  },
];

export interface NewsCarouselProps {
  autoPlay: boolean;
  showArrows: boolean;
  showThumbs: boolean;
  showStatus: boolean;
  showIndicators: boolean;
  infiniteLoop: boolean;
  interval: number;
}

export const NewsCarousel: FC<NewsCarouselProps> = ({ ...props }) => {
  const classes = useStyles();
  // const { data } = useNewsList({ ...DEFAULT_REQUEST_PARAMS, limit: 20 });
  // const slides = data?.results ?? [];

  return (
    <ReactResponsiveCarousel className={classes.carousel} {...props}>
      {slides.map((slide) => (
        <Box key={slide.id} height="100%">
          <Img alt={slide.image} src={slide.image} height="100%" />
          <Container>
            <Link
              href="https://cfrrinkens.com/news/"
              target="_blank"
              component="a"
              color="inherit"
              display="inline"
            >
              <StyledTypography variant="body1">CFR News</StyledTypography>
            </Link>
            <Box pb={2}>
              <Typography variant="h4">{slide?.title} </Typography>
            </Box>
            <Description variant="body1">{slide?.description}</Description>
            {slide.link && (
              <Link
                href={slide.link}
                target="_blank"
                component="a"
                color="inherit"
                display="inline"
              >
                <Wrapper>
                  <Typography variant="h6">Go to CFR News</Typography>
                  <Icon fontSize="1.8rem" name={IconName.arrowRight} ml={1} />
                </Wrapper>
              </Link>
            )}
          </Container>
        </Box>
      ))}
    </ReactResponsiveCarousel>
  );
};
