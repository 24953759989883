import { BillOfLanding, CfrApiError } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import { useCfrApiClient, useQueryGetRequest } from 'hooks/cfr-api';

export const useBillOfLanding = (
  Txnouuid: string,
  enabled?: boolean
): UseQueryResult<BillOfLanding, Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<BillOfLanding>(
    client,
    `reporting/bill-of-landing/${Txnouuid}/`,
    { enabled }
  );
};
