import { FC } from 'react';
import { AttachmentsGrid } from '../AttachmentsGrid';
import { setFileType } from '../AttachmentsInfo.model';
import { AttachmentView } from '../../AttachmentView';
import { Box } from '@material-ui/core';
import { DocumentUpload, DocumentUploadParams } from '../DocumentUpload';
import { DocumentsBaseProps } from '../Documents';

interface UploadDocumentsProps extends DocumentsBaseProps {
  uploadParams: DocumentUploadParams;
}

export const DocumentsSingleUpload: FC<UploadDocumentsProps> = ({
  documents,
  isAllChecked,
  handleCheck,
  uploadParams,
}) => (
  <Box pb={10} data-testid="documents-single-upload">
    <AttachmentsGrid title="Documents" filesCount={documents.length}>
      <DocumentUpload uploadParams={uploadParams} discardAfterUpload={true} />

      {setFileType(documents).map((document) => (
        <AttachmentView
          key={document.FileTitle + document.AttachDate}
          file={document}
          checked={isAllChecked}
          handleCheck={handleCheck}
        />
      ))}
    </AttachmentsGrid>
  </Box>
);
