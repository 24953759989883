import { Box } from '@material-ui/core';
import { FC } from 'react';
import { UserNavigation } from './UserNavigation';
import { ExternalUserIdSelector } from './ExternalUserIdSelector';
import {
  useUserProfile,
  getUserFullName,
  getUserInitials,
} from 'hooks/cfr-api';

export const Header: FC = () => {
  const { data: user } = useUserProfile();

  return (
    <Box
      width="100%"
      component="header"
      bgcolor="primary.main"
      py={3}
      color="common.white"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      minHeight={{ xs: 46, md: 67 }}
    >
      {user && (
        <>
          <ExternalUserIdSelector external_user_ids={user.external_user_ids} />
          <UserNavigation
            label={getUserFullName(user)}
            iconText={getUserInitials(user)}
          />
        </>
      )}
    </Box>
  );
};
