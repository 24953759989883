import { useEffect } from 'react';
import { useLocalStorage } from 'hooks/useLocaleStorage';
import { LOCAL_STORAGE_USER_MODULE_COLUMNS } from '../constants';
import { useUserProfile } from 'hooks/cfr-api';
import isEqual from 'lodash/isEqual';
import { ModuleColumn } from 'types';
import { fromArrayOfObjects } from 'utils';

export interface UserModuleColumn extends ModuleColumn {
  show: boolean;
}

export interface UserModulesColumns {
  [key: string]: UserModuleColumn[];
}
export const useUserModuleColumns = (
  module: string
): [UserModuleColumn[], (columns: UserModuleColumn[]) => void] => {
  const [userModulesColumns, setUserModulesColumns] =
    useLocalStorage<UserModulesColumns>(LOCAL_STORAGE_USER_MODULE_COLUMNS, {});

  const { data: user } = useUserProfile();

  useEffect(() => {
    if (!userModulesColumns || !user) {
      return;
    }

    const newUserModuleColumns = getUserModuleColumns();

    if (!isEqual(userModulesColumns[module], newUserModuleColumns)) {
      setUserModulesColumns({
        ...userModulesColumns,
        [module]: newUserModuleColumns,
      });
    }
  }, [userModulesColumns, user]);

  const getUserModuleColumns = () => {
    const userModuleColumnsObject = getUserModuleColumnsObject();

    return (
      user?.modules_columns[module]?.map((column: ModuleColumn) => ({
        ...column,
        show: userModuleColumnsObject[column.name]?.show ?? true,
      })) || []
    );
  };

  const getUserModuleColumnsObject = () =>
    fromArrayOfObjects<UserModuleColumn>(
      userModulesColumns[module] ?? [],
      'name'
    );

  const saveColumns = (columns: UserModuleColumn[]) => {
    setUserModulesColumns({ ...userModulesColumns, [module]: columns });
  };

  return [userModulesColumns[module] ?? [], saveColumns];
};
