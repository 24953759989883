import {
  Grid,
  Box,
  Typography,
  Divider,
  Link,
  Hidden,
} from '@material-ui/core';
import { Logo, FormError } from 'components';
import { useIsDevice } from 'hooks';
import { FC } from 'react';
import {
  Wrapper,
  Copyright,
  Content,
  LogoWrapper,
  Main,
  MainContent,
  StyledLink,
} from './FormLayout.styles';
import { NewsCarousel } from './NewsCarousel';

interface FormLayoutProps {
  title: string;
  error?: string;
  subtitle?: JSX.Element | string;
}

export const FormLayout: FC<FormLayoutProps> = ({
  title,
  error,
  subtitle,
  children,
}) => {
  const isMobile = useIsDevice('mobile');

  return (
    <Wrapper>
      <Hidden smDown>
        <Grid item md={5}>
          <Box width="100%" height="100%" position="relative">
            <NewsCarousel
              autoPlay
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              showIndicators={true}
              infiniteLoop
              interval={7000}
            />
          </Box>
        </Grid>
      </Hidden>

      <Grid item xs={12} md={7}>
        <Content>
          {isMobile ? (
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <Link
                href="https://cfrrinkens.com/news/"
                target="_blank"
                component="a"
              >
                <StyledLink variant="subtitle1">CFR news</StyledLink>
              </Link>
            </Box>
          ) : (
            <LogoWrapper>
              <Logo hasText={true} />
            </LogoWrapper>
          )}

          <Main>
            <MainContent>
              <Box pb={{ md: 1 }}>
                <Typography variant="h4" component="h2">
                  {title}
                </Typography>
              </Box>

              {subtitle && (
                <Box pb={1} pt={2} color="primary.light">
                  <Typography component="p">{subtitle}</Typography>
                </Box>
              )}

              {error && (
                <Box mb={-1} pt={3}>
                  <FormError error={error} />
                </Box>
              )}

              <Box mt={7} mb={8}>
                <Divider />
              </Box>
              {children}
            </MainContent>
          </Main>
          <Copyright>
            <Typography component="p" variant="overline" align="center">
              &copy; 2021 CFR Classic
            </Typography>
          </Copyright>
        </Content>
      </Grid>
    </Wrapper>
  );
};
