import { FC, MouseEvent } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.info.main};

  a {
    color: ${({ theme }) => theme.palette.info.main};
  }

  &:hover {
    text-decoration: underline;
  }
`;

interface LinkCellProps {
  onClick?(event: MouseEvent<HTMLElement>): void;
}

export const LinkCell: FC<LinkCellProps> = ({ children, ...props }) => (
  <Container {...props} data-testid="link-cell">
    <Typography variant="caption">{children}</Typography>
  </Container>
);
