import styled from 'styled-components';

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;

  & li:last-child {
    margin-bottom: 0;
  }
`;

export const StyledListItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  & > span {
    padding-top: 2px;
  }
`;
