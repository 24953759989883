import { EmailAttachments } from 'types';

export interface FormFields {
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  message: string;
  files: File[];
}

type OptionErrors = { [key: string]: string };

export interface FormErrors {
  to: string;
  message: string;
  files: string;
  autocompleteOptions: OptionErrors;
}

interface FormDataFields extends FormFields {
  attachments: EmailAttachments[];
}

export const getFormData = (data: FormDataFields): FormData => {
  const formData = new FormData();

  data.files.forEach((file) => {
    formData.append('files', file);
  });

  if (data.cc.length) {
    formData.append('cc', JSON.stringify(data.cc));
  }

  if (data.bcc.length) {
    formData.append('bcc', JSON.stringify(data.bcc));
  }

  formData.append('to', JSON.stringify(data.to));
  formData.append('message', data.message);
  formData.append('subject', data.subject);
  formData.append('attachments', JSON.stringify(data.attachments));

  return formData;
};
