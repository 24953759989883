import { BillOfLanding, CfrApiError, PaginatedListResponse } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  useCfrApiClient,
  useQueryGetListRequest,
  RequestParams,
} from 'hooks/cfr-api';

export const useBillOfLandingList = (
  params: RequestParams
): UseQueryResult<
  PaginatedListResponse<BillOfLanding>,
  Error | CfrApiError
> => {
  const client = useCfrApiClient();

  return useQueryGetListRequest<PaginatedListResponse<BillOfLanding>>(
    client,
    'reporting/bill-of-landing',
    params
  );
};
