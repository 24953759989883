import { WarehouseReceipt } from 'types';
import { Column, Row } from 'react-table';
import { Status, FormatDate, OpenAttachmentsCell } from 'components/table';
import { CustomColumns } from '../../../constants';

export const freightInventoryColumns: Column<WarehouseReceipt>[] = [
  {
    accessor: 'StatusID',
    Cell: ({ value }) => <Status value={value} />,
    isSticky: true,
    searchable: false,
  },
  {
    accessor: 'WRnumber',
    isSticky: true,
    width: 180,
    Cell: ({ row, value }) => (
      <OpenAttachmentsCell id={row.original.Txnouuid} title={value} row={row} />
    ),
  },
  {
    accessor: 'DateIn',
    Cell: FormatDate,
    searchControl: 'date-range',
    width: 130,
  },
  {
    accessor: 'WarehouseID',
    width: 120,
  },
  {
    accessor: 'SName',
    width: 170,
  },
  {
    accessor: 'CName',
    width: 170,
  },
  {
    accessor: 'ProNumbers',
    searchField: 'ProNumbers__ProNumber',
    width: 250,
  },
  {
    accessor: 'WarehouseID',
    searchControl: 'warehouse',
  },
];

export const freightCustomColumns: Column<WarehouseReceipt>[] = [
  {
    id: CustomColumns.UPLOAD_ATTACHMENTS,
    width: 150,
    searchable: false,
    Header: 'Upload Documents',
    Cell: ({ row }: { row: Row<WarehouseReceipt> }) => (
      <OpenAttachmentsCell
        id={row.original.Txnouuid}
        title="Upload"
        type="upload"
        row={row}
      />
    ),
    positionIndex: 2,
    tooltip: {
      title: 'Upload Attachments',
      description: 'Upload required documents',
    },
  },
];
