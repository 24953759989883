import { DocumentUploadSettings } from 'components';

export const vehicleInventoryDocumentsSettings: DocumentUploadSettings[] = [
  {
    title: 'Bill of Sale',
    code: 'BOS',
  },
  {
    title: 'Copy of Title Front',
    code: 'TITLE_F',
  },
  {
    title: 'Copy of Title Back',
    code: 'TITLE_B',
  },
  {
    title: 'Lien Release',
    code: 'LIEN_RLS',
  },
  {
    title: 'Itemized Packing List with Values',
    code: 'IPL',
  },
  {
    title: 'Commercial Invoice',
    code: 'CI',
  },
  {
    title: 'Power of Attorney',
    code: 'POA',
  },
  {
    title: "Shipper's Letter of Instructions",
    code: 'SLI',
  },
  {
    title: 'Copy of ID or EIN#',
    code: 'EIN',
  },
];
