export enum Currency {
  USD = 'USD',
}

export const formatPrice = (
  param: string | number,
  currency: Currency
): string =>
  new Number(
    typeof param == 'string' ? parseFloat(param) : param
  ).toLocaleString('en-US', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    localeMatcher: 'lookup',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
