import { Hooks, Row } from 'react-table';
import { ExpandCell } from './ExpandCell';
import { CONTROL_COLUMN_WIDTH } from '../../../../constants';

export const useExpandColumn = <T extends object>(hooks: Hooks<T>) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'open-row-details',
      isSticky: true,
      searchable: false,
      isControl: true,
      width: CONTROL_COLUMN_WIDTH,
      Cell: ({ row }: { row: Row<T> }) => <ExpandCell row={row} />,
    },
    ...columns,
  ]);
};
