import { CfrApiError, WarehouseReceipt, PaginatedListResponse } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  useCfrApiClient,
  useQueryGetListRequest,
  RequestParams,
} from 'hooks/cfr-api';

export const useWarehouseReceiptList = (
  params: RequestParams
): UseQueryResult<
  PaginatedListResponse<WarehouseReceipt>,
  Error | CfrApiError
> => {
  const client = useCfrApiClient();

  return useQueryGetListRequest<PaginatedListResponse<WarehouseReceipt>>(
    client,
    'reporting/warehouse-receipt',
    params
  );
};
