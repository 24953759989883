import { Box, Typography } from '@material-ui/core';
import { FileUpload } from 'components';
import { FC } from 'react';
import { convertBytesToMb } from 'utils';

const MAX_FILE_SIZE_ALLOWED = 3145728; // 3 mb
const MAX_ATTACHED_FILE_SIZE = 26214400; // 25 mb

interface FileUploadSectionProps {
  onFileUpload: (files: File[]) => void;
}

export const FileUploadSection: FC<FileUploadSectionProps> = ({
  onFileUpload,
}) => (
  <div>
    <FileUpload
      onFileUpload={onFileUpload}
      tooltipContent={
        <div>
          <Box pb={2}>
            <Typography variant="overline">
              {` Max file size should be less than ${convertBytesToMb(
                MAX_FILE_SIZE_ALLOWED
              )} MB`}
            </Typography>
          </Box>
          <div>
            <Typography variant="overline">
              {` Max files size should be less than ${convertBytesToMb(
                MAX_ATTACHED_FILE_SIZE
              )} MB`}
            </Typography>
          </div>
        </div>
      }
    />
  </div>
);
