import { createContext, FC, useState } from 'react';

interface ExternalUserIdContextType {
  externalUserId?: string;
  setExternalUserId: (id: string) => void;
}

export const externalUserIdContextDefaultValues: ExternalUserIdContextType = {
  externalUserId: undefined,
  setExternalUserId: () => null,
};

const ExternalUserIdContext = createContext<ExternalUserIdContextType>(
  externalUserIdContextDefaultValues
);

const ExternalUserIdProvider: FC = ({ children }) => {
  const [externalUserId, setExternalUserId] = useState<string>();

  return (
    <ExternalUserIdContext.Provider
      value={{
        externalUserId,
        setExternalUserId,
      }}
    >
      {children}
    </ExternalUserIdContext.Provider>
  );
};

ExternalUserIdContext.displayName = 'ExternalUserIdContext';
const ExternalUserIdConsumer = ExternalUserIdContext.Consumer;

export {
  ExternalUserIdProvider,
  ExternalUserIdConsumer,
  ExternalUserIdContext,
};
