import { IconName } from 'components/common';
import { MenuItemType } from 'components/menu';

export * from './CustomColumns';
export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';
export const LOCAL_STORAGE_IS_REMEMBER_USER_CHECKED_KEY = 'is_remember_user';
export const LOCAL_STORAGE_IS_DRAWER_OPEN = 'is_drawer_open';
export const LOCAL_STORAGE_SHOW_TOOLTIPS = 'show_tooltips';
export const LOCAL_STORAGE_USER_MODULE_COLUMNS = 'user_module_columns';
export const LOCAL_STORAGE_EMAIL_OPTIONS = 'email_options';
export const LOCAL_STORAGE_EXTERNAL_USER_ID = 'external_user_id';

export const IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg'];
export const DOCUMENT_EXTENSIONS = ['pdf'];

export const SIDEBAR_WIDTH = 308;
export const ATTACHMENT_SECTION_WIDTH = 830;
export const EMAIL_SECTION_WIDTH = 460;

export const CONTROL_COLUMN_WIDTH = 50;
export const DEFAULT_COLUMN = {
  width: 140,
};

export enum UserPermissions {
  VIEW_INVOICES = 'view_invoices',
  VIEW_BILL_OF_LANDING = 'view_bill_of_landing',
  VIEW_WAREHOUSE_RECEIPTS = 'view_warehouse_receipts',
  VIEW_SHIPPING_INSTRUCTIONS = 'view_shipping_instructions',
  VIEW_VEHICLE_INVENTORY = 'view_vehicle_inventory',
  VIEW_LOAD_PLANS = 'view_load_plans',
}

export const menuItems: MenuItemType[] = [
  {
    icon: IconName.dashboard,
    text: 'Dashboard',
    link: '/',
  },
  {
    icon: IconName.estate,
    text: 'My Inventory',
    link: '/vehicle-inventory',
    user_permissions: [UserPermissions.VIEW_VEHICLE_INVENTORY],
  },
  {
    icon: IconName.ship,
    text: 'My Shipments',
    link: '/shipments',
    user_permissions: [UserPermissions.VIEW_BILL_OF_LANDING],
  },
  {
    icon: IconName.invoice,
    text: 'My Invoices',
    link: '/invoices',
    user_permissions: [UserPermissions.VIEW_INVOICES],
  },
  {
    icon: IconName.invoice,
    text: 'My Orders',
    link: '/orders',
    user_permissions: [UserPermissions.VIEW_SHIPPING_INSTRUCTIONS],
  },
  {
    icon: IconName.ship,
    text: 'My Load Plans',
    link: '/load-plans',
    user_permissions: [UserPermissions.VIEW_LOAD_PLANS],
  },
];
