import { FC, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { routes, privateRoutes, RouteObject } from './Router';
import { useIsLogged } from 'hooks';
import { LoaderScreen } from 'components';

export const renderRouters = (routes: RouteObject[], redirectTo: string) => (
  <Suspense fallback={<LoaderScreen />}>
    <Switch>
      {routes.map(({ path, exact, component }) => (
        <Route key={path} exact={exact} path={path} component={component} />
      ))}
      <Redirect
        to={{
          pathname: redirectTo,
        }}
      />
    </Switch>
  </Suspense>
);

export const RenderRoutes: FC = () => {
  const isLogged = useIsLogged();
  return (
    <Router>
      {isLogged
        ? renderRouters(privateRoutes, '/')
        : renderRouters(routes, '/login')}
    </Router>
  );
};
