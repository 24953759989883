import { Box } from '@material-ui/core';
import { Icon, IconName } from 'components/common';
import { FC } from 'react';
import { Wrapper, Text, TextSmall, StyledLink } from './OverviewBox.styles';

export interface OverviewBoxProps {
  count: number | string;
  title: string;
  link: string;
}

const renderOverview = (count: number | string, title: string) => (
  <Wrapper hasLink={!!count}>
    <Text>{count}</Text>
    <Box
      pb={2}
      pt={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={150}
    >
      <TextSmall variant="body2">{title}</TextSmall>
      {!!count && <Icon fontSize="1.8rem" name={IconName.angleRight} />}{' '}
    </Box>
  </Wrapper>
);

export const OverviewBox: FC<OverviewBoxProps> = ({ link, count, title }) => (
  <>
    {count ? (
      <StyledLink to={link}>{renderOverview(count, title)}</StyledLink>
    ) : (
      renderOverview(count, title)
    )}
  </>
);
