import { UserCredentials, UserAuthInfo, CfrApiError } from 'types';
import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';
import { unwrapResponse, unwrapErrorResponse } from './helpers';
import { useCfrApiClient } from 'hooks/cfr-api';
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../../constants';
import { useLocalStorage } from 'hooks';

export const useLogin = (
  isRememberUser: boolean
): UseMutationResult<UserAuthInfo, Error | CfrApiError, UserCredentials> => {
  const client = useCfrApiClient();
  const [access, setAccessToken] = useLocalStorage(
    LOCAL_STORAGE_ACCESS_TOKEN_KEY,
    ''
  );
  const [refresh, setRefreshToken] = useLocalStorage(
    LOCAL_STORAGE_REFRESH_TOKEN_KEY,
    ''
  );

  return useMutation((data) =>
    client
      .post<UserAuthInfo>(`auth/jwt/create/`, data)
      .then((response) => {
        setAccessToken(unwrapResponse(response).access || '');

        const unwrapResponseData: string =
          unwrapResponse(response).refresh || '';

        if (isRememberUser) {
          setRefreshToken(unwrapResponseData);
        } else {
          sessionStorage.setItem(
            LOCAL_STORAGE_REFRESH_TOKEN_KEY,
            JSON.stringify(unwrapResponseData)
          );
        }

        return { access, refresh };
      })
      .catch(unwrapErrorResponse)
  );
};
