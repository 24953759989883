import { Fragment, SyntheticEvent, useMemo, useRef, useState } from 'react';
import {
  Column,
  ColumnInstance,
  Hooks,
  Row,
  useExpanded,
  useRowSelect,
  useTable,
} from 'react-table';
import {
  Cell,
  FullWidthTable,
  LoaderOverlay,
  PaginationContainer,
  Shadow,
  StickyTable,
  StyledCircularProgress,
  TableContainer,
  Tr,
} from './Table.styles';
import { useIsDevice, useIsElementHaveScroll, useShowTooltips } from 'hooks';
import { RequestParams, SearchItem } from 'hooks/cfr-api';
import { Pagination } from '../Pagination';
import clsx from 'clsx';
import { Placeholder, PlaceholderImage } from 'components';
import { KeyOfByType, ModuleName } from 'types';
import {
  RowDataPk,
  useExpandColumn,
  useRowSelectionChangeDetection,
  useRowSelectionColumn,
} from 'components/table/Table/hooks';
import { HeaderCell } from './Cells/HeaderCell';
import { useCustomColumns } from './hooks/useCustomColumns';
import { DEFAULT_COLUMN } from '../../../constants';
import { Box } from '@material-ui/core';

const calculateColumnLeft = <T extends object>(
  columns: ColumnInstance<T>[],
  currentColumnIndex: number
): number =>
  columns
    .slice(0, currentColumnIndex)
    .reduce(
      (left: number, col) => left + +(col.width ?? DEFAULT_COLUMN.width),
      0
    );

export type SelectedRows<T> = T[KeyOfByType<T, string>][];

export interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  count: number;
  requestParams: RequestParams;
  isFetching: boolean;
  rowDataPk: RowDataPk<T>;
  hiddenColumns?: string[];
  moduleName?: ModuleName;

  renderRowDetails?(
    row: T,
    columnsCount: number,
    controlColumnsCount: number
  ): JSX.Element;
  customColumns?: Column<T>[];
  onChangeRowsSelection(
    selectedRows: SelectedRows<T>,
    isAllRowsSelected: boolean
  ): void;
  setRequestParams(params: RequestParams): void;
  onRowClick?(row: Row<T>): void;
}

export const Table = <T extends object>({
  columns,
  data,
  setRequestParams,
  requestParams,
  count,
  isFetching,
  rowDataPk,
  onChangeRowsSelection,
  onRowClick,
  renderRowDetails,
  hiddenColumns = [],
  customColumns,
  moduleName,
}: TableProps<T>) => {
  const pageSize = requestParams.limit;
  const pageCount = Math.ceil(count / requestParams.limit);

  const page = requestParams.offset
    ? requestParams.offset / requestParams.limit + 1
    : 1;

  const [showStickyColumnShadow, setShowStickyColumnShadow] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const isTableHaveScroll = useIsElementHaveScroll(tableContainerRef);
  const [showTooltip] = useShowTooltips();
  const isMobile = useIsDevice('mobile');

  const onTableScroll = (event: SyntheticEvent) => {
    setShowStickyColumnShadow(event.currentTarget.scrollLeft > 0);
  };

  const onPageChange = (page: number) => {
    setRequestParams({
      ...requestParams,
      offset: (page - 1) * pageSize,
    });
  };

  const onPageSizeChange = (limit: number) => {
    setRequestParams({
      ...requestParams,
      offset: 0,
      limit: limit,
    });
  };

  const onColumnSearch = (field: string, item: SearchItem) => {
    const search = { ...requestParams.search };

    if (item.value) {
      search[field] = {
        value: item.value,
        operator: item.operator,
      };
    } else {
      delete search[field];
    }

    setRequestParams({
      ...requestParams,
      search,
      offset: 0,
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    prepareRow,
    rows,
    visibleColumns,
    selectedFlatRows,
    isAllRowsSelected,
  } = useTable<T>(
    {
      columns,
      data,
      defaultColumn: DEFAULT_COLUMN,
      initialState: { hiddenColumns },
    },
    ...(customColumns
      ? [(hooks: Hooks<T>) => useCustomColumns(hooks, customColumns)]
      : []),
    ...(renderRowDetails ? [useExpanded, useExpandColumn] : []),
    useRowSelect,
    useRowSelectionColumn
  );

  useRowSelectionChangeDetection({
    onChangeRowsSelection,
    selectedFlatRows,
    isAllRowsSelected,
    rowDataPk,
  });

  const getCellsClasses = (sticky: boolean) =>
    clsx({
      sticky: sticky,
      withShadow: sticky && showStickyColumnShadow,
    });

  const Table = isTableHaveScroll && !isMobile ? StickyTable : FullWidthTable;

  const controlColumnsCount = useMemo<number>(
    () => visibleColumns.filter((column) => column.isControl).length,
    [visibleColumns]
  );

  return (
    <>
      <Table>
        {isFetching && (
          <LoaderOverlay>
            <StyledCircularProgress />
          </LoaderOverlay>
        )}
        <TableContainer onScroll={onTableScroll} ref={tableContainerRef}>
          <Shadow isHidden={!isTableHaveScroll} />
          <table {...getTableProps()}>
            <thead>
              <tr>
                {headers
                  .filter((column) => column.isVisible)
                  .map((column, i) => {
                    const { key, ...headerProps } = column.getHeaderProps();

                    return (
                      <HeaderCell<T>
                        key={key}
                        {...headerProps}
                        column={column}
                        left={calculateColumnLeft(headers, i)}
                        classNames={getCellsClasses(!!column?.isSticky)}
                        tooltipRef={ref}
                        showTooltip={showTooltip}
                        requestParamValue={
                          requestParams?.search?.[column.id]?.value ?? ''
                        }
                        onSearch={onColumnSearch}
                        moduleName={moduleName}
                      />
                    );
                  })}
              </tr>
            </thead>

            <tbody {...getTableBodyProps()}>
              {!!data?.length &&
                rows.map((row) => {
                  prepareRow(row);
                  const { key, ...rowProps } = row.getRowProps();
                  return (
                    <Fragment key={key}>
                      <Tr
                        {...rowProps}
                        isActive={row.isExpanded}
                        onClick={() => onRowClick && onRowClick(row)}
                      >
                        {row.cells.map((cell, i) => {
                          const { key, ...cellProps } = cell.getCellProps();
                          const width = cell.column.width + 'px';

                          return (
                            <Cell
                              key={key}
                              {...cellProps}
                              minWidth={width}
                              maxWidth={width}
                              left={calculateColumnLeft(
                                row.cells.map((c) => c.column),
                                i
                              )}
                              className={getCellsClasses(
                                !!cell.column?.isSticky
                              )}
                            >
                              {!cell.value &&
                              !!(cell.column as Column<T>).accessor
                                ? '-'
                                : cell.render('Cell')}
                            </Cell>
                          );
                        })}
                      </Tr>

                      {renderRowDetails && row.isExpanded
                        ? renderRowDetails(
                            row.original,
                            visibleColumns.length,
                            controlColumnsCount
                          )
                        : null}
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
          {!data?.length && (
            <Box
              position="absolute"
              left="0"
              top="0"
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              zIndex="0"
            >
              <Placeholder
                variant={PlaceholderImage.noData}
                title="No records"
                description="Empty search result!"
              />
            </Box>
          )}
        </TableContainer>
      </Table>

      {pageCount > 1 && (
        <PaginationContainer>
          <Pagination
            page={page}
            pageCount={pageCount}
            pageSize={pageSize}
            isDisabled={isFetching}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </PaginationContainer>
      )}
    </>
  );
};
