import { UserProfile } from 'types';

export const getUserName = (user: UserProfile): string => {
  if (!user.first_name) {
    return user.email;
  }

  return user.first_name;
};

export const getUserFullName = (user: UserProfile): string => {
  if (!user.first_name && !user.last_name) {
    return user.email;
  }

  return `${user.first_name} ${user.last_name}`.trim();
};

export const getUserInitials = (user: UserProfile): string => {
  const firstName = user.first_name?.[0] ?? '';
  const lastName = user.last_name?.[0] ?? '';

  return `${firstName}${lastName}`;
};
