import { AxiosError, AxiosResponse } from 'axios';

export const unwrapErrorResponse = (error: AxiosError): never => {
  const responseError = error?.response;

  if (responseError?.data) {
    const { data, status } = responseError;
    throw { data, status };
  }

  throw error;
};

export const unwrapResponse = <T>(response: AxiosResponse<T>): T =>
  response.data;
