import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div<{ hasLink: boolean }>`
  background: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(6, 9)};
  border: ${({ theme }) => `1px solid ${theme.palette.grey.A100}`};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  height: 100%;

  ${({ hasLink }) =>
    hasLink &&
    `
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 8px rgba(30, 44, 101, 0.15);
    }
  `}
`;

export const Text = styled(Typography)`
  font-size: 3.2rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const TextSmall = styled(Typography)`
  opacity: 0.8;
`;

export const StyledLink = styled(Link)`
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
