import { useIsDevice, useLocalStorage } from 'hooks';
import { useEffect, useState } from 'react';
import { LOCAL_STORAGE_SHOW_TOOLTIPS } from '../constants';

export const useShowTooltips = (): [boolean, (hasTooltip: boolean) => void] => {
  const isMobile = useIsDevice('mobile');
  const [hasTooltips, setHasTooltips] = useLocalStorage(
    LOCAL_STORAGE_SHOW_TOOLTIPS,
    true
  );
  const [showTooltip, setShowTooltip] = useState(hasTooltips);

  useEffect(() => {
    setShowTooltip(isMobile ? false : hasTooltips);
  }, [isMobile, hasTooltips]);

  return [showTooltip, setHasTooltips];
};
