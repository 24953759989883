import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledIconButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;
