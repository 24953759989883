import { createContext, FC, useState } from 'react';

interface ContactFormContextType {
  isContactFormOpen: boolean;
  setIsContactFormOpen: (open: boolean) => void;
}

export const contactFormContextDefaultValues: ContactFormContextType = {
  isContactFormOpen: false,
  setIsContactFormOpen: () => null,
};

const ContactFormContext = createContext<ContactFormContextType>(
  contactFormContextDefaultValues
);

const ContactFormProvider: FC = ({ children }) => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  return (
    <ContactFormContext.Provider
      value={{
        isContactFormOpen,
        setIsContactFormOpen,
      }}
    >
      {children}
    </ContactFormContext.Provider>
  );
};

ContactFormContext.displayName = 'ContactFormContext';
const ContactFormConsumer = ContactFormContext.Consumer;

export { ContactFormProvider, ContactFormConsumer, ContactFormContext };
