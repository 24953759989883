import { Column } from 'react-table';
import { WarehouseReceiptDetail } from 'types';

export const freightDetailColumns: Column<WarehouseReceiptDetail>[] = [
  {
    Header: 'Quantity',
    accessor: 'Qty',
    width: 140,
    tooltip: {
      title: 'Quantity',
      description: 'This is a quantity attribute of freight!',
    },
  },
  {
    Header: 'Cargo Type',
    accessor: 'CargoType',
  },
  {
    Header: 'Description',
    accessor: 'Description',
  },
  {
    Header: 'Dimensions',
    accessor: 'Dimensions',
  },
  {
    Header: 'Weight',
    accessor: 'TotWeight',
  },
  {
    Header: 'Volume',
    accessor: 'Cubic',
  },
];
