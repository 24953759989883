import { Box } from '@material-ui/core';
import { LoaderScreen, Placeholder, PlaceholderImage } from 'components/common';
import { Wrapper } from './Attachments.styles';
import { useContext } from 'react';
import {
  AttachmentsInfo,
  DocumentUploadParams,
  DocumentUploadSettings,
} from '../AttachmentsInfo';
import { EmailSection } from '../EmailSection';
import { CloseButton } from '../CloseButton';
import { AttachmentsContext } from 'context';
import { Attachment, CfrApiError } from 'types';

interface AttachmentsProps {
  id?: string;
  isLoading: boolean;
  attachments?: Attachment[];
  isError: boolean;
  error: Error | CfrApiError | null;
  title?: string;
  subTitle?: string;
  hasEmailSection: boolean;
  hasPhotosSection: boolean;
  hasUploadSection: boolean;
  defaultEmailSubject?: string;
  uploadParams?: DocumentUploadParams;
  uploadDocumentsSettings?: DocumentUploadSettings[];
}

export const Attachments = ({
  id,
  isLoading,
  attachments,
  isError,
  error,
  title,
  subTitle,
  defaultEmailSubject,
  hasEmailSection,
  hasPhotosSection,
  hasUploadSection,
  uploadParams,
  uploadDocumentsSettings,
}: AttachmentsProps) => {
  const { handleCloseAll } = useContext(AttachmentsContext);

  return (
    <Box display="flex" flex={1}>
      <Wrapper>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          position="relative"
        >
          <CloseButton
            onClose={(e) => {
              e.stopPropagation();
              handleCloseAll();
            }}
          />

          {!id ? (
            <Placeholder
              variant={PlaceholderImage.noData}
              title="No data available"
              description="No id provided"
            />
          ) : (
            <>
              {isLoading ? (
                <LoaderScreen />
              ) : (
                <>
                  {attachments ? (
                    <AttachmentsInfo
                      attachments={attachments || []}
                      title={title}
                      subTitle={subTitle}
                      hasPhotosSection={hasPhotosSection}
                      hasEmailSection={hasEmailSection}
                      hasUploadSection={hasUploadSection}
                      uploadParams={uploadParams}
                      uploadDocumentsSettings={uploadDocumentsSettings}
                    />
                  ) : (
                    <Placeholder
                      variant={PlaceholderImage.noData}
                      title="Nothing found"
                      description="No data was found"
                    />
                  )}
                </>
              )}
            </>
          )}

          {isError && error && 'detail' in error && (
            <Placeholder
              variant={PlaceholderImage.error}
              title="An error occurred"
              description={error.detail}
            />
          )}
        </Box>
      </Wrapper>

      {hasEmailSection && !isLoading && (
        <EmailSection
          title="Email Attachments"
          defaultEmailSubject={defaultEmailSubject}
        />
      )}
    </Box>
  );
};
