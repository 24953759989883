import {
  makeStyles,
  Theme,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { Icon } from 'components/common';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '.input.MuiInput-root': {
      'input :-internal-autofill-selected': {
        backgroundColor: 'transparent !important',
        color: theme.palette.primary.main + ' !important',
      },
    },
    '& .MuiInput-underline': {
      fontSize: theme.typography.body2.fontSize,
      '&::before': { borderBottom: `1px solid ${theme.palette.divider}` },
      '&::after, &:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '& input, & textarea': {
        padding: '12px 0 18px',

        '&::placeholder': {
          fontWeight: 'normal',
        },
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,

      '& fieldset': {
        borderColor: theme.palette.grey[200],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
        borderWidth: '2px',
      },
      '&.Mui-disabled': {
        '& fieldset': {
          borderColor: theme.palette.grey[200] + ' !important',
          borderWidth: '2px',
        },
        '& span': {
          color: theme.palette.grey[200],
          opacity: '1 !important',
          cursor: 'not-allowed !important',
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.light,
      },
    },
    '& .Mui-error': {
      textTransform: 'none',
      marginLeft: 0,

      '&:after, &:before': {
        borderBottom: `1px solid ${theme.palette.error.main} !important`,
      },
    },
  },
}));

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.primary.main};

  &&&:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const StyledLabel = styled.label<{ variant: TextFieldProps['variant'] }>`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: ${({ theme, variant }) =>
    variant === 'standard'
      ? theme.typography.fontWeightLight
      : theme.typography.fontWeightMedium};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const CharsCountWrapper = styled.div<{ isValid: boolean }>`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme, isValid }) =>
    isValid ? theme.palette.grey[900] : theme.palette.error.main};
  opacity: ${({ isValid }) => (isValid ? '0.5' : '1')};
`;

export const Count = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;
