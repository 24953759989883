import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  carousel: {
    height: '100%',
    '& .carousel': {
      '&.carousel-slider': {
        height: '100%',
      },
      '&.carousel-slider .slider-wrapper': {
        height: '100%',
      },
      '&.carousel-slider .slider-wrapper .slider': {
        height: '100%',
      },
    },
  },
}));

export const Img = styled.img`
  width: auto;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(40)}px;
  padding: 0 ${({ theme }) => theme.spacing(13)}px;
  color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: left;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 0 ${({ theme }) => theme.spacing(28)}px;
  }
`;

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTypography = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  padding-bottom: ${({ theme }) => theme.spacing(5)}px;
  display: inline-block;
`;

export const Description = styled(Typography)`
  line-height: 3rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  max-height: 210px;
  overflow: hidden;
`;
