import { ROWS_PER_PAGE } from 'components/table/Pagination';

export enum FilterOperators {
  IN = 'in',
  ICONTAINS = 'icontains',
  DATE = 'date',
  DATERANGE = 'date-range',
}

export interface DateFilterValue {
  year: string;
  month: string;
  day: string;
}
export interface DateRangeFilterValue {
  from: DateFilterValue;
  to: DateFilterValue;
}
export type SearchValue = string | DateFilterValue | DateRangeFilterValue;

export interface SearchItem {
  value: SearchValue;
  operator?: FilterOperators;
}

export interface SearchRequestParams {
  [key: string]: SearchItem;
}
export interface RequestParams {
  offset: number;
  limit: number;
  search?: SearchRequestParams;
}

export const DEFAULT_REQUEST_PARAMS: RequestParams = {
  offset: 0,
  limit: ROWS_PER_PAGE[0],
  search: {},
};

export const defaultSearchOperator = FilterOperators.ICONTAINS;

const getDateRequestParam = (fieldKey: string, dateValue: DateFilterValue) => [
  `${fieldKey}__year=${dateValue.year}`,
  `${fieldKey}__month=${dateValue.month}`,
  `${fieldKey}__day=${dateValue.day}`,
];

const getSearchRequestParam = (
  fieldKey: string,
  operator: FilterOperators | undefined,
  value: string | DateFilterValue | DateRangeFilterValue
): string[] => {
  const params: string[] = [];

  let dateValue: DateFilterValue | DateRangeFilterValue;

  switch (operator) {
    case FilterOperators.DATE:
      params.push(...getDateRequestParam(fieldKey, value as DateFilterValue));
      break;
    case FilterOperators.DATERANGE:
      dateValue = value as DateRangeFilterValue;
      if (
        dateValue.from.year === dateValue.to.year &&
        dateValue.from.month === dateValue.to.month &&
        dateValue.from.day === dateValue.to.day
      ) {
        params.push(
          ...getDateRequestParam(fieldKey, dateValue.from as DateFilterValue)
        );
      } else {
        params.push(
          `${fieldKey}__gte=${dateValue.from.year}-${dateValue.from.month}-${dateValue.from.day}T00:00:00`
        );
        params.push(
          `${fieldKey}__lt=${dateValue.to.year}-${dateValue.to.month}-${dateValue.to.day}T00:00:00`
        );
      }
      break;
    default:
      params.push(`${fieldKey}__${operator || defaultSearchOperator}=${value}`);
  }

  return params;
};

/**
 * Gets request params and builds query string based on them
 */
export const useRequestParams = ({
  limit,
  offset,
  search,
}: Partial<RequestParams>): string | null => {
  const arr: string[] = [];

  for (const [property, value] of Object.entries({ limit, offset })) {
    arr.push(`${property}=${value}`);
  }

  if (search && Object.keys(search).length) {
    for (const [fieldKey, { operator, value }] of Object.entries(search)) {
      arr.push(...getSearchRequestParam(fieldKey, operator, value));
    }
  }

  return arr.length ? '?' + arr.join('&') : null;
};
