import { FC, useContext } from 'react';
import { Icon, IconName, Tooltip } from 'components/common';
import { Box, Typography } from '@material-ui/core';
import { Wrapper } from '../MenuItem/MenuItem.styles';
import { ContactFormContext } from 'context';

export interface ContactUsItemProps {
  hasText?: boolean;
  hasTooltip?: boolean;
}
const CONTACT_US_TEXT = 'Do you have a question?';

export const ContactUsItem: FC<ContactUsItemProps> = ({
  hasText = false,
  hasTooltip = false,
}) => {
  const { isContactFormOpen, setIsContactFormOpen } =
    useContext(ContactFormContext);

  return (
    <Wrapper $hasText={hasText}>
      <Tooltip
        placement="right"
        title={CONTACT_US_TEXT}
        arrow={true}
        disableFocusListener={!hasTooltip}
        disableTouchListener={!hasTooltip}
        disableHoverListener={!hasTooltip}
      >
        <Box
          onClick={() => setIsContactFormOpen(!isContactFormOpen)}
          display="flex"
          width="100%"
          py={5}
          px={hasText ? 7 : 4}
          justifyContent={hasText ? 'flex-start' : 'center'}
        >
          <Icon
            name={IconName.envelope}
            mr={hasText ? 3 : 0}
            fontSize={hasText ? '1.6rem' : '2rem'}
          />
          {hasText && (
            <Typography variant="caption" color="inherit">
              {CONTACT_US_TEXT}
            </Typography>
          )}
        </Box>
      </Tooltip>
    </Wrapper>
  );
};
