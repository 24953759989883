import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

export default createMuiTheme({
  palette: {
    primary: {
      light: '#3551BF',
      main: '#223FB2',
      dark: '#16319D',
    },
    secondary: {
      light: '#2914A7',
      main: '#3E29BE',
      dark: '#5843D8',
    },
    error: {
      main: '#CE202F',
    },
    warning: {
      light: '#FFD789',
      main: '#F3BB1C',
      dark: '#E88400',
      contrastText: '#F8F9FA',
    },
    info: {
      light: '#F9FAFF',
      main: '#3E66FB',
    },
    success: {
      light: '#16F16E',
      main: '#3CC13B',
      dark: '#128E44',
    },
    grey: {
      50: '#f9fafa',
      100: '#efefef',
      200: '#e6e6e6',
      300: '#C2C9D1',
      400: '#98A1AF',
      600: '#808080',
      700: '#666',
      900: '#393939',
      A100: '#F3F6FF',
    },
    text: {
      primary: '#26272F',
      secondary: '#92979F',
    },
    divider: '#E8EBF1',
    background: {
      paper: '#fff',
      default: '#F4F6FA',
    },
    action: {
      hover: '#F3F6FF',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: ['Inter', 'Helvetica'].join(','),
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: '6.4rem',
      lineHeight: 1.375,
    },
    h2: {
      fontWeight: 500,
      fontSize: '3.8rem',
      lineHeight: 1,
    },
    h3: {
      fontWeight: 700,
      fontSize: '3.2rem',
      lineHeight: 1.5,
    },
    h4: {
      fontWeight: 700,
      fontSize: '2.4rem',
      lineHeight: 1.5,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.2rem',
      },
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.8rem',
      lineHeight: 1.11,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6rem',
      },
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.6rem',
      lineHeight: 1.75,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
    },
    subtitle1: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '1.8rem',
      lineHeight: 1.67,
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: 1.5,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    body2: {
      fontWeight: 400,
      fontSize: '1.4rem',
      lineHeight: 1.43,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
    },
    caption: {
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: 1.33,
    },
    overline: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      color: '#b3b3b3',
      textTransform: 'none',
      lineHeight: 1.4,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '10px',
          height: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px white inset',
          '-webkit-text-fill-color': '#1E2C65',
        },
        '&::placeholder': {
          color: '#000',
          opacity: '0.4',
        },
        color: '#1E2C65',
      },
    },
    MuiLink: {
      root: {
        display: 'block',
      },
    },
    MuiButton: {
      textPrimary: {
        color: theme.palette.grey[600],
        '&:hover, &.active': {
          color: '#1E2C65',
          backgroundColor: theme.palette.action.hover,
        },
      },
      outlinedPrimary: {
        border: '1px solid #E8EBF1;',
        color: '#4b5384',
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: 14,
        },
      },
    },
    MuiBadge: {
      badge: {
        margin: '0 6px 0 0',
      },
      colorPrimary: {
        backgroundColor: '#3E66FB',
      },
    },
  },
});
