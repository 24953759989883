import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Icon, IconName } from 'components/common';
import {
  Wrapper,
  LineWrapper,
  AngleRightLink,
  BoxTitle,
  ItemValue,
  LinkWrapper,
} from './VehiclesInfoBox.styles';

type VehiclesInfoBoxItem = {
  title: string;
  info: string | number;
};

export interface VehiclesInfoBoxProps {
  title: string;
  link?: string;
  data: VehiclesInfoBoxItem[];
}

const renderVehicleInfo = ({ title, data, link }: VehiclesInfoBoxProps) => (
  <Wrapper>
    <BoxTitle variant="h6">
      {title}
      {link && <Icon pl={2} fontSize="1.6rem" name={IconName.angleRight} />}
    </BoxTitle>
    <Box>
      {data.map(({ title, info }) => (
        <LineWrapper key={title}>
          <Typography variant="body2">{title}</Typography>
          <ItemValue>{info}</ItemValue>
        </LineWrapper>
      ))}
    </Box>
  </Wrapper>
);

export const VehiclesInfoBox: FC<VehiclesInfoBoxProps> = (props) => (
  <>
    {props.link ? (
      <LinkWrapper>
        <AngleRightLink to={props.link}>
          {renderVehicleInfo(props)}
        </AngleRightLink>
      </LinkWrapper>
    ) : (
      <Box borderRadius={5} bgcolor="background.default">
        {renderVehicleInfo(props)}
      </Box>
    )}
  </>
);
