import {
  validateEmailAutocomplete,
  validateEmailNotRequired,
  validateEmailMessage,
  validateFilesSize,
} from 'utils/validation';
import { getErrorOrElse, isFormValid } from 'utils';
import { FormFields, FormErrors } from './EmailSection.model';

export const validateEmailForm = (
  formData: FormFields
): { isFormValid: boolean; formErrors: FormErrors } => {
  const validateEmailOptions = [
    ...formData.to,
    ...formData.cc,
    ...formData.bcc,
  ].map((option) => ({
    [option]: validateEmailNotRequired(option),
  }));

  const filesSize: number = formData.files.reduce(
    (prev, cur) => prev + cur.size,
    0
  );

  const validatedForm = {
    to: validateEmailAutocomplete(formData.to),
    message: validateEmailMessage(formData.message),
    files: validateFilesSize(filesSize),
    autocompleteOptions: { ...Object.assign({}, ...validateEmailOptions) },
  };

  const formErrors = {
    to: getErrorOrElse(validatedForm.to, ''),
    message: getErrorOrElse(validatedForm.message, ''),
    files: getErrorOrElse(validatedForm.files, ''),
    autocompleteOptions: Object.assign(
      {},
      ...Object.keys(validatedForm.autocompleteOptions).map((key) => ({
        [key]: getErrorOrElse(validatedForm.autocompleteOptions[key], ''),
      }))
    ),
  };

  const autocompleteOptionsValidated = validatedForm.autocompleteOptions;
  const { autocompleteOptions, ...formFieldsValidated } = validatedForm;

  return {
    isFormValid: isFormValid({
      ...formFieldsValidated,
      ...autocompleteOptionsValidated,
    }),
    formErrors,
  };
};
