import { FC, useEffect, useState } from 'react';
import { SearchOption } from 'react-table-config';
import { SearchValue } from 'hooks/cfr-api';
import { OptionValue, Select } from 'components/form';

interface SearchSelectProps {
  searchOptions?: readonly SearchOption[];
  value: SearchValue;
  onChange(value: string): void;
}

export const SearchSelect: FC<SearchSelectProps> = ({
  searchOptions,
  value,
  onChange,
}) => {
  const [selectValue, setSelectValue] = useState<OptionValue>(value as string);

  useEffect(() => {
    if (selectValue && !value) {
      setSelectValue('');
    }
  }, [value]);

  return (
    <Select
      fullWidth={true}
      size="small"
      value={selectValue}
      setValue={setSelectValue}
      options={searchOptions ?? []}
      onChange={(value) => onChange(value as string)}
    />
  );
};
