import { Attachment, AttachmentType, EmailAttachments } from 'types';
import { getIsImage, getExtension } from 'utils/string';

export interface CustomAttachment extends Attachment {
  _type?: AttachmentType;
}

export const getEmailAttachments = (
  attachments: Attachment[]
): EmailAttachments[] =>
  attachments.map((attachment) => ({
    name: attachment.FileName,
    link: attachment.FullPath,
  }));

export const getFilesByExtension = (
  attachment: Attachment[],
  extensions: string[]
): Attachment[] =>
  attachment.filter(({ FileName }) =>
    extensions.includes(getExtension(FileName) || '')
  );

export const setFileType = (files: Attachment[]): CustomAttachment[] =>
  files.map((el) => ({
    ...el,
    _type: getIsImage(getExtension(el.FileName) || '')
      ? AttachmentType.IMAGE
      : AttachmentType.OTHER,
  }));
