import styled from 'styled-components';

export const FileUploadButton = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 21rem;
  padding: ${({ theme }) => theme.spacing(3, '7px')};
  background: ${({ theme }) => theme.palette.warning.contrastText};
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  &:hover {
    cursor: pointer;
  }
`;

export const Files = styled.div`
  display: inline-flex;
  flex-flow: wrap;
`;
