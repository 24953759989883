import { CfrApiError, Invoice, PaginatedListResponse } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  RequestParams,
  useCfrApiClient,
  useQueryGetListRequest,
} from 'hooks/cfr-api';

export const useInvoiceList = (
  params: RequestParams
): UseQueryResult<PaginatedListResponse<Invoice>, Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetListRequest<PaginatedListResponse<Invoice>>(
    client,
    'reporting/invoices',
    params
  );
};
