import { makeStyles } from '@material-ui/core/styles';
import { BadgeVariant, BadgeStyleProps } from './Badge';
import { Theme } from '@material-ui/core';

const getVariantStyles = (variant: BadgeVariant, theme: Theme) => {
  switch (variant) {
    case 'red':
      return {
        color: '#EA3546',
        background: 'rgba(234, 53, 70, 0.2)',
      };
    case 'orange':
      return {
        color: '#FF7A00',
        background: 'rgba(255, 122, 0, 0.2)',
      };
    case 'yellow':
      return {
        color: '#FCCA46',
        background: 'rgba(252, 202, 70, 0.2)',
      };
    case 'green':
      return {
        color: '#00C823',
        background: 'rgba(0, 255, 45, 0.2)',
      };
    case 'darkGreen':
      return {
        color: '#00810D',
        background: 'rgba(0, 129, 13, 0.31)',
      };
    case 'gray':
      return {
        color: theme.palette.text.secondary,
        background: theme.palette.grey[200],
      };
  }
};

export const useStyles = makeStyles<Theme, BadgeStyleProps>((theme) => ({
  root: ({ variant }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    textAlign: 'center',

    ...getVariantStyles(variant, theme),
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  }),
}));
