import { useEffect, useState } from 'react';
import { UserModuleColumn } from 'hooks';
import { Button, Icon, IconName, Dialog } from 'components/common';
import { Checkbox } from '../../form';
import { Box, Grid } from '@material-ui/core';
import { CheckBoxWrapper } from './ColumnsManagement.styles';
import { fromArrayOfObjects } from 'utils';

interface ColumnsManagementFormData {
  [key: string]: UserModuleColumn;
}

export interface ColumnsManagementProps {
  userColumns: UserModuleColumn[];

  onSave(columns: UserModuleColumn[]): void;
}

export const ColumnsManagement = ({
  userColumns,
  onSave,
}: ColumnsManagementProps) => {
  const [isOpen, setOpen] = useState(false);

  const [columnsFormData, setColumnsFormData] =
    useState<ColumnsManagementFormData>({});

  useEffect(() => {
    initFormData();
  }, [userColumns]);

  const initFormData = () => {
    const formData = fromArrayOfObjects<UserModuleColumn>(userColumns, 'name');

    setColumnsFormData(formData);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    initFormData();
  };

  const handleApply = () => {
    onSave(
      Object.keys(columnsFormData).map((column) => columnsFormData[column])
    );

    setOpen(false);
  };

  const handleChanges = (column: string, value: boolean) => {
    setColumnsFormData({
      ...columnsFormData,
      [column]: { ...columnsFormData[column], show: value },
    });
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        endIcon={<Icon name={IconName.grid} />}
      >
        Columns
      </Button>

      <Dialog
        dialogTitle="Customize Columns"
        isOpen={isOpen}
        onClose={handleClose}
        onApply={handleApply}
      >
        <Grid container spacing={3}>
          {Object.keys(columnsFormData).map((column) => (
            <Grid key={column} item xs={12} sm={6}>
              <Box px={2}>
                <CheckBoxWrapper>
                  <Checkbox
                    label={columnsFormData[column].verbose_name}
                    checked={columnsFormData[column].show}
                    onChange={() =>
                      handleChanges(column, !columnsFormData[column].show)
                    }
                  />
                </CheckBoxWrapper>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </>
  );
};
