import { Box, Typography } from '@material-ui/core';
import { Icon, IconName } from 'components/common';
import { FC } from 'react';

interface InfoTooltipContent {
  title: string;
  description: string;
}

export const InfoTooltipContent: FC<InfoTooltipContent> = ({
  title,
  description,
}) => (
  <div>
    <Box display="flex" pb={1} color="primary.dark">
      <Icon pr={1} name={IconName.infoCircle} />
      <Typography color="inherit" variant="subtitle1">
        {title}
      </Typography>
    </Box>
    <Typography color="primary" variant="overline" component="p">
      {description}
    </Typography>
  </div>
);
