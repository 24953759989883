import { ReactNode } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Icon, IconName } from '../Icon';
import { DialogTitleStyles } from './Dialog.styles';

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: ReactNode;
  onClose?: () => void;
}

export const DialogTitle = withStyles(DialogTitleStyles)(
  (props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            data-testid="dialogCloseButton"
          >
            <Icon name={IconName.times} />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
);
