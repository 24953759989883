import { ReactNode, useEffect, useState } from 'react';
import { SelectedRows, Table, TableProps } from '../Table';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  CfrApiError,
  FilterType,
  ModuleName,
  PaginatedListResponse,
} from 'types';
import { Column, Row } from 'react-table';
import { Box } from '@material-ui/core';
import { UserModuleColumn, useUserModuleColumns } from 'hooks';
import { ColumnsManagement } from '../ColumnsManagement';
import { TableToolBar } from '../TableToolBar';
import { Button, Placeholder, PlaceholderImage } from 'components/common';
import {
  FilterOperators,
  RequestParams,
  useExport,
  useRequestParamsBasedOnUrl,
  useFilters,
  useUserProfile,
} from 'hooks/cfr-api';
import {
  QuickFilter,
  MultiSelectFilter,
  FiltersClearUp,
  prepareTableColumns,
} from 'components/table';
import { TablePlaceholder } from '../TablePlaceholder';
import { fromArrayOfObjects } from 'utils';
import isEqual from 'lodash/isEqual';
import { CustomColumns } from '../../../constants';

interface TableContainerProps<T extends object> {
  columns: Column<T>[];
  moduleName: ModuleName;
  rowDataPk: TableProps<T>['rowDataPk'];
  hiddenColumns?: string[];
  hasExport?: boolean;
  customColumns?: Column<T>[];
  renderCustomRightBarItems?(
    requestParams: RequestParams,
    setRequestParams: (requestParams: RequestParams) => void
  ): ReactNode[];

  renderRowDetails?(
    row: T,
    columnsCount: number,
    controlColumnsCount: number
  ): JSX.Element;
  useListHook(
    params: RequestParams
  ): UseQueryResult<PaginatedListResponse<T>, Error | CfrApiError>;
  onRowClick?(row: Row<T>): void;
  onDataCountLoad?(count: number): void;
}

export const TableContainer = <T extends object>({
  columns,
  rowDataPk,
  hiddenColumns,
  useListHook,
  onRowClick,
  onDataCountLoad,
  moduleName,
  renderRowDetails,
  renderCustomRightBarItems,
  hasExport = true,
  customColumns,
}: TableContainerProps<T>) => {
  const { data: filters } = useFilters(moduleName);

  const [requestParams, setRequestParams] =
    useRequestParamsBasedOnUrl(moduleName);

  const { data, isLoading, isFetching } = useListHook(requestParams);
  const tableData = data?.results ?? [];
  const [userModuleColumns, saveColumns] = useUserModuleColumns(moduleName);
  const columnsObject = fromArrayOfObjects<Column<T>>(columns, 'accessor');
  const [selectedRows, setSelectedRows] = useState<SelectedRows<T>>([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState<boolean>();
  const { doExport, isLoading: isExporting } = useExport(moduleName);

  const { data: user } = useUserProfile();

  const customColumnsFilter = user?.is_warehouse_employee
    ? (column: Column<T>) => column.id != CustomColumns.UPLOAD_ATTACHMENTS
    : () => true;

  useEffect(() => {
    if (!isFetching && typeof onDataCountLoad === 'function') {
      onDataCountLoad(data?.count ?? 0);
    }
  }, [isFetching, requestParams?.search]);

  const onChangeRowsSelection = (
    rows: SelectedRows<T>,
    isAllSelected: boolean
  ) => {
    if (!isEqual(rows, selectedRows)) {
      setSelectedRows(rows);
    }
    if (isAllRowsSelected != isAllSelected) {
      setIsAllRowsSelected(isAllSelected);
    }
  };

  useEffect(() => {
    if (userModuleColumns.length === 0) {
      if (typeof onDataCountLoad === 'function') {
        onDataCountLoad(0);
      }
    }
  }, [userModuleColumns]);

  const exportSelectedRows = () => {
    doExport(
      isAllRowsSelected
        ? requestParams.search ?? {}
        : {
            [rowDataPk as string]: {
              operator: FilterOperators.IN,
              value: selectedRows.join(','),
            },
          }
    );
  };

  const saveColumnsHandler = (columns: UserModuleColumn[]) => {
    saveColumns(columns);

    //Trigger resize to recalculate table width
    window.dispatchEvent(new Event('resize'));
  };

  const tableLeftBarItems = () => {
    const quickFilters =
      filters?.filter((filter) => filter.type === FilterType.QUICK_FILTER) ??
      [];

    return quickFilters.map((filter) => (
      <QuickFilter
        key={filter.field}
        filter={filter}
        requestParams={requestParams}
        setRequestParams={setRequestParams}
      />
    ));
  };

  const tableRightBarItems = () => {
    const items: ReactNode[] = [
      <FiltersClearUp
        key="filter-clear-up"
        requestParams={requestParams}
        setRequestParams={setRequestParams}
      />,
    ];

    if (renderCustomRightBarItems) {
      const customRightBarItems = renderCustomRightBarItems(
        requestParams,
        setRequestParams
      );

      items.push(...customRightBarItems);
    }

    const multiselectFilters =
      filters?.filter((filter) => filter.type === FilterType.MULTISELECT) ?? [];

    multiselectFilters.forEach((filter) => {
      items.push(
        <Box pr={{ xs: 1, md: 0 }}>
          <MultiSelectFilter
            key={filter.field}
            moduleName={moduleName}
            filter={filter}
            requestParams={requestParams}
            setRequestParams={setRequestParams}
          />
        </Box>
      );
    });

    items.push(
      <Box pr={{ xs: 1, md: 0 }}>
        <ColumnsManagement
          key={2}
          userColumns={userModuleColumns}
          onSave={saveColumnsHandler}
        />
      </Box>
    );

    if (hasExport) {
      items.push(
        <Box pr={{ xs: 1, md: 0 }}>
          <Button
            isLoading={isExporting}
            key={3}
            variant="outlined"
            disabled={!selectedRows.length}
            onClick={exportSelectedRows}
          >
            Export
          </Button>
        </Box>
      );
    }

    return items;
  };

  const isSearchUsed = !!Object.keys(requestParams?.search ?? {}).length;
  if (!tableData.length && !isSearchUsed && !isLoading) {
    return (
      <Placeholder
        variant={PlaceholderImage.noData}
        title="No records"
        description="There are no records available at this time"
      />
    );
  }

  if (isLoading) {
    return <TablePlaceholder />;
  }

  if (userModuleColumns.length === 0) {
    return (
      <Placeholder
        variant={PlaceholderImage.noData}
        title="No records"
        description="You don't have any available columns to be displayed, please contact administrator"
      />
    );
  }

  return (
    <Box pb={5} pt={5} display="flex" flexDirection="column" flexGrow={1}>
      <TableToolBar
        leftBarItems={tableLeftBarItems()}
        rightBarItems={tableRightBarItems()}
      />

      <Table<T>
        count={data?.count ?? 0}
        columns={prepareTableColumns<T>(columnsObject, userModuleColumns)}
        data={tableData}
        rowDataPk={rowDataPk}
        onChangeRowsSelection={onChangeRowsSelection}
        requestParams={requestParams}
        setRequestParams={setRequestParams}
        isFetching={isFetching}
        onRowClick={onRowClick}
        renderRowDetails={renderRowDetails}
        hiddenColumns={hiddenColumns}
        customColumns={customColumns?.filter(customColumnsFilter)}
        moduleName={moduleName}
      />
    </Box>
  );
};
