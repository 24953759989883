import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

export enum PlaceholderImage {
  noData = 'CFR-EmptyScreen',
  error = 'CFR-Error',
}

export interface PlaceholderProps {
  variant?: PlaceholderImage;
  title: string;
  description: string;
}

export const Placeholder: FC<PlaceholderProps> = ({
  variant = PlaceholderImage.noData,
  title,
  description,
}) => {
  const src = `images/${variant}.png`;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
      <Box display="flex" flexDirection="column" textAlign="center" width={200}>
        <Box mb={4}>
          <img src={src} width="100%" />
        </Box>
        <Box mb={1.5}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  );
};
