import { useNotes } from 'hooks/cfr-api';
import { FC, useState, useEffect } from 'react';
import { DrawerTimelineCell } from 'components';
import { TimelineItem } from 'components';
import sortBy from 'lodash/sortBy';
import { formatStatusesDescription } from 'utils';
import { NoteItem, ModuleName } from 'types';

interface NotesCellProps {
  id: string;
  subtitle?: string;
  moduleName: ModuleName;
}

export const NotesCell: FC<NotesCellProps> = ({ id, subtitle, moduleName }) => {
  const {
    data,
    isLoading,
    refetch: refetchNotes,
    isError,
    error,
  } = useNotes(moduleName, id ?? '');

  const [notes, setNotes] = useState<TimelineItem[]>([]);

  useEffect(() => {
    let notes: NoteItem[] = [];
    data?.forEach((item) => {
      notes = notes.concat(item.Notes);
    });

    setNotes(
      sortBy(notes, (note) => note.date).map(({ title, date = '', text }) => ({
        title: formatStatusesDescription(title),
        date,
        text,
      })) || []
    );
  }, [data]);

  const refetch = () => {
    refetchNotes();
  };

  return (
    <DrawerTimelineCell
      hasIcon={false}
      hasTitle={false}
      title="Notes"
      isLoading={isLoading}
      refetch={refetch}
      isError={isError}
      error={error}
      subtitle={subtitle}
      items={notes}
    />
  );
};
