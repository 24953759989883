import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    padding: `0 ${theme.spacing(3)}px`,
    boxShadow: 'none',
    height: 43,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    '&.MuiButton-sizeSmall': {
      height: 36,
    },
    '&.MuiButton-containedSizeLarge': {
      height: 56,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));
