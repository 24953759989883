import styled from 'styled-components';
import { Box, InputBase } from '@material-ui/core';

export const SearchBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 43px;
  background: #f4f6fa;
  border: 1px solid #e8ebf1;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all 0.5s;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const InputSearch = styled(InputBase)`
  padding-left: ${({ theme }) => theme.spacing(4)}px;
  font-size: 12px;
`;

export const ColorBox = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.4;
`;
