import { FC, ReactElement } from 'react';
import { render, RenderOptions, RenderResult } from '@testing-library/react';
import theme from 'styles/theme';
import { ThemeContext as ThemeContextProvider } from 'context/ThemeContext';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const ProviderWrapper: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeContextProvider theme={theme}>{children}</ThemeContextProvider>
  </QueryClientProvider>
);

const customRender = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'queries'>
): RenderResult => render(ui, { wrapper: ProviderWrapper, ...options });

export * from '@testing-library/react';
export { customRender as render };
