import { FC, useEffect, useState } from 'react';
import { Icon, IconName } from 'components/common';
import debounce from 'lodash/debounce';
import { Box } from '@material-ui/core';
import { RequestParams, SearchValue } from 'hooks/cfr-api';
import { SearchBox, InputSearch, ColorBox } from './SearchByVIN.styles';

const debounceTime = 500;

export interface SearchByVINProps {
  requestParams: RequestParams;
  searchKey: string;
  setRequestParams(params: RequestParams): void;
}

export const SearchByVIN: FC<SearchByVINProps> = ({
  requestParams,
  setRequestParams,
  searchKey,
}) => {
  const [value, setValue] = useState('');
  const [isChanged, setIsChanged] = useState(false);

  const onChange = (value: SearchValue) => {
    setRequestParams({
      ...requestParams,
      search: {
        [searchKey]: {
          value,
        },
      },
    });
  };

  const onInputValueChange = (value: string) => {
    setValue(value);
    debounceValueChange(value);
  };

  useEffect(() => {
    if (value && !requestParams?.search?.[searchKey]) {
      setValue('');
      setIsChanged(false);
    }
  }, [requestParams]);

  const debounceValueChange = debounce(
    (value: SearchValue) => onChange(value),
    debounceTime
  );

  return (
    <Box pr={{ xs: 1, md: 0 }}>
      <SearchBox width={isChanged ? 200 : 146}>
        <InputSearch
          value={value}
          placeholder="Search by VIN"
          onChange={(event) => onInputValueChange(event.target.value)}
          onFocus={() => setIsChanged(true)}
          onBlur={(event) => setIsChanged(!!event.target.value)}
        />
        <ColorBox>
          <Icon
            color="inherit"
            pr={3}
            fontSize="1.5rem"
            name={IconName.search}
          />
        </ColorBox>
      </SearchBox>
    </Box>
  );
};
