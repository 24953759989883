import { ReactNode, FC } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

interface IThemeContext {
  children: ReactNode;
  theme: Theme;
}

export const ThemeContext: FC<IThemeContext> = ({ children, theme }) => (
  <MuiThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </StyledThemeProvider>
  </MuiThemeProvider>
);
