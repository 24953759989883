import styled from 'styled-components';
import { Button } from '../Button';

export const ButtonIcon = styled(Button)`
  &.MuiButton-root {
    min-width: 50px;
    padding: 7px 14px;

    .MuiButton-endIcon {
      margin: 0;
    }
  }
`;
