import { Box, Typography, Link, Divider } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { FC } from 'react';
import { Icon, IconName } from 'components/common';
import { ItemWrapper } from 'components/common/DropDownMenu/DropDownMenu.styles';

export const UserMenu: FC = () => {
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <Box color="primary.main" width="218px">
      <Link component={RouterLink} to="/password-change">
        <ItemWrapper mt="2px">
          <Icon name={IconName.accountSettings} pr={3} />
          <Typography variant="body2">Change password</Typography>
        </ItemWrapper>
      </Link>
      <Link onClick={logout}>
        <Box mx={-4} my="18px">
          <Divider />
        </Box>
        <ItemWrapper>
          <Icon name={IconName.logOut} pr={3} />
          <Typography variant="body2">Log out</Typography>
        </ItemWrapper>
      </Link>
    </Box>
  );
};
