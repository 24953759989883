export enum AttachmentType {
  PDF = 'PDF',
  IMAGE = 'Image',
  DOCUMENT = 'Document',
  OTHER = 'Other',
}

export enum AttachBy {
  API_INTEGRATION = 'Api_Integration',
  MOBILE_API = 'Mobile_Api',
}

export interface Attachment {
  Lineuuid: string;
  Module: string;
  Application: string;
  Transaction: string;
  FileName: string;
  OriginalFileName: string;
  FileTitle: string;
  Type: AttachmentType;
  Online: boolean;
  AttachBy: AttachBy;
  AttachDate?: string;
  AttactmentType: string;
  Comments: string;
  ExportCSV: boolean;
  Path: string;
  FullPath: string;
  ExportRemote: boolean;
  DimensionLineuuid: string;
}

export interface NoteItem {
  date: string;
  title: string;
  text: string;
}

export interface Note {
  Lineuuid: string;
  Transaction: string;
  Notes: NoteItem[];
}

export enum ModuleName {
  VEHICLE_INVENTORY = 'vehicle_inventory',
  SHIPPING_INSTRUCTIONS = 'shipping_instructions',
  WAREHOUSE_RECEIPTS = 'warehouse_receipts',
  BILL_OF_LANDING = 'bill_of_landing',
  INVOICES = 'invoices',
  LOAD_PLANS = 'load_plans',
}
