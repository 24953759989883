import {
  Attachments,
  DocumentUploadParams,
  DocumentUploadSettings,
} from 'components';
import { useContext, useEffect, useState } from 'react';
import { ModuleName } from 'types';
import { useHistory } from 'react-router-dom';
import { getHashParams } from 'hooks';
import { useAttachments } from 'hooks/cfr-api';
import { AttachmentsContext } from 'context';

export interface DataBasedProps {
  title?: string;
  subTitle?: string;
  defaultEmailSubject?: string;
  uploadParams?: DocumentUploadParams;
}

interface AttachmentsContainerProps<T extends object> {
  moduleName: ModuleName;
  getDataBasedProps?(data: T): DataBasedProps;
  hasEmailSection?: boolean;
  hasPhotosSection?: boolean;
  hasUploadSection?: boolean;
  uploadDocumentsSettings?: DocumentUploadSettings[];
}

export const AttachmentsContainer = <T extends object>({
  moduleName,
  getDataBasedProps,
  hasEmailSection = true,
  hasPhotosSection = true,
  hasUploadSection = false,
  uploadDocumentsSettings,
}: AttachmentsContainerProps<T>) => {
  const [dataBasedProps, setDataBasedProps] = useState<DataBasedProps>({});
  const { activeRow } = useContext(AttachmentsContext);

  const history = useHistory();
  const {
    location: { hash },
  } = history;

  const id = getHashParams(hash)?.id;

  const { data, isLoading, isError, error, isFetching } = useAttachments(
    moduleName,
    id ?? ''
  );

  useEffect(() => {
    if (activeRow?.original) {
      setDataBasedProps(
        getDataBasedProps ? getDataBasedProps(activeRow?.original as T) : {}
      );
    }
  }, [activeRow]);

  useEffect(
    () => () => {
      history.push({ search: history.location.search, hash: undefined });
    },
    []
  );

  return (
    <Attachments
      id={id}
      attachments={data}
      isLoading={isLoading || isFetching}
      isError={isError}
      error={error}
      hasEmailSection={hasEmailSection}
      hasPhotosSection={hasPhotosSection}
      hasUploadSection={hasUploadSection}
      uploadDocumentsSettings={uploadDocumentsSettings}
      {...dataBasedProps}
    />
  );
};
