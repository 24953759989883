import { Box } from '@material-ui/core';
import { Icon, IconName } from 'components/common';
import { FC, useState, useEffect, SyntheticEvent } from 'react';
import { AttachmentType } from 'types';
import { CustomAttachment } from '../AttachmentsInfo/AttachmentsInfo.model';
import { Text, StyledCheckbox, Wrapper } from './AttachmentView.styles';
import { useDownloadAndStoreAttachment } from 'hooks/cfr-api/useDownloadAndStoreAttachment';

export interface AttachmentViewProps {
  file: CustomAttachment;
  checked?: boolean;
  handleCheck?: (name: string, link: string, checked: boolean) => void;
}

export const AttachmentView: FC<AttachmentViewProps> = ({
  file,
  checked,
  handleCheck,
}) => {
  const { _type, Path, FullPath, FileTitle } = file;
  const isImage = _type === AttachmentType.IMAGE;
  const [isChecked, setIsChecked] = useState(checked);

  const downloadAndStoreAttachment = useDownloadAndStoreAttachment();

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  const onCheckHandler = (name: string, link: string) => {
    setIsChecked(!isChecked);
    handleCheck && handleCheck(name, link, !isChecked);
  };

  const downloadAttachmentIfDoesNotExist = (
    e: SyntheticEvent<HTMLImageElement>,
    LineUUID: string
  ) => {
    downloadAndStoreAttachment.mutate(
      {
        LineUUID,
        Path,
        FileTitle,
      },
      {
        onSuccess: () => {
          const target = e.target as HTMLInputElement;
          target.onerror = null;
          target.src = FullPath + '?t=' + new Date().getTime();
        },
      }
    );
  };

  return (
    <Wrapper>
      <img
        src={isImage ? FullPath : undefined}
        alt={isImage ? FileTitle : undefined}
        onError={(e) => downloadAttachmentIfDoesNotExist(e, file.Lineuuid)}
      />
      {handleCheck && (
        <Box top={2} left={2} position="absolute">
          <StyledCheckbox
            onChange={() => onCheckHandler(FileTitle, FullPath)}
            checked={isChecked}
          />
        </Box>
      )}
      {!isImage && (
        <Box bgcolor="common.white" p={4} display="flex" alignItems="center">
          <Icon fontSize="1.6rem" name={IconName.file} pr={1} />
          <Text variant="caption" title={FileTitle}>
            {FileTitle}
          </Text>
        </Box>
      )}
    </Wrapper>
  );
};
