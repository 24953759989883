import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiInput-underline': {
      padding: theme.spacing('11px', 6, 2, 0),
      fontSize: theme.typography.body2.fontSize,
      '&::before': { borderBottom: `1px solid ${theme.palette.divider}` },
      '&::after, &:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&.Mui-error, &.Mui-error:after, &.Mui-error:before': {
        borderColor: theme.palette.error.main,
      },
      '& input': {
        '&::placeholder': {
          fontWeight: 'normal',
        },
      },
    },
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    borderRadius: `${theme.shape.borderRadius}px`,
    background: theme.palette.warning.contrastText,
    color: theme.palette.primary.light,
    fontSize: theme.typography.body2.fontSize,
    border: `1px solid ${theme.palette.divider}`,
    height: 28,
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
      fontSize: '1.7rem',
      marginRight: `${theme.spacing(2)}px`,
    },
    '& .MuiChip-labelSmall': {
      padding: theme.spacing(0, 3),
    },
  },
  errorChip: {
    background: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    border: `1px solid ${theme.palette.error.main}`,

    '& .MuiChip-deleteIcon': {
      color: theme.palette.error.contrastText,
    },
  },
}));

export const StyledLabel = styled.label`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
