import {
  ReactNode,
  FC,
  Context,
  createContext,
  useEffect,
  useContext,
} from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useLocalStorage } from 'hooks';
import configureInterceptors from './configureInterceptors';
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '../../constants';
import { ExternalUserIdContext } from 'context/ExternalUserIdContext';
import { useQueryClient } from 'react-query';
import { useUserProfile } from 'hooks/cfr-api';

const createHeaders = (
  token: string,
  externalUserId?: string,
  isWarehouseEmployee?: boolean
): AxiosRequestConfig['headers'] => {
  const withAuthorization = token ? { Authorization: `Bearer ${token}` } : {};
  const withExternalUserId = externalUserId
    ? { 'X-User-ID': `${externalUserId}` }
    : {};
  const withIsWarehouseEmployee = isWarehouseEmployee
    ? { 'X-Warehouse-Employee': `${isWarehouseEmployee}` }
    : {};

  return {
    ...withAuthorization,
    ...withExternalUserId,
    ...withIsWarehouseEmployee,
  };
};

const client = axios.create({
  baseURL: process.env.REACT_APP_CFR_API_ENDPOINT || '',
});

configureInterceptors(client);

interface AxiosInstanceType {
  client: AxiosInstance;
}

const CfrApiContext: Context<AxiosInstanceType> =
  createContext<AxiosInstanceType>({
    client,
  });

interface ContextProvidersProps {
  children: ReactNode;
}
const CfrApiProvider: FC<ContextProvidersProps> = ({
  children,
}: ContextProvidersProps) => {
  const [accessToken] = useLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN_KEY, '');
  const { externalUserId } = useContext(ExternalUserIdContext);
  const { data: user } = useUserProfile();

  const queryClient = useQueryClient();

  useEffect(() => {
    client.defaults.headers = createHeaders(
      accessToken,
      externalUserId,
      user?.is_warehouse_employee
    );
  }, [accessToken, externalUserId]);

  useEffect(() => {
    queryClient.invalidateQueries('reporting');
  }, [externalUserId]);

  return (
    <CfrApiContext.Provider
      value={{
        client,
      }}
    >
      {children}
    </CfrApiContext.Provider>
  );
};

export { CfrApiContext, CfrApiProvider };
