import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { drawerWidth: number }>(
  (theme: Theme) => ({
    hide: {
      display: 'none',
    },
    drawer: ({ drawerWidth }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    }),
    paper: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    paperAnchorDockedLeft: {
      borderRight: 0,
    },
    drawerOpen: ({ drawerWidth }) => ({
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(12),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(17),
      },
    },
    contentShift: ({ drawerWidth }) => ({
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: -drawerWidth + theme.spacing(12),
      [theme.breakpoints.up('sm')]: {
        marginLeft: -drawerWidth + theme.spacing(17) + 'px !important',
      },
    }),
    content: ({ drawerWidth }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: 0,
      paddingLeft: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      [theme.breakpoints.only('xs')]: {
        marginLeft: -drawerWidth + theme.spacing(12),
      },
      [theme.breakpoints.only('sm')]: {
        marginLeft: -drawerWidth + theme.spacing(17),
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(3),
      },
    }),
    arrowWrapper: {
      color: theme.palette.common.white,
      position: 'absolute',
      right: 0,
      bottom: 120,
      width: '3rem',
      height: '3rem',
      borderRadius: '0 0 4px 4px',
      [theme.breakpoints.up('sm')]: {
        width: '4.5rem',
        height: '4.5rem',
      },
    },
    arrow: {
      color: theme.palette.common.white,
      height: '100%',
      width: '100%',
      padding: 0,
      textAlign: 'center',
      minWidth: 'inherit',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    minimalPanel: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      width: theme.spacing(12),
      height: '100%',
      background: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(17),
      },
    },
  })
);
