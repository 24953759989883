import { UserProfile } from 'types';
import { useContext, useEffect, useState } from 'react';
import { ItemWrapper } from 'components/common/DropDownMenu/DropDownMenu.styles';
import { DropDownMenu } from 'components/common';
import { Box, Divider, Typography } from '@material-ui/core';
import { ExternalUserIdContext } from 'context/ExternalUserIdContext';
import { useLocalStorage } from 'hooks';
import { LOCAL_STORAGE_EXTERNAL_USER_ID } from '../../../../constants';

export interface ExternalUserIdSelectorProps {
  external_user_ids: UserProfile['external_user_ids'];
}
export const ExternalUserIdSelector = ({
  external_user_ids,
}: ExternalUserIdSelectorProps) => {
  const [isOpen, setOpen] = useState(false);
  const [localStorageExternalUserId, setLocalStorageExternalUserId] =
    useLocalStorage(LOCAL_STORAGE_EXTERNAL_USER_ID, '');

  const { externalUserId, setExternalUserId } = useContext(
    ExternalUserIdContext
  );

  useEffect(() => {
    if (external_user_ids && external_user_ids.length > 0) {
      const newExternalUserId = external_user_ids.includes(
        localStorageExternalUserId
      )
        ? localStorageExternalUserId
        : external_user_ids[0];
      setExternalUserId(newExternalUserId);

      if (newExternalUserId != localStorageExternalUserId) {
        setLocalStorageExternalUserId(newExternalUserId);
      }
    }
  }, [external_user_ids, localStorageExternalUserId]);

  const onSelectId = (id: string) => {
    setExternalUserId(id);
    setLocalStorageExternalUserId(id);
    setOpen(false);
  };

  if (external_user_ids && external_user_ids.length > 1) {
    return (
      <DropDownMenu
        isOpen={isOpen}
        setIsOpen={setOpen}
        label={externalUserId ?? ''}
        body={
          <Box color="primary.main">
            {external_user_ids.map((id, index) => (
              <>
                {index > 0 && (
                  <Box mx={-4} my="18px">
                    <Divider />
                  </Box>
                )}
                <ItemWrapper key={id}>
                  <Typography
                    variant="body2"
                    onClick={() => {
                      onSelectId(id);
                    }}
                  >
                    {id}
                  </Typography>
                </ItemWrapper>
              </>
            ))}
          </Box>
        }
      />
    );
  }
  return <></>;
};
