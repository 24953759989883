import { MouseEvent } from 'react';
import { Icon, IconName } from 'components/common';
import theme from 'styles/theme';
import { Row } from 'react-table';
import { StyledIconButton, Container } from './ExpandCell.styles';

interface ExpandCellProps<T extends object> {
  row: Row<T>;
}

export const ExpandCell = <T extends object>({ row }: ExpandCellProps<T>) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    row.toggleRowExpanded(!row.isExpanded);
  };

  return (
    <Container onClick={onClick} data-testid="expand-cell">
      <StyledIconButton>
        <Icon
          fontSize="2.1rem"
          color={
            row.isExpanded ? theme.palette.info.main : theme.palette.grey[300]
          }
          name={row.isExpanded ? IconName.collapse : IconName.expand}
        />
      </StyledIconButton>
    </Container>
  );
};
