import { FC } from 'react';
import { Box } from '@material-ui/core';
import { Text, StyledImage, StyledLink } from './Logo.styles';

export type LogoSize = 'small' | 'medium';
export type VariantLogo = 'light' | 'dark';

const lightLogo = '/images/CFR_Classic.png';
const darkLogo = '/images/CFR_Classic.png';

export interface LogoProps {
  hasText?: boolean;
  variant?: VariantLogo;
  size?: LogoSize;
}

export const Logo: FC<LogoProps> = ({
  hasText = false,
  size = 'medium',
  variant = 'dark',
}) => {
  const text = 'Global Logistics Solutions';
  const isLight = variant === 'light';
  const ImgUrl = isLight ? lightLogo : darkLogo;

  return (
    <Box
      display="flex"
      flexDirection={hasText ? 'row' : 'column'}
      alignItems="center"
    >
      <StyledLink to="/" $isLight={isLight}>
        <StyledImage size={size} src={ImgUrl} alt={text} $hasText={hasText} />
        {hasText && <Text>{text}</Text>}
      </StyledLink>
    </Box>
  );
};
