import { CfrApiError, Invoice } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import { useCfrApiClient, useQueryGetRequest } from 'hooks/cfr-api';

export const useInvoice = (
  Txnouuid: string
): UseQueryResult<Invoice, Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<Invoice>(client, `reporting/invoices/${Txnouuid}/`);
};
