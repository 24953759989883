import { CfrApiError, WarehouseReceipt } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import { useCfrApiClient, useQueryGetRequest } from 'hooks/cfr-api';

export const useWarehouseReceipt = (
  Txnouuid: string,
  enabled?: boolean
): UseQueryResult<WarehouseReceipt, Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<WarehouseReceipt>(
    client,
    `reporting/warehouse-receipt/${Txnouuid}/`,
    { enabled }
  );
};
