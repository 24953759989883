import styled from 'styled-components';
import { Typography, Box } from '@material-ui/core';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 2)};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.warning.contrastText};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  margin: ${({ theme }) => theme.spacing(0, 2, 2, 0)};
`;

export const Text = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
  max-width: 9.4rem;
`;

export const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 1.8rem;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;
