export enum MetricValueFormat {
  MONEY = 'money',
}
export interface DashboardWidget {
  name: string;
  label: string;
  widgets?: DashboardWidget[];
  metrics?: Metric[];
}

export interface Metric {
  label: string;
  value: string | number;
  format?: MetricValueFormat;
}
