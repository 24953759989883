import { LinkCell } from 'components/table/Table/Cells/LinkCell';
import { useContext, MouseEvent, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AttachmentsContext, AttachmentsDrawerTypes } from 'context';
import { Row } from 'react-table';

interface OpenAttachmentsCellProps {
  id: string;
  title: string;
  type?: AttachmentsDrawerTypes;
  row: Row<any>;
}

export const OpenAttachmentsCell: FC<OpenAttachmentsCellProps> = ({
  id,
  title,
  type = 'attachments',
  row,
}) => {
  const history = useHistory();
  const { setIsAttachmentsDrawerOpen, setIsUploadDrawerOpen, setActiveRow } =
    useContext(AttachmentsContext);

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    history.push({
      search: history.location.search,
      hash: `${type}/${id}`,
    });

    setActiveRow(row);

    switch (type) {
      case 'attachments':
        setIsAttachmentsDrawerOpen(true);
        break;

      case 'upload':
        setIsUploadDrawerOpen(true);
    }
  };

  return <LinkCell onClick={onClick}>{title}</LinkCell>;
};
