import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DEFAULT_REQUEST_PARAMS,
  RequestParams,
  FilterOperators,
  SearchRequestParams,
} from './base';
import { ModuleName } from 'types';
import { InventorySearchQuery } from 'pages/VehicleInventoryOverview/VehicleInventoryOverview';

const TAB_MODULE_NAME: { [key: string]: string } = {
  [ModuleName.VEHICLE_INVENTORY]: InventorySearchQuery.VEHICLES,
  [ModuleName.WAREHOUSE_RECEIPTS]: InventorySearchQuery.FREIGHT,
};

export const useRequestParamsBasedOnUrl = (
  moduleName?: ModuleName
): [RequestParams, (params: RequestParams) => void] => {
  const history = useHistory();
  const [requestParams, setRequestParams] = useState<RequestParams>(
    DEFAULT_REQUEST_PARAMS
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const filterParam = queryParams.get('filter');
    const tabParam = queryParams.get('tab');

    if (
      !filterParam ||
      (tabParam && moduleName && tabParam !== TAB_MODULE_NAME[moduleName])
    ) {
      return;
    }

    const search: SearchRequestParams = {};
    const filterFields = filterParam?.split(';') || [];

    filterFields.forEach((filterField) => {
      const [field = '', value = ''] = filterField?.split('__') || [];
      if (field && value) {
        search[field] = {
          operator: FilterOperators.IN,
          value,
        };
      }
    });

    if (Object.keys(search).length) {
      setRequestParams({
        ...DEFAULT_REQUEST_PARAMS,
        search,
      });
    }
  }, [history.location.search]);

  return [requestParams, setRequestParams];
};
