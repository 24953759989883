import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { UserAuthInfo } from 'types';
import { getNowDate, onAxiosRequestError, safelyParseJson } from 'utils';
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../../constants';

const refreshAccessToken = (
  client: AxiosInstance,
  refresh: string,
  request: AxiosRequestConfig
) =>
  client
    .post<UserAuthInfo>('/auth/jwt/refresh/', { refresh })
    .then((response) => {
      localStorage.setItem(
        LOCAL_STORAGE_ACCESS_TOKEN_KEY,
        JSON.stringify(response.data.access || '')
      );

      client.defaults.headers['Authorization'] =
        'Bearer ' + response.data.access;
      request.headers['Authorization'] = 'Bearer ' + response.data.access;

      return client(request);
    })
    .catch((err) => {
      console.error(err);
    });

const cancelRequestForReportingWithoutUserIdHeader = (
  config: AxiosRequestConfig
) => {
  if (
    config.url?.startsWith('reporting/') &&
    !config.headers['X-User-ID'] &&
    !config.headers['X-Warehouse-Employee']
  ) {
    throw new axios.Cancel('Operation canceled');
  }

  delete config.headers['X-Warehouse-Employee'];

  return config;
};

const configureInterceptors = (client: AxiosInstance) => {
  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const { response: errorResponse, config: originalRequest } = error;
      const isAuthorizationError = errorResponse?.status === 401;

      if (
        isAuthorizationError &&
        originalRequest.url ===
          process.env.REACT_APP_CFR_API_ENDPOINT + 'auth/jwt/refresh/'
      ) {
        window.location.href = '/login';
        return Promise.reject(error);
      }

      if (
        isAuthorizationError &&
        errorResponse?.data.code === 'token_not_valid'
      ) {
        const localStorageRefreshToken = safelyParseJson(
          localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY) || ''
        ) as string;
        const sessionStorageRefreshToken = safelyParseJson(
          sessionStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY) || ''
        ) as string;

        const refresh = localStorageRefreshToken || sessionStorageRefreshToken;

        if (refresh) {
          const tokenParts = JSON.parse(atob(refresh.split('.')[1]));
          const now = getNowDate();

          if (tokenParts.exp > now) {
            return refreshAccessToken(client, refresh, originalRequest);
          } else {
            onAxiosRequestError(
              `Refresh token is expired', ${(tokenParts.exp, now)}`
            );
          }
        } else {
          onAxiosRequestError('Refresh token not available.');
        }
      }

      return Promise.reject(error);
    }
  );
  client.interceptors.request.use(cancelRequestForReportingWithoutUserIdHeader);
};

export default configureInterceptors;
