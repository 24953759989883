import styled from 'styled-components';

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 10;

  &:hover {
    cursor: pointer;

    p,
    span {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`;
