import { CfrApiError, UserProfile } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import { useCfrApiClient, useQueryGetRequest } from 'hooks/cfr-api';

export const useUserProfile = (): UseQueryResult<
  UserProfile,
  Error | CfrApiError
> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<UserProfile>(client, 'users/me/');
};
