import { SelectedRows } from 'components/table/Table/Table';
import {
  Hooks,
  Row,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
} from 'react-table';
import { useEffect } from 'react';
import { KeyOfByType } from 'types';
import { Checkbox, CheckboxProps } from 'components/form';
import { CONTROL_COLUMN_WIDTH, CustomColumns } from '../../../../constants';

export type RowDataPk<T> = KeyOfByType<T, string>;

export const useRowSelectionColumn = <T extends object>(hooks: Hooks<T>) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: CustomColumns.SELECTION,
      isSticky: true,
      isControl: true,
      searchable: false,
      width: CONTROL_COLUMN_WIDTH,
      Header: ({
        getToggleAllRowsSelectedProps,
      }: UseRowSelectInstanceProps<T>) => (
        <Checkbox {...(getToggleAllRowsSelectedProps() as CheckboxProps)} />
      ),
      Cell: ({ row }: { row: UseRowSelectRowProps<T> }) => (
        <Checkbox {...(row.getToggleRowSelectedProps() as CheckboxProps)} />
      ),
    },
    ...columns,
  ]);
};

export interface UseRowSelectionChangeDetectionProps<T extends object> {
  onChangeRowsSelection(
    selectedRows: SelectedRows<T>,
    isAllRowsSelected: boolean
  ): void;
  selectedFlatRows: Row<T>[];
  isAllRowsSelected: boolean;
  rowDataPk: RowDataPk<T>;
}

export const useRowSelectionChangeDetection = <T extends object>({
  onChangeRowsSelection,
  selectedFlatRows,
  isAllRowsSelected,
  rowDataPk,
}: UseRowSelectionChangeDetectionProps<T>) => {
  useEffect(() => {
    const originalRows: SelectedRows<T> = selectedFlatRows.map(
      (row: Row<T>) => row.original[rowDataPk]
    );
    onChangeRowsSelection(originalRows, isAllRowsSelected);
  }, [selectedFlatRows]);
};
