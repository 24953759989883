import { Box, Typography } from '@material-ui/core';
import { useIsDevice } from 'hooks';
import { TitleWrapper } from '../../DrawerTimelineCell/DrawerTimelineCell.styles';
import { Drawer, Input, Button, FormError } from 'components';
import { FC, useState, FormEvent, useEffect, useContext } from 'react';
import { CloseButton } from 'components/attachments/CloseButton';
import { useSendEmailContact } from 'hooks/cfr-api';
import { FileUploadSection } from '../../attachments/EmailSection/FileUploadSection';
import { ContactFormContext, NotificationContext } from 'context';

const DRAWER_WIDTH = 600;

export interface FormFields {
  subject: string;
  message: string;
  files: File[];
}

type OptionErrors = { [key: string]: string };

export interface FormErrors {
  message: string;
  files: string;
  autocompleteOptions: OptionErrors;
}

const DEFAULT_CONTACT_FORM_VALUES = {
  subject: '',
  message: '',
  files: [],
};

const DEFAULT_CONTACT_FORM_ERRORS = {
  message: '',
  autocompleteOptions: {},
  files: '',
};

const defaultEmailSubject = 'Contact Form Question';

export const getFormData = (data: FormFields): FormData => {
  const formData = new FormData();

  formData.append('message', data.message);
  formData.append('subject', data.subject);

  data.files.forEach((file) => {
    formData.append('files', file);
  });

  return formData;
};

export const ContactForm: FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [formData, setFormData] = useState<FormFields>(
    DEFAULT_CONTACT_FORM_VALUES
  );
  const [formErrors, setFormErrors] = useState<FormErrors>(
    DEFAULT_CONTACT_FORM_ERRORS
  );
  const [apiError, setApiError] = useState('');
  const { setIsNotificationOpen, setNotificationInfo } =
    useContext(NotificationContext);

  const isDesktop = useIsDevice('desktop');

  const { isLoading, mutateAsync: onSendEmail } = useSendEmailContact();

  const drawerWidth = isDesktop ? DRAWER_WIDTH + 'px' : '100%';

  const { isContactFormOpen, setIsContactFormOpen } =
    useContext(ContactFormContext);

  useEffect(() => {
    setFormErrors({ ...formErrors, files: '' });
    setFormData({ ...formData, files: uploadedFiles });
  }, [uploadedFiles]);

  const handleDataChanged = (name: string, value: string[] | string | null) => {
    setFormErrors({ ...formErrors, message: '' });
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setApiError('');

    try {
      const data = getFormData({
        ...formData,
        subject: formData.subject || defaultEmailSubject,
        message: formData.message,
      });

      await onSendEmail(data);
      setNotificationInfo({
        status: 'success',
        text: 'The email has been sent.',
      });
    } catch (error) {
      const message =
        'Error occurred during email sending, please, try one more time or contact administrator.';
      setApiError(message);
      setNotificationInfo({
        status: 'error',
        text: message,
      });
    } finally {
      setIsNotificationOpen(true);
      setIsContactFormOpen(false);
      setFormData(DEFAULT_CONTACT_FORM_VALUES);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isContactFormOpen}
      onClose={() => setIsContactFormOpen(false)}
      width={drawerWidth}
      onClick={(e) => e.stopPropagation()}
    >
      <Box px={16} py="58px" display="flex" flex={1} flexDirection="column">
        <TitleWrapper>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">Do you have a question?</Typography>
          </Box>
          <CloseButton onClose={() => setIsContactFormOpen(false)} />
        </TitleWrapper>

        <form onSubmit={handleSubmit}>
          <Box pb={7}>
            <Input
              autoComplete="off"
              type="text"
              name="subject"
              label="Subject"
              placeholder={defaultEmailSubject}
              defaultValue={defaultEmailSubject}
              onChange={(e) => handleDataChanged(e.target.name, e.target.value)}
              value={formData.subject}
            />
          </Box>

          <Box pb={4}>
            <Input
              autoComplete="off"
              onChange={(e) => handleDataChanged(e.target.name, e.target.value)}
              rows={11}
              multiline={true}
              maxCharsCount={150}
              name="message"
              label="Message"
              placeholder="Your message"
              error={!!formErrors.message}
              helperText={formErrors.message}
              value={formData.message}
            />
          </Box>

          <Box pb={2}>
            <FileUploadSection onFileUpload={setUploadedFiles} />
            {!!formErrors.files.length && (
              <Box pt={2} color="error.main">
                <Typography color="inherit" variant="overline">
                  {formErrors.files}
                </Typography>
              </Box>
            )}
          </Box>

          {apiError && (
            <Box py={4}>
              <FormError error={apiError} />
            </Box>
          )}

          <Box display="flex" justifyContent="flex-end" pt={3}>
            <Box width={100} pr={2}>
              <Button
                fullWidth={true}
                onClick={() => setIsContactFormOpen(false)}
                color="default"
                disabled={isLoading}
              >
                Cancel
              </Button>
            </Box>

            <Box width={100}>
              <Button
                autoFocus
                color="primary"
                fullWidth={true}
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Send
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};
