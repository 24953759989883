import { pipe } from 'fp-ts/lib/function';
import {
  isNullable,
  isEmptyString,
  isString,
  isRequiredValidator,
  Validator,
} from 'utils';
import { isFalse } from 'utils/predicates';
import { isBoolean } from 'utils/refinements';

type Invalid = { errorMessage: string; isValid: false };
type Valid<T> = { value: T; isValid: true };
export type Validation<T> = Invalid | Valid<T>;

export const invalid = (errorMessage: string): Invalid => ({
  isValid: false,
  errorMessage,
});

export const valid = <T>(value: T): Valid<T> => ({
  isValid: true,
  value,
});

export const isValid = <T>(
  validatedValue: Validation<T>
): validatedValue is Valid<T> => validatedValue.isValid;

export const getErrorOrElse = (
  validatedValue: Validation<unknown>,
  defaultValue: string
): string =>
  !isValid(validatedValue) ? validatedValue.errorMessage : defaultValue;

export const validateRequired =
  <T>(validators?: readonly Validator<T>[]) =>
  (value: T | null | undefined): Validation<T> =>
    pipe(
      isRequiredValidator(value),
      fold(
        (error) => invalid(error),
        (value) => {
          if (validators) {
            const error = validators
              .reduce<string[]>(
                (errors, validator) =>
                  pipe(
                    validator(value),
                    fold(
                      (error) => [...errors, error],
                      () => errors
                    )
                  ),
                []
              )
              .join(' ');

            return error.length !== 0 ? invalid(error) : valid(value);
          }
          return valid(value);
        }
      )
    );

export const validateOptional =
  <T>(validators: readonly Validator<T>[]) =>
  (value: T | null | undefined): Validation<T | null | undefined> => {
    if (
      isNullable(value) ||
      (isString(value) &&
        (isEmptyString(value) || isEmptyString(value.trim()))) ||
      (isBoolean(value) && isFalse(value))
    ) {
      return valid(value);
    }
    return validateRequired(validators)(value);
  };

export function validate<T>(
  value: T | null | undefined,
  validators: readonly Validator<T>[],
  required: true
): Validation<T>;
export function validate<T>(
  value: T | null | undefined,
  validators: readonly Validator<T>[],
  required: false
): Validation<T | null | undefined>;
export function validate<T>(
  value: T | null | undefined,
  validators: readonly Validator<T>[],
  required: boolean
): Validation<T | null | undefined>;
export function validate<T>(
  value: T | null | undefined,
  validators: readonly Validator<T>[],
  required: boolean
): Validation<unknown> {
  return required
    ? validateRequired(validators)(value)
    : validateOptional(validators)(value);
}

export const map: <T, R>(
  f: (value: T) => R
) => (validation: Validation<T>) => Validation<R> = (f) => (validation) =>
  isValid(validation)
    ? valid(f(validation.value))
    : invalid(validation.errorMessage);

export const chain: <V, R>(
  f: (value: V) => Validation<R>
) => (value: Validation<V>) => Validation<R> = (f) => (value) =>
  isValid(value) ? f(value.value) : invalid(value.errorMessage);

export const fold: <T, R>(
  onInvalid: (error: string) => R,
  onValid: (value: T) => R
) => (value: Validation<T>) => R = (onInvalid, onValid) => (value) =>
  isValid(value) ? onValid(value.value) : onInvalid(value.errorMessage);

export const isFormValid = (form: {
  [key: string]: Validation<unknown>;
}): boolean => Object.values(form).every((entity) => entity.isValid);
