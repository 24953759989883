import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const EmailWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  background: ${({ theme }) => theme.palette.background.paper};
  transition: width 0.3s;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  top: 0;
  right: 0;
  bottom: 0;
  border-left: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  overflow: auto;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    position: static;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(7)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(14, 8)};
  }
`;

export const Title = styled(Typography)`
  font-size: 2rem;
`;

export const TitleWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(6)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
