import { ModuleName, VehicleInventory } from 'types';
import { Column, Row } from 'react-table';
import { Status, FormatDate, OpenAttachmentsCell } from 'components/table';
import { CustomColumns } from '../../../constants';
import { NotesCell } from 'components/table';

export const vehicleInventoryColumns: Column<VehicleInventory>[] = [
  {
    accessor: 'StatusID',
    Cell: ({ value }) => <Status value={value} />,
    isSticky: true,
    searchable: false,
  },
  {
    accessor: 'VehicleVinNumber',
    isSticky: true,
    width: 170,
    Cell: ({ row, value }) => (
      <OpenAttachmentsCell id={row.original.Txnouuid} title={value} row={row} />
    ),
  },
  {
    accessor: 'WRnumber',
    width: 150,
  },
  {
    accessor: 'NumberOfCarKeys',
    width: 120,
  },
  {
    accessor: 'VehicleYear',
    width: 90,
  },
  {
    accessor: 'VehicleMileage',
    width: 100,
  },
  {
    accessor: 'VehicleInspDate',
    Cell: FormatDate,
    searchControl: 'date-range',
    width: 130,
  },
  {
    accessor: 'TimeReceived',
    searchable: false,
  },
  {
    accessor: 'VehicleState',
    width: 100,
  },
  {
    accessor: 'ShippingInstr',
    width: 170,
  },
  {
    accessor: 'WarehouseID',
    searchControl: 'warehouse',
  },
  {
    accessor: 'TitleReceived',
    searchControl: 'select',
    searchOptions: [
      {
        title: 'None',
        value: '',
      },
      {
        title: 'Yes',
        value: '1',
      },
      {
        title: 'No',
        value: '0',
      },
    ],
  },
  {
    accessor: 'CName',
    width: 155,
  },
];

export const vehicleInventoryCustomColumns: Column<VehicleInventory>[] = [
  {
    id: CustomColumns.UPLOAD_ATTACHMENTS,
    width: 150,
    searchable: false,
    Header: 'Upload Documents',
    Cell: ({ row }: { row: Row<VehicleInventory> }) => (
      <OpenAttachmentsCell
        id={row.original.Txnouuid}
        title="Upload"
        type="upload"
        row={row}
      />
    ),
    positionIndex: 2,
    tooltip: {
      title: 'Upload Attachments',
      description: 'Upload required documents',
    },
  },
  {
    id: CustomColumns.NOTES,
    width: 120,
    searchable: false,
    Header: 'Notes',
    Cell: ({ row }: { row: Row<VehicleInventory> }) => (
      <NotesCell
        subtitle={row.original.StatusID}
        moduleName={ModuleName.VEHICLE_INVENTORY}
        id={row.original['Txnouuid']}
      />
    ),
    positionIndex: 4,
  },
];
