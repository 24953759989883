import { FC, useContext } from 'react';
import { InnerDropzone } from 'components/common';
import { FileWithPath } from 'react-dropzone';
import { NotificationContext } from 'context';
import { Attachment } from 'types';
import {
  DocumentUploadParams,
  getFileName,
  getUploadDocumentFormData,
} from './DocumentUpload.model';
import { useAttachmentUpload } from 'hooks/cfr-api';
import { getExtension } from 'utils/string';
import moment from 'moment';

export interface DocumentUploadSettings {
  title: string;
  code: string;
}

interface DocumentUploadProps {
  uploadParams: DocumentUploadParams;
  document?: Attachment;
  settings?: DocumentUploadSettings;
  discardAfterUpload?: boolean;
}

export const DocumentUpload: FC<DocumentUploadProps> = ({
  settings,
  uploadParams,
  document,
  discardAfterUpload = false,
}) => {
  const { setIsNotificationOpen, setNotificationInfo } =
    useContext(NotificationContext);

  const { mutateAsync: upload, isLoading } = useAttachmentUpload(
    uploadParams.id,
    uploadParams.moduleName
  );

  const uploadDocument = async (
    files: FileWithPath[],
    setFiles: (files: FileWithPath[]) => void
  ) => {
    try {
      const file = files[0];
      const extension = getExtension(file.name) ?? '';
      const dateTime = moment().format('MMDDYYYY_HHmmss');
      const fileName = settings
        ? `${getFileName(settings, uploadParams.body)}_${dateTime}.${extension}`
        : file.name;

      await upload(
        getUploadDocumentFormData({
          uploadParams: uploadParams.body,
          file,
          fileName,
        })
      );

      setNotificationInfo({
        status: 'success',
        text: `The file is uploaded with name ${fileName}! You will see the file after verification, it usually takes up to 30 seconds.`,
      });
    } catch (error) {
      const message =
        'Error occurred during document uploading, please, try one more time or contact administrator.';
      setNotificationInfo({
        status: 'error',
        text: message,
      });
    } finally {
      setIsNotificationOpen(true);

      if (discardAfterUpload) {
        setTimeout(() => {
          setFiles([]);
        }, 2000);
      }
    }
  };

  return (
    <InnerDropzone
      documentType={settings?.title}
      isLoading={isLoading}
      document={document}
      noDragEventsBubbling={true}
      onDrop={uploadDocument}
      hasDeleteButton={false}
    />
  );
};
