import { makeStyles, Theme } from '@material-ui/core';
import { TooltipStyleProps } from './Tooltip';

export const useStyles = makeStyles<Theme, TooltipStyleProps>(
  (theme: Theme) => ({
    tooltip: ({ borderRadius, width }) => ({
      borderRadius,
      width: width + 'px',
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'normal',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px 4px rgba(30, 44, 101, 0.1)',
      padding: theme.spacing(4),
      '& .MuiTooltip-arrow::before': {
        color: theme.palette.common.white,
      },
    }),
  })
);
