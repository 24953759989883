import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const StyledDateInput = styled(KeyboardDatePicker)`
  .MuiInputBase-root {
    font-size: 1rem;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.background.default};

    .MuiInputBase-input {
      padding: ${({ theme }) => theme.spacing('6px', 3)};
      line-height: 16px;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      z-index: 1;
    }
  }

  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:hover:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }

  .MuiIconButton-root {
    padding: 6px;
    margin-top: 2px;
  }
`;
