import { FilterOperators } from 'hooks/cfr-api';

export enum FilterType {
  QUICK_FILTER = 'quick_filter',
  MULTISELECT = 'multiselect',
}

export interface FilterValue {
  title: string;
  description: string;
  value: string;
  count: number;
}

export interface Filter {
  field: string;
  title: string;
  type: FilterType;
  operator: FilterOperators;
  values: FilterValue[];
}
