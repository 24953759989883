import { FC, useEffect, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { TooltipWrapper, ItemWrapper, Label } from './DropDownMenu.styles';
import { Tooltip } from 'components/common/Tooltip';
import { Icon, IconName } from 'components/common/Icon';
import * as React from 'react';

export interface DropDownMenuProps {
  body: NonNullable<React.ReactNode>;
  label: string;
  labelIcon?: React.ReactNode;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}
export const DropDownMenu: FC<DropDownMenuProps> = ({
  body,
  label,
  labelIcon,
  isOpen: isOpenProp = false,
  setIsOpen: setIsOpenProp,
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpenProp || false);
  }, [isOpenProp]);

  const handleTooltipClose = () => {
    setOpen(false);
    if (typeof setIsOpenProp == 'function') {
      setIsOpenProp(false);
    }
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    if (typeof setIsOpenProp == 'function') {
      setIsOpenProp(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <TooltipWrapper>
        <Tooltip
          borderRadius={10}
          placement="bottom-end"
          interactive
          title={body}
          arrow={true}
          onClose={handleTooltipClose}
          open={isOpen}
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
        >
          <ItemWrapper
            onClick={isOpen ? handleTooltipClose : handleTooltipOpen}
          >
            {labelIcon}
            <Label variant="body1">{label}</Label>
            <Icon
              ml={1}
              mt={1}
              fontSize="1.8rem"
              name={isOpen ? IconName.angleUp : IconName.angleDown}
            />
          </ItemWrapper>
        </Tooltip>
      </TooltipWrapper>
    </ClickAwayListener>
  );
};
