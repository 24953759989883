import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from 'components/common';
import { RequestParams } from 'hooks/cfr-api';
import { useShowTooltips } from 'hooks';
import { Filter } from 'types';
import { useHistory } from 'react-router-dom';
import { FilterTooltip } from './Tooltip';

export interface QuickFilterProps {
  requestParams: RequestParams;
  filter: Filter;
  setRequestParams(params: RequestParams): void;
}

export const QuickFilter: FC<QuickFilterProps> = ({
  requestParams,
  filter,
  setRequestParams,
}) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [showTooltip] = useShowTooltips();

  const onFilter = (filterValue: string) => {
    const search = { ...requestParams.search };

    if (search[filter.field]?.value === filterValue) {
      delete search[filter.field];
      params.delete('filter');
    } else {
      search[filter.field] = {
        value: filterValue,
        operator: filter.operator,
      };
      params.set('filter', `${filter.field}__${filterValue}`);
    }

    history.push({
      ...history.location,
      search: params.toString(),
    });

    setRequestParams({
      ...requestParams,
      offset: 0,
      search,
    });
  };

  return (
    <>
      {filter.values.map((filterValue) => (
        <Grid key={filterValue.title} item>
          <Button
            isActive={
              requestParams?.search?.[filter.field]?.value === filterValue.value
            }
            onClick={() => onFilter(filterValue.value)}
            variant="text"
            endIcon={
              showTooltip && (
                <FilterTooltip
                  title={filterValue.title}
                  description={filterValue.description}
                  showTooltip={showTooltip}
                />
              )
            }
          >
            {`${filterValue.title} (${filterValue.count})`}
          </Button>
        </Grid>
      ))}
    </>
  );
};
