import { Column, ColumnInstance, Hooks } from 'react-table';

export const useCustomColumns = <T extends object>(
  hooks: Hooks<T>,
  customColumns: Column<T>[]
) => {
  hooks.visibleColumns.push((columns) => {
    const newColumns = [...columns];

    customColumns.forEach((column) => {
      newColumns.splice(
        column.positionIndex === -1
          ? columns.length
          : column.positionIndex ?? 0,
        0,
        column as ColumnInstance<T>
      );
    });

    return newColumns;
  });
};
