import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(10)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 300px;
`;
