import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './Badge.styles';

export type BadgeVariant =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'darkGreen'
  | 'gray';

export interface BadgeStyleProps {
  variant: BadgeVariant;
}

export interface BadgeProps extends BadgeStyleProps {
  text: string;
}

export const Badge: FC<BadgeProps> = ({ text, variant }) => {
  const classes = useStyles({
    variant: variant || 'gray',
  });

  return (
    <Box data-testid="badge" className={classes.root}>
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
};
