import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-width: 275px;
  padding: ${({ theme }) => theme.spacing(7, 9)};
  background: ${({ theme }) => theme.palette.background.paper};
  border: ${({ theme }) => `1px solid ${theme.palette.grey.A100}`};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0px 0px 8px rgba(30, 44, 101, 0.15);
  }
`;

export const LineWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0)};
  display: flex;
  justify-content: space-between;
`;

export const AngleRightLink = styled(Link)`
  font-size: 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const BoxTitle = styled(Typography)`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ItemValue = styled(Typography)`
  text-align: right;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.text.primary};
`;
