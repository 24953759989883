import { CfrApiError } from 'types';
import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';
import { unwrapResponse, unwrapErrorResponse } from './helpers';
import { useCfrApiClient } from 'hooks/cfr-api';

export const useSendEmailAttachments = (): UseMutationResult<
  unknown,
  Error | CfrApiError,
  FormData
> => {
  const client = useCfrApiClient();

  return useMutation((data) =>
    client
      .post(`emails/send/attachments/`, data)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  );
};
