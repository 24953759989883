import { useCfrApiClient } from 'hooks/cfr-api/base';
import { CfrApiError } from 'types';
import { useMutation } from 'react-query';
import { unwrapErrorResponse, unwrapResponse } from 'hooks/cfr-api/helpers';
import { UseMutationResult } from 'react-query/types/react/types';

export interface UseDownloadAndStoreAttachmentInput {
  LineUUID: string;
  Path: string;
  FileTitle: string;
}

export const useDownloadAndStoreAttachment = (): UseMutationResult<
  unknown,
  Error | CfrApiError,
  UseDownloadAndStoreAttachmentInput
> => {
  const client = useCfrApiClient();

  return useMutation((data) =>
    client
      .post(`reporting/attachments/download/`, data)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  );
};
