import { Button as MuiButton, ButtonProps, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FC } from 'react';
import { useStyles } from './Button.styles';
import clsx from 'clsx';

export interface CustomButtonProps extends ButtonProps {
  isLoading?: boolean;
  isActive?: boolean;
}

export const Button: FC<CustomButtonProps> = ({
  children,
  color = 'primary',
  size = 'medium',
  variant = 'contained',
  isActive = false,
  isLoading = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiButton
      data-testid="button"
      className={clsx(classes.root, {
        active: isActive,
      })}
      color={color}
      size={size}
      variant={variant}
      {...props}
    >
      {isLoading ? (
        <Box color="common.white" display="flex">
          <CircularProgress size={24} data-testid="load" color="inherit" />
        </Box>
      ) : (
        children
      )}
    </MuiButton>
  );
};
