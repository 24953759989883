import { FC, useEffect, useState } from 'react';
import {
  DateRangeFilterValue,
  DateFilterValue,
  SearchValue,
} from 'hooks/cfr-api';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { StyledDateRangeContainer } from './SearchDateRangeInput.styles';
import moment, { Moment } from 'moment';

export interface SearchDateRangeInputProps {
  fieldId: string;
  onDateChange(value: DateRangeFilterValue | DateFilterValue | string): void;
  value: SearchValue;
}

export const SearchDateRangeInput: FC<SearchDateRangeInputProps> = ({
  fieldId,
  onDateChange,
  value,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputShape | null>(null);

  useEffect(() => {
    if ((startDate || endDate) && !value) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [value]);

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    if (startDate) {
      setStartDate(startDate);
      if (endDate) {
        setEndDate(endDate);
        onDateChange({
          from: {
            year: moment(startDate).format('YYYY'),
            month: moment(startDate).format('MM'),
            day: moment(startDate).format('DD'),
          },
          to: {
            year: moment(endDate).format('YYYY'),
            month: moment(endDate).format('MM'),
            day: moment(endDate).format('DD'),
          },
        });
      }
    } else {
      setStartDate(null);
      setEndDate(null);
      onDateChange('');
    }
  };

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    setFocusedInput(arg);
  };

  return (
    <StyledDateRangeContainer>
      <DateRangePicker
        minDate={moment().subtract(5, 'years')}
        maxDate={moment().add(5, 'years')}
        startDate={startDate}
        startDateId={`DateRangePickerStart-${fieldId}`}
        endDate={endDate}
        endDateId={`DateRangePickerEnd-${fieldId}`}
        minimumNights={0}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        isOutsideRange={() => false}
        startDatePlaceholderText="mm-dd-yyyy"
        endDatePlaceholderText="mm-dd-yyyy"
        showDefaultInputIcon
        showClearDates
        noBorder
        small
        hideKeyboardShortcutsPanel
      />
    </StyledDateRangeContainer>
  );
};
