import theme from 'styles/theme';
import { Icon, IconName } from 'components/common';
import { Box, Typography } from '@material-ui/core';
import { StyledListItem } from '../PasswordValidationHint.styles';
import { FC } from 'react';

interface RuleProps {
  valid: boolean;
  iconSize?: number;
  validColor?: string;
  invalidColor?: string;
}

export const Rule: FC<RuleProps> = ({
  valid,
  iconSize = '1.6rem',
  validColor = theme.palette.success.main,
  invalidColor = theme.palette.error.main,
  children,
}) => (
  <StyledListItem className={valid ? 'valid' : 'invalid'}>
    {valid ? (
      <Icon
        fontSize={iconSize}
        color={validColor}
        name={IconName.checkCircle}
      />
    ) : (
      <Icon
        fontSize={iconSize}
        color={invalidColor}
        name={IconName.infoCircle}
      />
    )}
    <Box pl={2}>
      <Typography variant="body2">{children}</Typography>
    </Box>
  </StyledListItem>
);
