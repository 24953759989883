import { Pagination as MuiPagination } from '@material-ui/lab';
import { ChangeEvent, FC, useState } from 'react';
import {
  Container,
  Label,
  SelectContainer,
  useStyles,
} from './Pagination.styles';
import { Select, OptionValue } from 'components/form/Select';
import { useIsDevice } from 'hooks';
import { ROWS_PER_PAGE } from './rowsPerPage';

export interface PaginationProps {
  page: number;
  pageCount: number;
  pageSize: number;
  isDisabled?: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(size: number): void;
}

const options = ROWS_PER_PAGE.map((number) => ({
  title: number,
  value: number,
}));

export const Pagination: FC<PaginationProps> = ({
  pageCount,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const isMobile = useIsDevice('mobile');
  const [value, setValue] = useState<OptionValue>(pageSize);

  return (
    <Container>
      <MuiPagination
        size={isMobile ? 'small' : 'medium'}
        className={classes.pagination}
        count={pageCount}
        page={page}
        onChange={(event: ChangeEvent<unknown>, newPage: number) =>
          onPageChange(newPage)
        }
        boundaryCount={1}
        siblingCount={isMobile ? 1 : 2}
        color="primary"
        shape="rounded"
        disabled={isDisabled}
      />

      <SelectContainer>
        <Label>Rows:</Label>
        <Select
          value={value}
          setValue={setValue}
          isDisabled={isDisabled}
          options={options}
          onChange={onPageSizeChange}
        />
      </SelectContainer>
    </Container>
  );
};
