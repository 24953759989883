import { FC, useEffect, useState, useContext } from 'react';
import { Button, Icon, IconName, Dialog } from 'components/common';
import { Box, Grid, Badge } from '@material-ui/core';
import { CheckBoxWrapper } from 'components/table/ColumnsManagement/ColumnsManagement.styles';
import { Checkbox } from 'components/form';
import { RequestParams } from 'hooks/cfr-api';
import removeArrayItem from 'lodash/remove';
import { Filter, ModuleName } from 'types';
import { MultiSelectFilterContext } from 'context';

export interface MultiSelectFilterProps {
  requestParams: RequestParams;
  filter: Filter;
  moduleName: ModuleName;
  setRequestParams(params: RequestParams): void;
}

export const MultiSelectFilter: FC<MultiSelectFilterProps> = ({
  requestParams,
  filter,
  setRequestParams,
  moduleName,
}) => {
  const filterKey = filter.field + moduleName;

  const { isOpen, toggleIsOpen } = useContext(MultiSelectFilterContext);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    initFormData();
  }, [requestParams]);

  const initFormData = () => {
    const selectedFilter = requestParams.search?.[filter.field]?.value;
    setSelectedFilters(
      typeof selectedFilter == 'string' && selectedFilter
        ? selectedFilter.split(',')
        : []
    );
  };

  const handleClose = () => {
    initFormData();
    toggleIsOpen(filterKey);
  };

  const handleApply = () => {
    const search = { ...requestParams.search } ?? {};
    if (selectedFilters.length) {
      search[filter.field] = {
        value: selectedFilters.join(','),
        operator: filter.operator,
      };
    } else {
      delete search[filter.field];
    }

    setRequestParams({
      ...requestParams,
      search,
    });

    toggleIsOpen(filterKey);
  };

  const onChange = (filterValue: string) => {
    const newSelectedFilters = [...selectedFilters];

    if (selectedFilters.includes(filterValue)) {
      removeArrayItem(newSelectedFilters, (item) => item == filterValue);
    } else {
      newSelectedFilters.push(filterValue);
    }

    setSelectedFilters(newSelectedFilters);
  };

  return (
    <>
      <Badge badgeContent={selectedFilters.length} color="primary">
        <Button
          onClick={() => toggleIsOpen(filterKey)}
          variant="outlined"
          endIcon={<Icon name={IconName.filter} />}
        >
          {filter.title}
        </Button>
      </Badge>

      <Dialog
        dialogTitle={filter.title}
        isOpen={isOpen(filterKey)}
        onClose={handleClose}
        onApply={handleApply}
      >
        <Grid container spacing={3}>
          {filter.values.map((filterValue) => (
            <Grid
              key={filterValue.title}
              item
              xs={12}
              sm={filter.values.length > 1 ? 6 : 12}
            >
              <Box px={2}>
                <CheckBoxWrapper>
                  <Checkbox
                    label={`${filterValue.title} (${filterValue.count})`}
                    checked={selectedFilters.includes(filterValue.value)}
                    onChange={() => onChange(filterValue.value)}
                  />
                </CheckBoxWrapper>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </>
  );
};
