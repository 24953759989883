import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';

interface AttachmentsGridProps {
  title: string;
  filesCount: number;
}

export const AttachmentsGrid: FC<AttachmentsGridProps> = ({
  title,
  filesCount,
  children,
}) => (
  <>
    <Box pb={4}>
      <Typography variant="h5" component="h4">
        {title} {`(${filesCount})`}
      </Typography>
    </Box>

    <Box
      display="grid"
      gridColumnGap={{ xs: 16, md: 32 }}
      gridRowGap={{ xs: 16, md: 32 }}
      gridTemplateColumns={{
        xs: 'repeat(auto-fill, minmax(140px, 1fr))',
        sm: 'repeat(auto-fill, minmax(180px, 1fr))',
        md: 'repeat(auto-fill, minmax(210px, 1fr))',
      }}
    >
      {children}
    </Box>
  </>
);
