import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import {
  Timeline as MuiTimeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab';
import { Content, useStyles, TextDate, Location } from './Timeline.styles';
import { Icon, IconName } from 'components';
import clsx from 'clsx';

export type TimelineItem = {
  title: string;
  date?: string;
  text: string;
};
export interface TimelineProps {
  items: TimelineItem[];
  hasIcon?: boolean;
  hasTitle?: boolean;
}

export const Timeline: FC<TimelineProps> = ({
  items,
  hasIcon,
  hasTitle = true,
}) => {
  const classes = useStyles();

  return (
    <MuiTimeline className={classes.root}>
      {items.map(({ title, date, text }, index) => {
        const isLastItem = index === items.length - 1;
        const isFirstItem = index === 0;

        return (
          <Box key={index} display="flex" justifyContent="flex-start">
            <TimelineItem className={classes.item}>
              <TimelineSeparator>
                <TimelineConnector
                  className={clsx(classes.root, {
                    [classes.transparent]: isLastItem,
                  })}
                />
                {isLastItem ? (
                  <Box color="info.main" lineHeight={0}>
                    <Icon name={IconName.ellipseCheck} />
                  </Box>
                ) : (
                  <div>
                    <TimelineDot variant="outlined" className={classes.root} />
                  </div>
                )}
                <TimelineConnector
                  className={clsx(classes.root, {
                    [classes.transparent]: isFirstItem,
                  })}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Content isLastItem={isLastItem}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb={2}
                  >
                    {hasTitle && <Typography variant="h6">{title}</Typography>}
                    {date && <TextDate variant="caption">{date}</TextDate>}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {hasIcon && (
                      <Icon
                        color="info.main"
                        fontSize="2.4rem"
                        pr={2}
                        name={IconName.locationPoint}
                      />
                    )}
                    <Location variant="body2">{text}</Location>
                  </Box>
                </Content>
              </TimelineContent>
            </TimelineItem>
          </Box>
        );
      })}
    </MuiTimeline>
  );
};
