import { Box, Typography } from '@material-ui/core';
import { IconName } from 'components/common';
import { FC } from 'react';
import {
  Wrapper,
  Text,
  LineWrapper,
  Count,
  IconBackground,
} from './VehiclesCountBox.styles';
import { useHistory } from 'react-router-dom';

type VehiclesCountItem = {
  title: string;
  count: number | string;
};

export interface VehiclesCountBoxProps {
  location: string;
  data: VehiclesCountItem[];
  link: string;
}

export const VehiclesCountBox: FC<VehiclesCountBoxProps> = ({
  location,
  data,
  link,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(link);
  };

  return (
    <Wrapper onClick={handleClick}>
      <Box pb={4} display="flex" alignItems="center">
        <IconBackground name={IconName.city} />
        <Text>{location}</Text>
      </Box>
      <Box>
        {data.map(({ title, count }) => (
          <LineWrapper key={title}>
            <Typography variant="body2" color="textSecondary">
              {title}
            </Typography>
            <Box>
              <Count variant="body2">{count}</Count>
            </Box>
          </LineWrapper>
        ))}
      </Box>
    </Wrapper>
  );
};
