import { ReactNode, FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      retry: 1,
    },
  },
});

interface ContextProvidersProps {
  children: ReactNode;
}

export const ReactQueryContext: FC<ContextProvidersProps> = ({
  children,
}: ContextProvidersProps) => (
  <QueryClientProvider client={queryClient}>
    {children}
    {process.env.NODE_ENV === 'development' && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </QueryClientProvider>
);

export default ReactQueryContext;
