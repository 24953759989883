import { useQuery } from 'react-query';
import { unwrapErrorResponse, unwrapResponse } from '../helpers';
import axios, { AxiosInstance } from 'axios';
import { UseQueryResult } from 'react-query/types/react/types';
import { CfrApiError, CancellablePromise } from 'types';
import { RequestParams, useRequestParams } from './useRequestParams';

export const useQueryGetListRequest = <T>(
  client: AxiosInstance,
  url: string,
  params: RequestParams
): UseQueryResult<T, Error | CfrApiError> => {
  const queryString = useRequestParams(params) || '';

  return useQuery(
    [...url.split('/').filter((path) => path != ''), params],
    () => {
      const source = axios.CancelToken.source();

      const promise = client
        .get<T>(`${url}/${queryString}`, {
          cancelToken: source.token,
        })
        .then(unwrapResponse)
        .catch(unwrapErrorResponse) as CancellablePromise<T>;

      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
      };

      return promise;
    },
    { keepPreviousData: true }
  );
};
