import { FC } from 'react';
import { AttachmentsGrid } from '../AttachmentsGrid';
import { setFileType } from '../AttachmentsInfo.model';
import { AttachmentView } from '../../AttachmentView';
import { Box } from '@material-ui/core';
import { Attachment } from 'types';

export interface DocumentsBaseProps {
  documents: Attachment[];
  handleCheck?: (name: string, link: string, checked: boolean) => void;
  isAllChecked?: boolean;
}

type DocumentsProps = DocumentsBaseProps;

export const Documents: FC<DocumentsProps> = ({
  documents,
  isAllChecked,
  handleCheck,
}) => (
  <Box pb={10} data-testid="documents">
    <AttachmentsGrid title="Documents" filesCount={documents.length}>
      {setFileType(documents).map((document) => (
        <AttachmentView
          key={document.FileTitle + document.AttachDate}
          file={document}
          checked={isAllChecked}
          handleCheck={handleCheck}
        />
      ))}
    </AttachmentsGrid>
  </Box>
);
