export const hasValueInTheObject = <T>(data: T, key: keyof T): boolean =>
  !!data[key];

export const fromArrayOfObjects = <T>(
  array: T[],
  keyProperty: keyof T
): { [key: string]: T } =>
  array.reduce((obj: { [key: string]: T }, item) => {
    const key = item[keyProperty];
    if (typeof key == 'string') {
      obj[key] = item;
    }
    return obj;
  }, {});
