import { Button, Box, Slide, Fade } from '@material-ui/core';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useStyles } from './Sidebar.styles';
import clsx from 'clsx';
import { Icon, IconName } from '../../common/Icon';
import { Drawer } from 'components/common';

export interface SidebarProps {
  width?: number;
  setOpen?: (open: boolean) => void;
  open?: boolean;
  minimalPanel: ReactNode;
}

export const Sidebar: FC<SidebarProps> = ({
  open = true,
  width = 300,
  children,
  setOpen,
  minimalPanel,
}) => {
  const classes = useStyles({ drawerWidth: width });
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleDrawerToggle = () => {
    setOpen && setOpen(!isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <Drawer
      data-testid="drawer"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
        open: isOpen,
        close: !isOpen,
      })}
      classes={{
        paper: clsx(classes.paper, classes.paperAnchorDockedLeft, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
      variant="permanent"
      anchor="left"
    >
      <Box position="relative" height="100%">
        <Slide direction="right" in={!open}>
          <Box className={classes.minimalPanel}>
            <Box display="flex" flexDirection="column" height="100%">
              {minimalPanel}
            </Box>
          </Box>
        </Slide>

        <Fade in={open}>
          <Box height="100%">{children}</Box>
        </Fade>

        <Box className={classes.arrowWrapper}>
          <Button
            data-testid="toggle-drawer-button"
            className={classes.arrow}
            onClick={handleDrawerToggle}
          >
            {isOpen ? (
              <Icon fontSize="2rem" name={IconName.angleLeft} />
            ) : (
              <Icon fontSize="2rem" name={IconName.angleRight} />
            )}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
