import {
  validateRequired,
  Validation,
  isPasswordPatternValidator,
  noLeadingTrailingWhiteSpacesValidator,
  makeIsEqualToPasswordValidator,
} from 'utils';

export const validateEmail = (value: string): Validation<string> =>
  validateRequired([noLeadingTrailingWhiteSpacesValidator])(value);

export const validatePassword = (value: string): Validation<string> =>
  validateRequired([noLeadingTrailingWhiteSpacesValidator])(value);

export const validateResetPassword = (value: string): Validation<string> =>
  validateRequired([
    noLeadingTrailingWhiteSpacesValidator,
    isPasswordPatternValidator,
  ])(value);

export const validateConfirmedPassword = ([password, confirmPassword]: [
  string,
  string
]): Validation<string> =>
  validateRequired([makeIsEqualToPasswordValidator(password)])(confirmPassword);
