import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Icon } from 'components/common';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(6)}px;
  border: ${({ theme }) => `1px solid ${theme.palette.grey.A100}`};
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  height: 100%;
  justify-content: space-between;

  &:hover {
    box-shadow: 0px 0px 8px rgba(30, 44, 101, 0.15);
    cursor: pointer;
  }
`;

export const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2, 3)};
`;

export const Text = styled(Typography)`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Count = styled(Typography)`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const IconBackground = styled(Icon)`
  font-size: 2rem;
  background: linear-gradient(147.6deg, #eff5fb 7.84%, #efebf7 93.65%);
  width: 52px;
  height: 52px;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-right: ${({ theme }) => theme.spacing(4)}px;
`;
