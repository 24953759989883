import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const IconWrapper = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  font-weight: ${({ theme }) => theme.typography.caption.fontWeight};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 43px;
    height: 43px;
    font-size: 2rem;
  }
`;
