import { FC, useRef } from 'react';
import { InfoTooltipContent } from '../../../InfoTooltipContent';
import { Box } from '@material-ui/core';
import { Icon, IconName, Tooltip } from 'components/common';

interface TooltipProps {
  showTooltip: boolean;
  title: string;
  description: string;
}

export const FilterTooltip: FC<TooltipProps> = ({
  showTooltip,
  title,
  description,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Tooltip
      width={200}
      placement="right"
      title={<InfoTooltipContent title={title} description={description} />}
      disableFocusListener={!showTooltip}
      disableHoverListener={!showTooltip}
      disableTouchListener={!showTooltip}
    >
      {/* use spread and destructuring to allow ts use ref for Box */}
      <Box display="flex" {...{ ref }}>
        <Icon name={IconName.infoCircle} />
      </Box>
    </Tooltip>
  );
};
