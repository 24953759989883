import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 530px;
  background-color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`;

export const Copyright = styled.div`
  padding: ${({ theme }) => theme.spacing(5)}px 0;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing(5)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
    justify-content: center;
    flex-direction: row;
  }
`;

export const LogoWrapper = styled.div`
  position: static;
  padding: ${({ theme }) => theme.spacing(4)}px 0;
  align-self: flex-start;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(8)}px 0;
    position: absolute;
    padding: 0;
    right: 74px;
    top: 34px;
  }
`;

export const Main = styled.div`
  width: 100%;
  max-width: 500px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  z-index: ${({ theme }) => theme.zIndex.modal};
  margin: ${({ theme }) => theme.spacing(6)}px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: auto;
    box-shadow: 0px 0px 4px rgba(30, 44, 101, 0.05);
    margin: 0;
  }
`;

export const MainContent = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(12)}px;
    width: 500px;
  }
`;

export const StyledLink = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
