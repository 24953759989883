import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

export const Label = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: ${({ theme }) => theme.spacing(6)}px;
  }
`;

export const ItemWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  fontSize="1.6rem"
`;
