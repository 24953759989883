import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import { SelectSize } from './Select';

const getSizeStyles = (size: SelectSize, theme: Theme) => {
  switch (size) {
    case 'medium':
      return {
        paddingRight: theme.spacing(9),
      };

    case 'small':
      return {
        padding: theme.spacing('1px', 2),
      };
  }
};

export const useStyles = makeStyles<Theme, { size: SelectSize }>((theme) => ({
  root: ({ size }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    '&.MuiSelect-select': {
      ...getSizeStyles(size, theme),
    },
  }),
  selectIcon: {
    right: '7px',
  },
  selectMenuItem: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

// example from official docs: https://material-ui.com/ru/components/selects/#customized-selects
export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[200]}`,
      fontSize: theme.typography.caption.fontSize,
      padding: theme.spacing(2, 7, 2, 3),
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
      },
    },
  })
)(InputBase);
