import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const flexStyles = { display: 'flex', flexDirection: 'column', flexGrow: 1 };

export const useStyles = makeStyles<Theme, { minWidth?: number }>((theme) => ({
  tabs: {
    '& + div': {
      ...flexStyles,
    },
  },
  tab: ({ minWidth }) => ({
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    color: theme.palette.primary.main,
    minWidth: minWidth || 'inherit',
  }),
  tabContainer: {
    '& [data-swipeable]': {
      ...flexStyles,
    },
  },
}));
