import { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const TablePlaceholder: FC = () => (
  <>
    <div>
      <Grid container direction="row" justify="space-between">
        <Skeleton height={60} width="30%" />
        <Skeleton height={60} width="24%" />
      </Grid>
    </div>
    <Box pt={4}>
      <Skeleton height={55} width="100%" />
    </Box>
    <Box pb={3}>
      <Skeleton variant="rect" height={300} width="100%" />
    </Box>
    <Grid container justify="center">
      <Skeleton height={60} width="30%" />
    </Grid>
  </>
);
