import { CfrApiError, Note, ModuleName } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  useCfrApiClient,
  useQueryGetRequest,
  MODULE_DATA_URLS,
} from 'hooks/cfr-api';

export const useNotes = (
  moduleName: ModuleName,
  Txnouuid: string,
  enabled?: boolean
): UseQueryResult<Note[], Error | CfrApiError> => {
  const client = useCfrApiClient();

  // use it to get static data
  // Txnouuid = '62E8384349D44D80B2ABBC194EFA18EC-190508113916';

  return useQueryGetRequest<Note[]>(
    client,
    `${MODULE_DATA_URLS[moduleName]}/${Txnouuid}/notes/`,
    { enabled }
  );
};
