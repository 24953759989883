import styled from 'styled-components';
import { DropzoneRootProps } from 'react-dropzone';

export const getColor = ({
  theme,
  isDragAccept,
  isDragReject,
  isDragActive,
}: DropzoneRootProps): string => {
  if (isDragAccept || isDragActive) {
    return theme.palette.grey[100];
  }
  if (isDragReject) {
    return theme.palette.error.main;
  }
  return 'transparent';
};

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  margin: ${({ theme }) => theme.spacing(-2)}px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 102%;
  height: 102%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.palette.grey[200]};
  border-style: dashed;
  opacity: 0.5;
  z-index: 2;
  pointer-events: none;
  background-color: ${(props) => getColor(props)};
  display: ${({
    isDragAccept,
    isDragReject,
    isDragActive,
  }: DropzoneRootProps) =>
    isDragAccept || isDragReject || isDragActive ? 'flex' : 'none'};
`;
