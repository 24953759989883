import { SubTableContainer, TableContainer } from 'components/table';
import { ModuleName, WarehouseReceipt, WarehouseReceiptDetail } from 'types';
import { useMemo, FC, useState, useEffect } from 'react';
import { Column, Row } from 'react-table';
import { useWarehouseReceipt, useWarehouseReceiptList } from 'hooks/cfr-api';
import {
  freightInventoryColumns,
  freightCustomColumns,
} from './freightInventoryColumns';
import { freightDetailColumns } from './freightDetailColumns';
import { CustomColumns } from '../../../constants';

interface FreightOverviewProps {
  getItemsCount: (count?: number) => void;
}

const modelDataPk = 'Txnouuid';
const detailDataKey = 'Details';

const renderRowDetails = (
  row: WarehouseReceipt,
  columnsCount: number,
  controlColumnsCount: number
) => (
  <SubTableContainer<WarehouseReceiptDetail>
    dataPk={row[modelDataPk]}
    detailDataKey={detailDataKey}
    useDetailHook={useWarehouseReceipt}
    columns={freightDetailColumns}
    columnsCount={columnsCount}
    controlColumnsCount={controlColumnsCount}
  />
);

export const FreightOverview: FC<FreightOverviewProps> = ({
  getItemsCount,
}) => {
  const [itemsCount, setItemsCount] = useState<number | undefined>();

  useEffect(() => {
    getItemsCount(itemsCount);
  }, [itemsCount]);

  const columns = useMemo(
    (): Column<WarehouseReceipt>[] => freightInventoryColumns,
    []
  );

  const handleOnRowClick = (row: Row<WarehouseReceipt>) => {
    row.toggleRowExpanded(!row.isExpanded);
  };

  return (
    <TableContainer<WarehouseReceipt>
      columns={columns}
      customColumns={freightCustomColumns}
      moduleName={ModuleName.WAREHOUSE_RECEIPTS}
      useListHook={useWarehouseReceiptList}
      onRowClick={handleOnRowClick}
      onDataCountLoad={setItemsCount}
      rowDataPk={modelDataPk}
      renderRowDetails={renderRowDetails}
      hiddenColumns={[CustomColumns.TRACKING_DETAILS]}
    />
  );
};
