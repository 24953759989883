import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { DropzoneRootProps } from 'react-dropzone';

export const getColor = ({
  theme,
  isDragAccept,
  isDragReject,
  isDragActive,
  disabled,
}: DropzoneRootProps): { color: string; bgcolor: string } => {
  if (disabled) {
    return {
      color: theme.palette.grey[300],
      bgcolor: theme.palette.grey[50],
    };
  }
  if (isDragReject) {
    return {
      color: theme.palette.error.light,
      bgcolor: theme.palette.grey[50],
    };
  }
  if (isDragAccept || isDragActive) {
    return {
      color: theme.palette.info.main,
      bgcolor: theme.palette.action.hover,
    };
  }

  return {
    color: theme.palette.grey[400],
    bgcolor: theme.palette.common.white,
  };
};

export const Circle = styled.div<{ hasFiles: boolean; isError: boolean }>`
  background-color: ${({ theme, hasFiles, isError }) =>
    isError
      ? theme.palette.error.light
      : hasFiles
      ? theme.palette.success.main
      : theme.palette.grey[200]};
  border-radius: 50%;
  height: 36px;
  width: 36px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(3)}px;
  border-width: 1px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-color: ${(props) => getColor(props).color};
  border-style: dashed;
  background-color: ${(props) => getColor(props).bgcolor};
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  position: relative;
`;

export const StyledText = styled(Typography)<{ disabled: boolean }>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.grey[400] : theme.palette.info.main};

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
    cursor: pointer;
    text-decoration: underline;
  }`}
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;

export const DocumentType = styled(Typography)`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 1.3rem;
  word-break: break-word;
  text-align: center;
`;

export const TrashBox = styled.div`
  position: absolute;
  top: 9px;
  right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grey[400]};

  &:hover {
    opacity: 0.8;
  }
`;

export const Error = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
