import { Box } from '@material-ui/core';
import { MenuItem, ContactUsItem, Logo } from 'components';
import { FC } from 'react';
import { MenuItemType } from '../Menu';

export interface MinimalMenuPanelProps {
  menuItems: readonly MenuItemType[];
}

export const MinimalMenuPanel: FC<MinimalMenuPanelProps> = ({ menuItems }) => (
  <Box
    bgcolor="primary.main"
    height="100%"
    justifyContent="space-between"
    display="flex"
    flexDirection="column"
  >
    <div>
      <Box py={{ xs: 4, md: 8 }} px={2}>
        <Logo variant="light" size="small" />
      </Box>
      <Box pt={{ xs: 1, md: 3 }}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.link}
            {...item}
            hasText={false}
            iconSize="2.4rem"
            hasTooltip={true}
          />
        ))}
      </Box>
    </div>
    <Box py={8} mb={3}>
      <ContactUsItem hasTooltip={true} />
    </Box>
  </Box>
);
