import {
  Switch as MuiSwitch,
  SwitchProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './Switch.styles';

export interface CustomSwitchProps extends SwitchProps {
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  label: string;
}

export const Switch: FC<CustomSwitchProps> = ({
  value,
  label,
  labelPlacement = 'start',
  checked,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.label}
      checked={checked}
      value={value}
      label={label}
      labelPlacement={labelPlacement}
      control={
        <MuiSwitch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
          value={value}
          checked={checked}
        />
      }
    />
  );
};
