import { Badge } from 'components/common';
import { FC } from 'react';
import { formatDate, getVariant, formatPrice, Currency } from 'utils';

/**
 * Collection of small reusable components that can be rendered as cells
 */

interface CellProps {
  value: string;
}

export const Status: FC<CellProps> = ({ value }) => (
  <Badge text={value} variant={getVariant(value)} />
);

export const FormatDate: FC<CellProps> = ({ value }) => (
  <>{formatDate(value)}</>
);

export const FormatPrice: FC<CellProps> = ({ value }) => (
  <>{formatPrice(value, Currency.USD)}</>
);
