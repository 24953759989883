import { FC } from 'react';
import { ISystem } from 'lib';
import { StyledIcon } from './Icon.styles';

const req = require.context(
  '!@svgr/webpack?{"icon":"true","svgo":"true"}!./library',
  false,
  /\.svg$/
);

export enum IconName {
  angleDown = 'angle-down',
  angleUp = 'angle-up',
  arrowDown = 'arrow-down',
  arrowUp = 'arrow-up',
  angleLeft = 'angle-left',
  angleRight = 'angle-right',
  bell = 'bell',
  box = 'box',
  calender = 'calender',
  car = 'car',
  carSideview = 'car-sideview',
  dashboard = 'dashboard',
  envelope = 'envelope',
  estate = 'estate',
  expandArrows = 'expand-arrows',
  eye = 'eye',
  eyeSlash = 'eye-slash',
  file = 'file',
  filter = 'filter',
  invoice = 'invoice',
  keySkeletonAlt = 'key-skeleton-alt',
  locationPoint = 'location-point',
  plus = 'plus',
  receiptAlt = 'receipt-alt',
  search = 'search',
  ship = 'ship',
  creditCard = 'credit-card',
  plusCircle = 'plus-circle',
  checkCircle = 'check-circle',
  signout = 'signout',
  infoCircle = 'info-circle',
  exclamationCircle = 'exclamation-circle',
  at = 'at',
  logOut = 'log-out',
  accountSettings = 'account-settings',
  times = 'times',
  arrowRight = 'arrow-right',
  fileDownload = 'file-download',
  grid = 'grid',
  ellipseCheck = 'ellipse-check',
  pic = 'pic',
  city = 'city',
  paperclip = 'paperclip',
  expand = 'expand',
  collapse = 'collapse',
  check = 'check',
  trash = 'trash',
  exclamation = 'exclamation',
}

export const IconNames = Object.values(IconName);

export interface IconProps extends ISystem {
  name: IconName;
  onClick?: () => void;
}

export const Icon: FC<IconProps> = ({ name, color, ...props }) => {
  const IconSvg = req(`./${name}.svg`);

  return (
    <StyledIcon color={color} {...props}>
      <IconSvg.default />
    </StyledIcon>
  );
};
