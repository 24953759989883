import { CfrApiError, ModuleName } from 'types';
import { useMutation, useQueryClient } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';
import { MODULE_DATA_URLS, useCfrApiClient } from './base';
import { unwrapErrorResponse, unwrapResponse } from './helpers';

export const useAttachmentUpload = (
  id: string,
  moduleName: ModuleName
): UseMutationResult<unknown, Error | CfrApiError, FormData> => {
  const client = useCfrApiClient();
  const queryClient = useQueryClient();

  const invalidateQuery = () => {
    const moduleUrl = MODULE_DATA_URLS[moduleName];
    queryClient.invalidateQueries(`${moduleUrl}/${id}`, {
      refetchActive: false,
    });
  };

  return useMutation(
    (data) =>
      client
        .post(`/reporting/attachments/upload/`, data)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: () => {
        invalidateQuery();

        setTimeout(() => {
          invalidateQuery();
        }, 30000);
      },
    }
  );
};
