import { CircularProgress } from '@material-ui/core';
import { FC } from 'react';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderScreen: FC = () => (
  <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>
);
