import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 30,
      height: 16,
      padding: 0,
      margin: 0,

      '& .Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme.palette.grey[400] + '! important',
      },
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(14px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.info.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        border: '6px solid ' + theme.palette.common.white,
      },
    },
    thumb: {
      width: 12,
      height: 12,
    },
    track: {
      borderRadius: 13,
      border: `2px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.grey[200],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    label: {
      margin: 0,
      '& .MuiTypography-root': {
        paddingRight: theme.spacing(2),
        fontSize: '1.2rem',
        margin: 0,
      },
    },
  })
);
