import {
  DownloadType,
  useDownloadFile,
  UseDownloadFileResponse,
} from 'hooks/cfr-api/base/useDownloadFile';
import { ModuleName } from 'types';
import { MODULE_DATA_URLS } from 'hooks/cfr-api/base/moduleDataUrls';

export interface UsePrintPdfResponse
  extends Omit<UseDownloadFileResponse, 'download'> {
  doPrint(pk: string): void;
}

export const usePrintPdf = (moduleName: ModuleName): UsePrintPdfResponse => {
  const { download, error, isError, isLoading, isSuccess } = useDownloadFile(
    DownloadType.OPEN
  );

  return {
    error,
    isError,
    isLoading,
    isSuccess,
    doPrint: async (pk: string) => {
      await download(`${MODULE_DATA_URLS[moduleName]}/${pk}/export_pdf/`);
    },
  };
};
