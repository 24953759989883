import styled from 'styled-components';

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 2, 3, 2)};
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  margin: ${({ theme }) => theme.spacing(0, -2)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 8, 10, 8)};
    margin: ${({ theme }) => theme.spacing(0, -8, 8, -8)};
  }
`;
