import { Box, Fade, Typography } from '@material-ui/core';
import {
  EMAIL_SECTION_WIDTH,
  LOCAL_STORAGE_EMAIL_OPTIONS,
} from '../../../constants';
import { useIsDevice, useLocalStorage } from 'hooks';
import { useContext, FormEvent, useState, useEffect, FC } from 'react';
import { CloseButton } from '../CloseButton';
import {
  EmailWrapper,
  Container,
  Title,
  TitleWrapper,
} from './EmailSection.styles';
import { AttachmentsContext, NotificationContext } from 'context';
import { Autocomplete, Button, Input, FormError } from 'components';
import { validateEmailForm } from './validateEmailForm';
import { FileUploadSection } from './FileUploadSection';
import { FormFields, FormErrors, getFormData } from './EmailSection.model';
import { useSendEmailAttachments } from 'hooks/cfr-api';

interface EmailSectionProps {
  title: string;
  defaultEmailSubject?: string;
}

export const DEFAULT_FORM_VALUES = {
  to: [],
  cc: [],
  bcc: [],
  subject: '',
  message: '',
  files: [],
};

export const DEFAULT_FORM_ERRORS = {
  to: '',
  message: '',
  autocompleteOptions: {},
  files: '',
};

export const EmailSection: FC<EmailSectionProps> = ({
  title,
  defaultEmailSubject = '',
}) => {
  const [formData, setFormData] = useState<FormFields>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<FormErrors>(DEFAULT_FORM_ERRORS);
  const [apiError, setApiError] = useState('');
  const [usedEmails, setUsedEmails] = useLocalStorage<string[]>(
    LOCAL_STORAGE_EMAIL_OPTIONS,
    []
  );
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const { setIsNotificationOpen, setNotificationInfo } =
    useContext(NotificationContext);

  const isDesktop = useIsDevice('desktop');
  const { isEmailSectionOpen, setIsEmailSectionOpen, checkedAttachments } =
    useContext(AttachmentsContext);

  const { isLoading, mutateAsync: onSendEmail } = useSendEmailAttachments();

  const responsiveWidth = isDesktop ? EMAIL_SECTION_WIDTH + 'px' : '100%';
  const width = isEmailSectionOpen ? responsiveWidth : '0px';

  useEffect(() => {
    formData.files = uploadedFiles;
    setFormErrors({ ...formErrors, files: '' });
  }, [uploadedFiles]);

  const handleDataChanged = (name: string, value: string[] | string | null) => {
    setFormErrors({ ...formErrors, to: '', message: '' });
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setApiError('');

    const { isFormValid, formErrors } = validateEmailForm(formData);

    setFormErrors(formErrors);

    if (isFormValid) {
      try {
        const data = getFormData({
          ...formData,
          subject: formData.subject || defaultEmailSubject,
          message: formData.message,
          attachments: checkedAttachments,
        });

        await onSendEmail(data);
        setNotificationInfo({
          status: 'success',
          text: 'The email has been sent.',
        });
      } catch (error) {
        const message =
          'Error occurred during email sending, please, try one more time or contact administrator.';
        setApiError(message);
        setNotificationInfo({
          status: 'error',
          text: message,
        });
      } finally {
        setIsNotificationOpen(true);
        setIsEmailSectionOpen(false);
        setFormData(DEFAULT_FORM_VALUES);
      }

      setUsedEmails(
        Array.from(
          new Set([
            ...usedEmails,
            ...formData.to,
            ...formData.cc,
            ...formData.bcc,
          ])
        )
      );
    }
  };

  return (
    <EmailWrapper width={width}>
      <Fade in={isEmailSectionOpen}>
        <Container>
          <TitleWrapper>
            <Title variant="h4">{title}</Title>
            {!isDesktop && (
              <CloseButton onClose={() => setIsEmailSectionOpen(false)} />
            )}
          </TitleWrapper>

          <form onSubmit={handleSubmit}>
            <Box pb={5}>
              <Autocomplete
                multiple={true}
                options={usedEmails}
                freeSolo={true}
                name="to"
                label="To:"
                placeholder="Enter email address"
                error={!!formErrors.to}
                helperText={formErrors.to}
                optionErrors={formErrors.autocompleteOptions}
                filterSelectedOptions={true}
                onChange={(_, value) => {
                  handleDataChanged('to', value);
                }}
                value={formData.to}
              />
            </Box>

            <Box pb={5}>
              <Autocomplete
                multiple={true}
                freeSolo={true}
                options={usedEmails}
                name="cc"
                label="Cc:"
                placeholder="Enter email address"
                optionErrors={formErrors.autocompleteOptions}
                filterSelectedOptions={true}
                onChange={(_, value) => {
                  handleDataChanged('cc', value);
                }}
                value={formData.cc}
              />
            </Box>

            <Box pb={7}>
              <Autocomplete
                multiple={true}
                freeSolo={true}
                options={usedEmails}
                name="bcc"
                label="Bcc:"
                placeholder="Enter email address"
                optionErrors={formErrors.autocompleteOptions}
                filterSelectedOptions={true}
                onChange={(_, value) => {
                  handleDataChanged('bcc', value);
                }}
                value={formData.bcc}
              />
            </Box>

            <Box pb={7}>
              <Input
                autoComplete="off"
                type="text"
                name="subject"
                label="Subject"
                placeholder={defaultEmailSubject}
                defaultValue={defaultEmailSubject}
                onChange={(e) =>
                  handleDataChanged(e.target.name, e.target.value)
                }
                value={formData.subject}
              />
            </Box>

            <Box pb={4}>
              <Input
                autoComplete="off"
                onChange={(e) =>
                  handleDataChanged(e.target.name, e.target.value)
                }
                rows={11}
                multiline={true}
                maxCharsCount={150}
                name="message"
                label="Message"
                placeholder="Your message"
                error={!!formErrors.message}
                helperText={formErrors.message}
                value={formData.message}
              />
            </Box>

            <Box pb={2}>
              <FileUploadSection onFileUpload={setUploadedFiles} />
              {!!formErrors.files.length && (
                <Box pt={2} color="error.main">
                  <Typography color="inherit" variant="overline">
                    {formErrors.files}
                  </Typography>
                </Box>
              )}
            </Box>

            {apiError && (
              <Box py={4}>
                <FormError error={apiError} />
              </Box>
            )}

            <Box display="flex" justifyContent="flex-end" pt={3}>
              <Box width={100} pr={2}>
                <Button
                  fullWidth={true}
                  onClick={() => setIsEmailSectionOpen(false)}
                  color="default"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Box>
              <Box width={100}>
                <Button
                  autoFocus
                  color="primary"
                  fullWidth={true}
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </Fade>
    </EmailWrapper>
  );
};
