import { Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.MuiTimeline-root': {
      flexDirection: 'column-reverse',
      flex: 'none',
      padding: 0,
      marginLeft: -10,
    },
    '&.MuiTimelineConnector-root': {
      border: `1px dashed ${theme.palette.grey[100]}`,
      background: 'none',
      marginTop: 1,
      marginBottom: 1,
    },
    '& .MuiTimelineContent-root': {
      padding: theme.spacing(2, '22px'),
    },
    '&.MuiTimelineDot-root': {
      marginTop: 2,
      marginBottom: 2,
      '&.MuiTimelineDot-outlinedGrey': {
        ShadowRoot: 'none',
        border: `1px solid ${theme.palette.grey[100]}`,
        width: 14,
        height: 14,
      },
    },
  },
  item: {
    '&.MuiTimelineItem-missingOppositeContent:before': {
      padding: 5,
    },
  },
  transparent: {
    borderColor: 'transparent !important',
  },
}));

export const Content = styled.div<{ isLastItem: boolean }>`
  width: 300px;
  margin-left: ${({ theme }) => theme.spacing(-2)}px;
  background: ${({ isLastItem, theme }) =>
    isLastItem ? theme.palette.background.default : theme.palette.grey[50]};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  min-height: 74px;

  border-radius: 4px;
  box-shadow: ${({ theme }) =>
    `inset 0px 0px 1px 0px ${theme.palette.grey[400]}`};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 435px;
    margin-left: ${({ theme }) => theme.spacing(0)}px;
  } ;
`;

export const TextDate = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const Location = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
  } ;
`;
