import { FileWithPath } from 'react-dropzone';
import { DocumentUploadSettings } from './DocumentUpload';
import { ModuleName } from '../../../../types';

export interface DocumentUploadParams {
  id: string;
  moduleName: ModuleName;
  body: {
    SI?: string;
    VIN?: string;
    WR?: string;
  };
}

export interface UploadDocumentRequestParams {
  uploadParams: DocumentUploadParams['body'];
  file: FileWithPath;
  fileName: string;
}

export const getUploadDocumentFormData = ({
  file,
  fileName,
  uploadParams,
}: UploadDocumentRequestParams): FormData => {
  const formData = new FormData();

  formData.append('file', file, fileName);
  formData.append('FileName', fileName);

  for (const [key, value] of Object.entries(uploadParams)) {
    if (value) {
      formData.append(key, value);
    }
  }

  return formData;
};

export const getFileName = (
  settings: DocumentUploadSettings,
  uploadParams: DocumentUploadParams['body']
): string => {
  let fileName = `${settings?.code}`;

  Object.keys(uploadParams).forEach((key) => {
    fileName += `_${uploadParams[key as keyof DocumentUploadParams['body']]}`;
  });

  return fileName;
};
