import { IMAGE_EXTENSIONS } from '../constants';

export const getIsImage = (extension: string): boolean =>
  IMAGE_EXTENSIONS.includes(extension);

export const getExtension = (fileName: string): string | null | undefined => {
  const regArray = /(?:\.([^.]+))?$/.exec(fileName);
  return regArray && regArray[1];
};

export const getRandomString = (): string =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);
