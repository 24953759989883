import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled(Box)<{ $hasText: boolean }>`
  margin-bottom: ${({ theme, $hasText }) =>
    $hasText ? theme.spacing(7) : 0}px;
  border-radius: ${({ $hasText }) => ($hasText ? '5px' : 0)};
  min-height: 56px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.dark};
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }

  .selected {
    background: ${({ theme }) => theme.palette.primary.dark};
    border-radius: ${({ $hasText }) => ($hasText ? '5px' : 0)};
  }
`;
