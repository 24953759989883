import { Box, Chip, TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Icon, IconName } from 'components';
import { ChangeEvent, FC, ReactNode } from 'react';
import { useStyles, StyledLabel } from './Autocomplete.styles';
import clsx from 'clsx';

export interface AutocompleteProps {
  multiple?: boolean;
  options: string[];
  freeSolo?: boolean;
  onChange: (e: ChangeEvent<{}>, value: string | string[] | null) => void;
  name: string;
  placeholder?: string;
  label?: string;
  error?: boolean;
  helperText?: ReactNode;
  optionErrors?: { [key: string]: string };
  filterSelectedOptions?: boolean;
  value: string[];
}

export const Autocomplete: FC<AutocompleteProps> = ({
  multiple = false,
  freeSolo = false,
  name,
  placeholder,
  label,
  error,
  optionErrors = {},
  helperText,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {label && (
        <StyledLabel htmlFor={name} data-testid="input-label">
          {label}
        </StyledLabel>
      )}
      <MuiAutocomplete
        id={name}
        multiple={multiple}
        freeSolo={freeSolo}
        autoSelect={true}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
          />
        )}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              key={index}
              label={option}
              size="small"
              deleteIcon={<Icon name={IconName.times} />}
              {...getTagProps({ index })}
              className={clsx(classes.chip, {
                [classes.errorChip]: !!optionErrors[option],
              })}
            />
          ))
        }
      />
    </Box>
  );
};
