import { FC, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { AttachmentsGrid } from '../AttachmentsGrid';
import {
  DocumentUpload,
  DocumentUploadParams,
  DocumentUploadSettings,
} from '../DocumentUpload';
import { setFileType } from '../AttachmentsInfo.model';
import { AttachmentView } from '../../AttachmentView';
import { splitDocuments } from './DocumentsMultipleUpload.model';
import { DocumentsBaseProps } from '../Documents';

interface DocumentsMultipleUploadProps extends DocumentsBaseProps {
  uploadParams: DocumentUploadParams;
  uploadDocumentsSettings: DocumentUploadSettings[];
}

export const DocumentsMultipleUpload: FC<DocumentsMultipleUploadProps> = ({
  documents,
  isAllChecked,
  handleCheck,
  uploadParams,
  uploadDocumentsSettings,
}) => {
  const {
    required: requiredDocs,
    rest: restDocs,
    restRequired: restRequiredDocs,
  } = useMemo(
    () => splitDocuments(documents, uploadDocumentsSettings),
    [documents]
  );

  return (
    <Box pb={10} data-testid="documents-multiple-upload">
      <AttachmentsGrid title="Required Documents" filesCount={documents.length}>
        {uploadDocumentsSettings.map((documentSetting) => (
          <DocumentUpload
            key={documentSetting.code}
            uploadParams={uploadParams}
            discardAfterUpload={false}
            document={requiredDocs[documentSetting.code]?.[0]}
            settings={documentSetting}
          />
        ))}

        {setFileType([...restRequiredDocs, ...restDocs]).map((document) => (
          <AttachmentView
            key={document.FileTitle + document.AttachDate}
            file={document}
            checked={isAllChecked}
            handleCheck={handleCheck}
          />
        ))}
      </AttachmentsGrid>
    </Box>
  );
};
