import { Attachment, CfrApiError, ModuleName } from 'types';
import { useCfrApiClient, useQueryGetRequest } from 'hooks/cfr-api';
import { MODULE_DATA_URLS } from 'hooks/cfr-api';
import { UseQueryResult } from 'react-query/types/react/types';

export const useAttachments = (
  moduleName: ModuleName,
  Txnouuid: string
): UseQueryResult<Attachment[], Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<Attachment[]>(
    client,
    `${MODULE_DATA_URLS[moduleName]}/${Txnouuid}/attachments/`
  );
};
