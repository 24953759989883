import { RefObject, useEffect, useState } from 'react';
import { throttle } from '../utils';

const checkElementHasScroll = (ref: RefObject<HTMLElement>) => {
  if (!ref?.current) {
    return false;
  }

  const current = ref.current;
  return current?.scrollWidth > current?.clientWidth;
};

export const useIsElementHaveScroll = (
  ref: RefObject<HTMLElement>
): boolean | null => {
  const [isWithScroll, setIsWithScroll] = useState(checkElementHasScroll(ref));

  useEffect(() => {
    const listener = throttle(() => {
      setIsWithScroll(checkElementHasScroll(ref));
    }, 100);

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  // update state when ref is defined
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [ref]);

  return isWithScroll;
};
