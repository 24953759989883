import { BadgeVariant } from 'components';

const valueToVariant: { [key: string]: BadgeVariant } = {
  expected: 'red',
  received: 'orange',
  readytoship: 'yellow',
  loaded: 'green',
  released: 'darkGreen',
  open: 'yellow',
  closed: 'green',
};

export const getVariant = (value: string): BadgeVariant => {
  const key = value.toLowerCase().split(' ').join('');
  return valueToVariant[key] || 'gray';
};
