import { useContext, useEffect } from 'react';
import { AttachmentsContext, AttachmentsDrawerTypes } from 'context';
import { useHistory } from 'react-router-dom';
import { ATTACHMENT_SECTION_WIDTH, EMAIL_SECTION_WIDTH } from '../constants';
import { useIsDevice } from './useIsDevice';

export interface AttachmentsHashParams {
  id: string;
  type: AttachmentsDrawerTypes;
}

export const getHashParams = (hash: string): AttachmentsHashParams | null => {
  const parts = hash.substr(1).split('/');

  if (parts?.length !== 2) {
    return null;
  }

  return {
    type: parts[0] as AttachmentsHashParams['type'],
    id: parts[1],
  };
};

export const useAttachmentsDrawerOpen = (): { drawerWidth: string } => {
  const history = useHistory();
  const isDesktop = useIsDevice('desktop');

  const {
    isEmailSectionOpen,
    setIsAttachmentsDrawerOpen,
    setIsUploadDrawerOpen,
  } = useContext(AttachmentsContext);

  useEffect(() => {
    const hashParams = getHashParams(history.location.hash);

    if (!hashParams) {
      return;
    }

    switch (hashParams.type) {
      case 'upload':
        setIsUploadDrawerOpen(true);
        break;

      case 'attachments':
        setIsAttachmentsDrawerOpen(true);
        break;
    }
  }, [history.location.hash]);

  const drawerWidthIfEmailSectionOpen = isEmailSectionOpen
    ? ATTACHMENT_SECTION_WIDTH + EMAIL_SECTION_WIDTH
    : ATTACHMENT_SECTION_WIDTH;
  const drawerWidth = isDesktop ? drawerWidthIfEmailSectionOpen + 'px' : '100%';

  return { drawerWidth };
};
