import { UserProfile } from 'types';
import { UserPermissions } from '../constants';

export const isPermitted = (
  required_permissions: readonly UserPermissions[],
  user?: UserProfile
): boolean => {
  if (!required_permissions || !required_permissions.length) {
    return true;
  }

  if (user?.user_permissions) {
    return required_permissions.every(
      (el) => user.user_permissions.indexOf(el) !== -1
    );
  }

  return false;
};
