import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import { FC, useState, ChangeEvent, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from 'styled-components';
import { TabPanel } from './TabPanel';
import { Tab } from './Tabs.model';
import { useHistory } from 'react-router-dom';
import { useStyles } from './Tabs.styles';

export interface TabsProps {
  tabs: Tab[];
  minWidth?: number;
}

export const getActiveTab = (search: string): string => {
  const params = new URLSearchParams(search);
  return params.get('tab') || '';
};

const getActiveTabIndex = (search: string, searchQueries: string[]): number => {
  const activeTabIndex = searchQueries.indexOf(getActiveTab(search)) || 0;

  return activeTabIndex === -1 ? 0 : activeTabIndex;
};

export const Tabs: FC<TabsProps> = ({ tabs, minWidth }) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const classes = useStyles({ minWidth });

  const searchQueries = tabs.map(({ searchQuery }) => searchQuery);

  const [value, setValue] = useState(
    getActiveTabIndex(history.location.search, searchQueries)
  );
  const theme = useTheme();

  const handleChange = (_: ChangeEvent<{}>, newValue: number) => {
    params.set('tab', searchQueries[newValue]);
    params.delete('filter');

    history.push({
      ...history.location,
      search: params.toString(),
    });
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  useEffect(() => {
    params.set('tab', searchQueries[value]);

    history.push({
      ...history.location,
      search: params.toString(),
    });
  }, []);

  return (
    <>
      <MuiTabs
        value={value}
        className={classes.tabs}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="common tabs"
      >
        {tabs.map(({ label, disabled }, index) => (
          <MuiTab
            className={classes.tab}
            key={index}
            label={label}
            disabled={disabled}
          />
        ))}
      </MuiTabs>
      <SwipeableViews
        containerStyle={{
          transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
          display: 'flex',
          flexGrow: 1,
        }}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.tabContainer}
      >
        {tabs.map(({ children, dir }, index) => (
          <TabPanel key={index} index={index} value={index} dir={dir}>
            {children}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
};
