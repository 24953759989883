import { Box, Typography } from '@material-ui/core';
import { Icon, IconName, Tooltip } from 'components';
import {
  FC,
  ChangeEvent,
  useState,
  useRef,
  ReactNode,
  ChangeEventHandler,
} from 'react';
import { getErrorOrElse, validateFileSize, Validation } from 'utils';
import { FileUploadButton, Files } from './FileUpload.styles';
import uniqBy from 'lodash/uniqBy';
import { FileTooltip } from '../FileTooltip';
import { IMAGE_EXTENSIONS, DOCUMENT_EXTENSIONS } from '../../../constants';

export interface FileUploadProps {
  onFileUpload: (files: File[]) => void;
  showFiles?: boolean;
  tooltipContent?: ReactNode;
}

type FileErrors = { [key: string]: string };
type ValidateFile = { [key: string]: Validation<number> };

const renderInput = (
  onChange: ChangeEventHandler<HTMLInputElement> | undefined
) => {
  const acceptedImageExtensions = IMAGE_EXTENSIONS.map(
    (ext) => `image/${ext}`
  ).join();

  const acceptedDocumentExtensions = DOCUMENT_EXTENSIONS.map(
    (ext) => `application/${ext}`
  ).join();

  return (
    <>
      <input
        multiple={true}
        type="file"
        onChange={onChange}
        onClick={(e) => {
          (e.target as HTMLInputElement).value === null;
        }}
        id="upload-file"
        style={{ display: 'none' }}
        accept={`${acceptedImageExtensions},${acceptedDocumentExtensions}`}
      />
      <label htmlFor="upload-file">
        <FileUploadButton>
          <Box color="primary.main" display="flex" alignItems="center">
            <Icon mr={2} fontSize="1.8rem" name={IconName.paperclip} />
            <Typography color="inherit" variant="overline">
              Add attachment(s)
            </Typography>
          </Box>

          <Icon fontSize="1.8rem" name={IconName.plus} />
        </FileUploadButton>
      </label>
    </>
  );
};

export const FileUpload: FC<FileUploadProps> = ({
  onFileUpload,
  showFiles = true,
  tooltipContent,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors([]);

    const uploadedFiles = e?.target?.files || [];
    const newFiles: File[] = [];
    const fileErrors: FileErrors = {};
    const validateFile: ValidateFile = {};

    Array.from(uploadedFiles).map((file) => {
      validateFile[file.name] = validateFileSize(file.size);
      fileErrors[file.name] = getErrorOrElse(validateFile[file.name], '');

      if (validateFile[file.name].isValid) {
        newFiles.push(file);
      }
    });

    const uniqFilesArray = uniqBy([...files, ...newFiles], 'lastModified');

    setFiles(uniqFilesArray);
    onFileUpload(uniqFilesArray);

    setErrors(
      Object.keys(fileErrors)
        .map((key) =>
          fileErrors[key] ? `${key} ${fileErrors[key].toLocaleLowerCase()}` : ''
        )
        .filter((error) => error)
    );
  };

  const handleRemoveFile = (name: string) => {
    const newFiles = files.filter((file) => file.name !== name);

    setFiles(newFiles);
    onFileUpload(newFiles);
  };

  return (
    <>
      <Box mt={0} mr={2} mb={2} ml={0}>
        {tooltipContent ? (
          <Tooltip placement="bottom-start" title={tooltipContent}>
            <div style={{ display: 'inline-flex' }} ref={ref}>
              {renderInput(onChange)}
            </div>
          </Tooltip>
        ) : (
          renderInput(onChange)
        )}
      </Box>

      {errors.length > 0 && (
        <Box pb={2}>
          {errors.map((error, index) => (
            <Box key={index} color="error.main">
              <Typography color="inherit" variant="overline">
                {error}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {showFiles && !!files.length && (
        <Files>
          {files.map((file) => (
            <FileTooltip
              onClose={handleRemoveFile}
              key={file.lastModified}
              fileName={file.name}
            />
          ))}
        </Files>
      )}
    </>
  );
};
