import { Drawer as MuiDrawer, DrawerProps } from '@material-ui/core';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useStyles } from './Drawer.styles';

export interface CustomDrawerProps extends DrawerProps {
  width?: string;
}

export const Drawer: FC<CustomDrawerProps> = ({
  children,
  width,
  ...props
}) => {
  const classes = useStyles({ width: width || 'auto' });
  const theme = useTheme();

  return (
    <MuiDrawer
      classes={classes}
      SlideProps={{
        onEntering: (node) => {
          node.style.webkitTransition = theme.transitions.create(
            ['-webkit-transform', 'width'],
            {
              easing: theme.transitions.easing.easeOut,
            }
          );

          node.style.transition = theme.transitions.create(
            ['transform', 'width'],
            {
              easing: theme.transitions.easing.easeOut,
            }
          );
        },
      }}
      {...props}
    >
      {children}
    </MuiDrawer>
  );
};
