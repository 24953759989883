import { FC } from 'react';
import { Icon, IconName } from 'components/common';
import { CircularProgress, Box } from '@material-ui/core';
import { Wrapper, Text, IconWrapper, CloseWrapper } from './FileTooltip.styles';
import { getIsImage, getExtension } from 'utils/string';

export interface FileTooltipProps {
  fileName: string;
  onClose: (fileName: string) => void;
  isLoading?: boolean;
}

export const FileTooltip: FC<FileTooltipProps> = ({
  fileName,
  onClose,
  isLoading,
}) => {
  const isImage = getIsImage(getExtension(fileName) || '');

  return (
    <Wrapper>
      <Box display="flex" flexDirection="row">
        {isLoading ? (
          <IconWrapper color="info.main" pr={2}>
            <CircularProgress size={17} color="inherit" />
          </IconWrapper>
        ) : (
          <IconWrapper fontSize={15} pr={2}>
            {isImage ? (
              <Icon name={IconName.pic} />
            ) : (
              <Icon name={IconName.file} />
            )}
          </IconWrapper>
        )}
        <Text variant="overline">{fileName}</Text>
      </Box>
      <CloseWrapper onClick={() => onClose(fileName)}>
        <Icon name={IconName.times} />
      </CloseWrapper>
    </Wrapper>
  );
};
