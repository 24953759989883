import { FC } from 'react';
import { RequestParams } from 'hooks/cfr-api';
import { Badge, Tooltip } from '@material-ui/core';
import { Icon, IconName, ButtonIcon } from 'components/common';
import { useHistory } from 'react-router-dom';

export interface FiltersClearUpProps {
  requestParams: RequestParams;
  setRequestParams(params: RequestParams): void;
}

export const FiltersClearUp: FC<FiltersClearUpProps> = ({
  requestParams,
  setRequestParams,
}) => {
  const filtersCount = Object.keys(requestParams?.search ?? {}).length;

  if (!filtersCount) {
    return null;
  }

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const clearFilters = () => {
    params.delete('filter');

    history.push({
      ...history.location,
      search: params.toString(),
    });

    setRequestParams({
      ...requestParams,
      search: {},
    });
  };

  return (
    <Tooltip title="Clear Up All Filters">
      <Badge badgeContent={filtersCount} color="primary">
        <ButtonIcon
          onClick={clearFilters}
          size="large"
          variant="outlined"
          endIcon={<Icon name={IconName.filter} />}
          data-testid="clear-up-filters-button"
        />
      </Badge>
    </Tooltip>
  );
};
