import {
  validateRequired,
  Validation,
  isEmailPatternValidator,
  isStringLengthLessThan150,
  isFileSizeLessThan3MB,
  isFileSizeLessThan25MB,
} from 'utils';

export const validateEmailAutocomplete = (
  value: string[]
): Validation<string[]> => validateRequired<string[]>()(value);

export const validateEmailNotRequired = (value: string): Validation<string> =>
  isEmailPatternValidator(value);

export const validateEmailMessage = (value: string): Validation<string> =>
  isStringLengthLessThan150(value);

export const validateFileSize = (value: number): Validation<number> =>
  isFileSizeLessThan3MB(value);

export const validateFilesSize = (value: number): Validation<number> =>
  isFileSizeLessThan25MB(value);
