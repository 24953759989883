import { ModuleName } from 'types';

export const MODULE_DATA_URLS = {
  [ModuleName.VEHICLE_INVENTORY]: 'reporting/vehicle-inventory',
  [ModuleName.SHIPPING_INSTRUCTIONS]: 'reporting/shipping-instruction',
  [ModuleName.WAREHOUSE_RECEIPTS]: 'reporting/warehouse-receipt',
  [ModuleName.BILL_OF_LANDING]: 'reporting/bill-of-landing',
  [ModuleName.INVOICES]: 'reporting/invoices',
  [ModuleName.LOAD_PLANS]: 'reporting/load-plans',
};
