import { FC, ReactNode } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box, Grid } from '@material-ui/core';

interface SubTableSkeletonProps {
  columnsCount: number;
  controlColumnsCount: number;
  isLoading: boolean;
  children: ReactNode;
}

export const SubTableSkeleton: FC<SubTableSkeletonProps> = ({
  columnsCount,
  controlColumnsCount,
  isLoading,
  children,
}) => {
  if (isLoading) {
    return (
      <tr>
        <td colSpan={columnsCount}>
          <Grid>
            <Box ml={9 * controlColumnsCount} mb={1}>
              <Skeleton height={50} width="70%" />
              <Skeleton height={50} width="70%" />
              <Skeleton height={50} width="70%" />
            </Box>
          </Grid>
        </td>
      </tr>
    );
  }

  return <>{children}</>;
};
