import { FC } from 'react';
import { DropDownMenu, Icon, IconName } from 'components/common';
import { IconWrapper } from './UserNavigation.styles';
import { UserMenu } from './UserMenu';

export interface UserNavigationProps {
  label: string;
  iconText: string;
}

export const UserNavigation: FC<UserNavigationProps> = ({
  label,
  iconText,
}) => (
  <DropDownMenu
    labelIcon={
      <IconWrapper>{iconText || <Icon name={IconName.at} />}</IconWrapper>
    }
    label={label}
    body={<UserMenu />}
  />
);
