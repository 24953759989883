import { useIsDevice } from 'hooks';
import { FC, useState, MouseEvent } from 'react';
import { TitleWrapper } from './DrawerCell.styles';
import { Drawer, LoaderScreen } from 'components';
import { Typography, Box } from '@material-ui/core';
import { CloseButton } from 'components/attachments/CloseButton';
import { LinkCell } from 'components/table';
import * as React from 'react';

const DRAWER_WIDTH = 600;

interface DrawerTimelineCellProps {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  onClickCallback?: () => void;
}

export const DrawerCell: FC<DrawerTimelineCellProps> = ({
  title,
  subtitle,
  isLoading = false,
  onClickCallback,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useIsDevice('desktop');

  const drawerWidth = isDesktop ? DRAWER_WIDTH + 'px' : '100%';

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(true);
    if (onClickCallback) {
      onClickCallback();
    }
  };

  return (
    <>
      <LinkCell onClick={onClick}>View</LinkCell>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        width={drawerWidth}
        onClick={(e) => e.stopPropagation()}
      >
        <Box px={16} py="58px" display="flex" flex={1} flexDirection="column">
          {isLoading ? (
            <LoaderScreen />
          ) : (
            <>
              <TitleWrapper>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h4">{title}</Typography>
                  {subtitle && (
                    <Box color="info.main">
                      <Typography
                        color="inherit"
                        variant="caption"
                        component="h3"
                      >
                        {subtitle}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <CloseButton
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }}
                />
              </TitleWrapper>

              {children}
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};
