import { useQuery } from 'react-query';
import { unwrapErrorResponse, unwrapResponse } from '../helpers';
import { AxiosInstance } from 'axios';
import { UseQueryResult } from 'react-query/types/react/types';
import { CfrApiError } from 'types';

interface QueryParams {
  enabled?: boolean;
}

export const useQueryGetRequest = <T>(
  client: AxiosInstance,
  url: string,
  params?: QueryParams
): UseQueryResult<T, Error | CfrApiError> =>
  useQuery(
    url.split('/').filter((path) => path != ''),
    () => client.get<T>(url).then(unwrapResponse).catch(unwrapErrorResponse),
    params
  );
