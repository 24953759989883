/**
 * Handles JSON.parse errors and prints to console.log
 * @return unknown | null
 */
export const safelyParseJson = (json: string): unknown | null => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.log(`JSON.parse error while parsing JSON string: '${json}'`, e);
    return null;
  }
};

/**
 * Parses JSON string as array of primitives
 * Filters array making sure that it contains only strings or numbers
 * @param json
 */
export const parseJsonArray = <T extends string | number>(
  json: string
): T[] => {
  const array = safelyParseJson(json);

  if (!array) {
    return [];
  }

  if (!Array.isArray(array)) {
    console.log(`Provided json string is not an array, string: ${json}`);
    return [];
  }

  return array.filter(
    (element) => typeof element === 'string' || typeof element === 'number'
  );
};
