import styled from 'styled-components';

export const StyledDateRangeContainer = styled.div`
  .DateRangePicker_picker {
    overflow: visible !important;
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected,
  .CalendarDay__selected:hover,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #4b5684;
    color: white;
    border: 1px solid #182350;
  }

  .DateInput {
    display: flex;
    width: 70px;
    padding: 0;
    margin: 2px 0 2px 22px;
    font-size: 10px;
    background-color: #f4f6fa;
    vertical-align: middle;
  }
  .DateInput_input {
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding: 0;
    background-color: #f4f6fa;
    border: none;
  }
  .DateRangePickerInput {
    border-radius: 4px;
    background-color: #f4f6fa;
    min-height: 24px;
  }
  .DateInput_input:focus {
    border-bottom: 1px solid #4b5684;
  }
  .DateInput_input_focused {
    border-bottom: 1px solid #4b5684;
  }
  .DateRangePickerInput_calendarIcon {
    padding: 4px 0;
    margin: 0 6px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .DayPicker_weekHeader {
    top: 58px;
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 16px;
  }
  .DateRangePickerInput_clearDates {
    margin: 0 2px;
  }
  .DateRangePickerInput_clearDates__small {
    padding: 3px;
  }
  .DateRangePickerInput_arrow {
    display: none;
  }
`;
