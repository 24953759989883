import { Column } from 'react-table';
import { UserModuleColumn } from 'hooks';

export const prepareTableColumns = <T extends object>(
  columnsObject: { [key: string]: Column<T> },
  userModuleColumns: UserModuleColumn[]
): Column<T>[] => {
  const columns = userModuleColumns
    .filter((column) => column.show)
    .map(
      (column) =>
        ({
          ...(columnsObject[column.name] ?? {}),
          Header: column.verbose_name,
          accessor: column.name,
          tooltip: column.help_text
            ? { title: column.verbose_name, description: column.help_text }
            : undefined,
        } as Column<T>)
    );

  return [...columns].sort(compareBySticky);
};

export const compareBySticky = <T extends object>(
  a: Column<T>,
  b: Column<T>
): number => {
  if (a?.isSticky && b?.isSticky) {
    return 0;
  }

  return a.isSticky ? -1 : 0;
};
