import { Column, useTable } from 'react-table';
import { Cell, HeaderTr, EmptyTdWithBorder } from './SubTable.styles';
import { useShowTooltips } from '../../../hooks';
import { useRef } from 'react';
import { HeaderCell } from '../Table/Cells/HeaderCell';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

export interface SubTableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  columnsCount: number;
  title?: string;
  controlColumnsCount: number;
}

export const SubTable = <T extends object>({
  data,
  columns,
  columnsCount,
  title,
  controlColumnsCount,
}: SubTableProps<T>) => {
  const [showTooltip] = useShowTooltips();
  const ref = useRef<HTMLDivElement>(null);

  const { headers, prepareRow, rows, visibleColumns } = useTable<T>({
    columns,
    data,
  });

  return (
    <>
      {title && (
        <tr>
          <td colSpan={controlColumnsCount} />
          <td colSpan={columns.length}>
            <Box data-testid="sub-table-title" color="info.main" py={4}>
              <Typography color="inherit" variant="caption">
                {title}
              </Typography>
            </Box>
          </td>
        </tr>
      )}

      <HeaderTr data-testid="sub-table-header">
        <td colSpan={controlColumnsCount} />

        {headers.map((column) => {
          const { key, ...headerProps } = column.getHeaderProps();

          return (
            <HeaderCell
              key={key}
              {...headerProps}
              column={column}
              tooltipRef={ref}
              showTooltip={showTooltip}
              classNames={clsx({
                ['without-border']: column?.isPlaceholder,
                ['has-top-border']: !!title,
              })}
            />
          );
        })}
      </HeaderTr>

      {!!data?.length &&
        rows.map((row, rowIndex) => {
          prepareRow(row);
          const { key, ...rowProps } = row.getRowProps();
          return (
            <tr key={key} {...rowProps}>
              <td colSpan={controlColumnsCount} />

              {row.cells.map((cell) => {
                const { key, ...cellProps } = cell.getCellProps();

                return (
                  <Cell
                    key={key}
                    {...cellProps}
                    className={clsx({
                      ['without-border']:
                        cell.column?.isPlaceholder &&
                        rowIndex !== data.length - 1,
                    })}
                  >
                    {!cell.value && !!(cell.column as Column<T>).accessor
                      ? '-'
                      : cell.render('Cell')}
                  </Cell>
                );
              })}

              {rowIndex === data.length - 1 && (
                <EmptyTdWithBorder
                  colSpan={columnsCount - visibleColumns.length}
                />
              )}
            </tr>
          );
        })}
    </>
  );
};
