import { Attachment } from '../../../../types';
import { DocumentUploadSettings } from '../DocumentUpload';

interface SplitDocuments {
  required: { [key: string]: Attachment[] };
  rest: Attachment[];
  restRequired: Attachment[];
}

export const splitDocuments = (
  documents: Attachment[],
  uploadDocumentsSettings: DocumentUploadSettings[]
): SplitDocuments => {
  const required: { [key: string]: Attachment[] } = {};
  const rest: Attachment[] = [];

  documents.forEach((document) => {
    let isRequiredDocument = false;

    uploadDocumentsSettings.forEach(({ code }) => {
      if (document.FileName.search(code) >= 0) {
        required[code] = required[code] ?? [];
        required[code].push(document);
        isRequiredDocument = true;
      }
    });

    if (!isRequiredDocument) {
      rest.push(document);
    }
  });

  const restRequired: Attachment[] = [];

  for (const key in required) {
    if (required[key].length > 1) {
      restRequired.push(...required[key].slice(1, required[key].length));
    }
  }

  return {
    required,
    rest,
    restRequired,
  };
};
