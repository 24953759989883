import { FC } from 'react';
import { FormControl, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { BootstrapInput, useStyles } from './Select.styles';
import { Option, OptionValue, Event } from './Select.model';

export type SelectSize = 'small' | 'medium';

export interface SelectProps {
  value: OptionValue;
  setValue(value: OptionValue): void;
  options: readonly Option[];
  isDisabled?: boolean;
  fullWidth?: boolean;
  size?: SelectSize;

  onChange?(value: OptionValue): void;
}

export const Select: FC<SelectProps> = ({
  value,
  setValue,
  options,
  isDisabled = false,
  onChange,
  fullWidth = false,
  size = 'medium',
}) => {
  const classes = useStyles({ size });

  const handleOptionClick = (event: Event) => {
    const value = event.target.value as OptionValue;
    setValue(value);

    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  return (
    <FormControl className={classes.formControl} fullWidth={fullWidth}>
      <MuiSelect
        classes={{
          root: classes.root,
          icon: classes.selectIcon,
        }}
        disabled={isDisabled}
        value={value}
        onChange={handleOptionClick}
        input={<BootstrapInput />}
      >
        {options.map((value) => (
          <MenuItem
            key={value.value}
            value={value.value}
            className={classes.selectMenuItem}
          >
            {value.title}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
