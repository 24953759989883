import { CfrApiError, ShippingInstruction } from 'types';
import { UseQueryResult } from 'react-query/types/react/types';
import { useCfrApiClient, useQueryGetRequest } from 'hooks/cfr-api';

export const useShippingInstruction = (
  Txnouuid: string,
  enabled?: boolean
): UseQueryResult<ShippingInstruction, Error | CfrApiError> => {
  const client = useCfrApiClient();

  return useQueryGetRequest<ShippingInstruction>(
    client,
    `reporting/shipping-instruction/${Txnouuid}/`,
    { enabled }
  );
};
