import { Typography, Box, CircularProgress } from '@material-ui/core';
import { Icon, IconName } from 'components/common/Icon';
import { FC, useState } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import {
  Container,
  Circle,
  StyledText,
  Title,
  DocumentType,
  TrashBox,
  Error,
} from './InnerDropzone.styles';
import { Attachment } from '../../../../types';

const getTitle = (
  files: FileWithPath[],
  multiple: boolean,
  document?: Attachment
): string => {
  const title = multiple
    ? `Drag and drop file(s) here or`
    : `Drag and drop file here or`;

  if (files.length) {
    return files[0].name;
  }

  if (document) {
    return document.FileName;
  }

  return title;
};

export interface InnerDropzoneProps {
  document?: Attachment;
  noDragEventsBubbling?: boolean;
  documentType?: string;
  multiple?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  isError?: boolean;
  error?: string;
  hasDeleteButton?: boolean;

  onDrop?(
    files: FileWithPath[],
    setFiles: (files: FileWithPath[]) => void
  ): void;
}

export const InnerDropzone: FC<InnerDropzoneProps> = ({
  document,
  noDragEventsBubbling = false,
  multiple = false,
  documentType,
  isLoading = false,
  disabled = false,
  isError = false,
  hasDeleteButton = true,
  error,
  onDrop,
}) => {
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open,
  } = useDropzone({
    noClick: true,
    noDragEventsBubbling,
    onDrop: (files) => {
      setFiles(files);
      onDrop && onDrop(files, setFiles);
    },
    accept: 'image/jpeg, image/png, application/pdf',
    multiple,
    disabled,
  });

  const deleteFiles = () => {
    acceptedFiles.splice(0, files.length);
    setFiles([]);
  };

  const hasFiles = files.length > 0 || !!document;
  const title = getTitle(files, multiple, document);
  const actionText = hasFiles ? 'Change file' : 'Browse files';

  return (
    <Container
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
        disabled,
      })}
    >
      <input {...getInputProps()} />
      {hasDeleteButton && hasFiles && (
        <TrashBox>
          <Icon
            onClick={() => deleteFiles()}
            color="inherit"
            fontSize="1.8rem"
            name={IconName.trash}
          />
        </TrashBox>
      )}
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={1}
      >
        {isLoading ? (
          <Box pb="11px" color="info.main">
            <CircularProgress thickness={2} color="inherit" size={38} />
          </Box>
        ) : (
          <Circle hasFiles={hasFiles} isError={isError}>
            <Icon
              fontSize="2rem"
              color="common.white"
              name={
                isError
                  ? IconName.exclamation
                  : hasFiles
                  ? IconName.check
                  : IconName.plus
              }
            />
          </Circle>
        )}
        {isError && error ? (
          <Error align="center">{error}</Error>
        ) : (
          <Box
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            width="100%"
            mb={1}
            textAlign="center"
          >
            <Title
              title={title}
              color={disabled ? 'textSecondary' : 'primary'}
              variant="overline"
            >
              {isDragReject ? 'File is not valid' : title}
            </Title>
          </Box>
        )}

        <Box color="info.main">
          {isLoading ? (
            <Typography variant="caption" color="inherit">
              Uploading ...
            </Typography>
          ) : (
            <StyledText disabled={disabled} variant="caption" onClick={open}>
              {actionText}
            </StyledText>
          )}
        </Box>
        {documentType && !isLoading && !isError && (
          <DocumentType>{documentType}</DocumentType>
        )}
      </Box>
    </Container>
  );
};
