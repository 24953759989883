import styled from 'styled-components';
import { Input } from 'components/form/Input';

export const StyledInput = styled(Input)`
  .MuiInputBase-root {
    font-size: 1rem;

    .MuiInputBase-input {
      padding: ${({ theme }) => theme.spacing('6px', 3)};
      line-height: 16px;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      z-index: 1;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`;
