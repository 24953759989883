import { createContext, FC, useState } from 'react';

interface MultiSelectFilterContextType {
  isOpen(key: string): boolean;
  toggleIsOpen(key: string): void;
}

export const defaultValues: MultiSelectFilterContextType = {
  isOpen: () => false,
  toggleIsOpen: () => null,
};
const MultiSelectFilterContext =
  createContext<MultiSelectFilterContextType>(defaultValues);

const MultiSelectFilterProvider: FC = ({ children }) => {
  const [filters, setFilters] = useState<{ [key: string]: boolean }>({});

  const isOpen = (key: string) => filters[key] ?? false;

  const toggleIsOpen = (key: string) => {
    setFilters({
      ...filters,
      [key]: !filters[key],
    });
  };

  return (
    <MultiSelectFilterContext.Provider
      value={{
        isOpen,
        toggleIsOpen,
      }}
    >
      {children}
    </MultiSelectFilterContext.Provider>
  );
};

MultiSelectFilterContext.displayName = 'MultiSelectFilterContext';
const MultiSelectFilterContextConsumer = MultiSelectFilterContext.Consumer;

export {
  MultiSelectFilterProvider,
  MultiSelectFilterContextConsumer,
  MultiSelectFilterContext,
};
