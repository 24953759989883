import { useState, useEffect } from 'react';
import { useLocalStorage } from 'hooks/useLocaleStorage';
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '../constants';

export const useIsLogged = (): boolean => {
  const [accessToken] = useLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN_KEY, '');
  const [isLogged, setIsLogged] = useState(!!accessToken);

  useEffect(() => {
    setIsLogged(!!accessToken);
  }, [accessToken]);

  return isLogged;
};
