import { FC, useEffect, useState } from 'react';
import { StyledDateInput } from './SearchDateInput.styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateFilterValue, SearchValue } from 'hooks/cfr-api';

export interface SearchDateInputProps {
  onDateChange(value: DateFilterValue | string): void;
  value: SearchValue;
}

const genDateInterval = (year: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + year);
  return date.getMonth() + '-' + date.getDate() + '-' + date.getFullYear();
};

export const SearchDateInput: FC<SearchDateInputProps> = ({
  onDateChange,
  value,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isOpened, setCalendarOpen] = useState(false);

  useEffect(() => {
    if (selectedDate && !value) {
      setSelectedDate(null);
    }
  }, [value]);

  const handleDateChange = (date: Date | null) => {
    if (date && date.getTime()) {
      setSelectedDate(date);

      onDateChange({
        year: new Intl.DateTimeFormat('en', {
          year: 'numeric',
        }).format(date),

        month: new Intl.DateTimeFormat('en', {
          month: '2-digit',
        }).format(date),

        day: new Intl.DateTimeFormat('en', {
          day: '2-digit',
        }).format(date),
      });

      setCalendarOpen(false);
    } else onDateChange('');
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDateInput
        minDate={genDateInterval(-5)}
        maxDate={genDateInterval(+5)}
        variant="inline"
        margin="none"
        format="MM-dd-yyyy"
        id="date-picker-inline"
        placeholder="mm-dd-yyyy"
        value={selectedDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        open={isOpened}
        onChange={handleDateChange}
        onOpen={() => setCalendarOpen(true)}
        onClose={() => setCalendarOpen(false)}
        data-testid="searchDateInput"
      />
    </MuiPickersUtilsProvider>
  );
};
