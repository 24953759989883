import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: center;
  }
`;

export const SelectContainer = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute !important;
    right: 0;
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(3)}px;
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;

export const useStyles = makeStyles((theme) => ({
  pagination: {
    '& button': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));
