import { Box } from '@material-ui/core';
import { IconName, Logo } from 'components/common';
import { MenuItem, ContactUsItem } from 'components';
import { FC } from 'react';
import { UserPermissions } from '../../../constants';

export interface MenuItemType {
  icon: IconName;
  text: string;
  link: string;
  user_permissions?: readonly UserPermissions[];
}

export interface MenuProps {
  menuItems: MenuItemType[];
}

export const Menu: FC<MenuProps> = ({ menuItems }) => (
  <Box
    color="common.white"
    bgcolor="primary.main"
    height="100%"
    justifyContent="space-between"
    display="flex"
    flexDirection="column"
  >
    <div>
      <Box p={8}>
        <Logo hasText={true} variant="light" />
      </Box>
      <Box p={8}>
        {menuItems.map((item) => (
          <MenuItem key={item.link} {...item} />
        ))}
      </Box>
    </div>
    <Box px={8} mb={3}>
      <ContactUsItem hasText={true} />
    </Box>
  </Box>
);
