import styled, { css } from 'styled-components';
import { Cell as CellBase } from '../Table/Table.styles';
import { Th } from '../Table/Table.styles';

const CellStyles = css`
  min-width: auto;
  min-height: auto;
  width: auto;

  &.without-border {
    border: none;
  }

  &.has-top-border:not(.without-border) {
    border-top: ${({ theme }) => `1px solid ${theme.palette.grey[100]}`};
  }
`;

export const Cell = styled(CellBase)`
  background-color: transparent;
  cursor: default;
  ${CellStyles};
`;

export const HeaderTr = styled.tr`
  ${Th} {
    background-color: transparent;
    vertical-align: middle;
    ${CellStyles};
  }
`;

export const EmptyTdWithBorder = styled.td`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
`;
