import styled from 'styled-components';
import { system, ISystem } from 'lib';
import { Box } from '@material-ui/core';

export const StyledIcon = styled(Box)<ISystem>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  svg {
    path {
      vertical-align: middle;
      fill: currentColor;
    }
  }

  ${system}
`;
