import { FC, useContext, useState } from 'react';
import { Box } from '@material-ui/core';
import {
  Tabs,
  getActiveTab,
  Drawer,
  AttachmentsContainer,
  DataBasedProps,
} from 'components';
import { useHistory } from 'react-router-dom';
import { useAttachmentsDrawerOpen } from 'hooks';
import { AttachmentsContext } from 'context';
import { VehiclesOverview } from './VehiclesOverview';
import { FreightOverview } from './FreightOverview';
import { Skeleton } from '@material-ui/lab';
import { OverviewPageHeader } from 'components/layout';
import { WarehouseReceipt, VehicleInventory, ModuleName } from 'types';
import { vehicleInventoryDocumentsSettings } from './VehiclesOverview';

export enum InventorySearchQuery {
  FREIGHT = 'freight',
  VEHICLES = 'vehicles',
}

export const getFreightDataBasedProps = (
  data: WarehouseReceipt
): DataBasedProps => ({
  title: data?.WRnumber,
  defaultEmailSubject: `Freight Details ${data?.WRnumber}`,
  uploadParams: {
    id: data.Txnouuid,
    moduleName: ModuleName.WAREHOUSE_RECEIPTS,
    body: {
      WR: data?.WRnumber,
    },
  },
});

export const getVehicleDataBasedProps = (
  data: VehicleInventory
): DataBasedProps => {
  const title = `${data?.VehicleYear} ${data?.VehicleMake} ${data?.VehicleModel}`;
  const VIN = data?.VehicleVinNumber;

  return {
    title,
    subTitle: VIN,
    defaultEmailSubject: `${title} ${VIN} ${
      data?.ShippingInstr ? '- ' + data?.ShippingInstr : ''
    }`,
    uploadParams: {
      id: data.Txnouuid,
      moduleName: ModuleName.VEHICLE_INVENTORY,
      body: {
        WR: data?.WRnumber,
        VIN: VIN?.substring(VIN?.length - 6),
      },
    },
  };
};

const getAttachmentsComponent = (search: string) => {
  switch (search) {
    case InventorySearchQuery.FREIGHT:
      return (
        <AttachmentsContainer<WarehouseReceipt>
          moduleName={ModuleName.WAREHOUSE_RECEIPTS}
          getDataBasedProps={getFreightDataBasedProps}
        />
      );
    default:
      return (
        <AttachmentsContainer<VehicleInventory>
          moduleName={ModuleName.VEHICLE_INVENTORY}
          getDataBasedProps={getVehicleDataBasedProps}
        />
      );
  }
};

const getUploadAttachmentsComponent = (search: string) => {
  switch (search) {
    case InventorySearchQuery.FREIGHT:
      return (
        <AttachmentsContainer<WarehouseReceipt>
          moduleName={ModuleName.WAREHOUSE_RECEIPTS}
          getDataBasedProps={getFreightDataBasedProps}
          hasPhotosSection={false}
          hasUploadSection={true}
        />
      );
    default:
      return (
        <AttachmentsContainer<VehicleInventory>
          moduleName={ModuleName.VEHICLE_INVENTORY}
          getDataBasedProps={getVehicleDataBasedProps}
          hasPhotosSection={false}
          hasUploadSection={true}
          uploadDocumentsSettings={vehicleInventoryDocumentsSettings}
        />
      );
  }
};

const renderLabel = (label: string, isLoading: boolean) => (
  <Box display="flex" alignItems="center">
    {label}
    {isLoading && (
      <Box ml={1}>
        <Skeleton height={30} width={50} />
      </Box>
    )}
  </Box>
);

export const VehicleInventoryOverview: FC = () => {
  const { drawerWidth } = useAttachmentsDrawerOpen();
  const history = useHistory();

  const [freightItemsCount, setFreightItemsCount] =
    useState<number | undefined>();
  const [vehicleItemsCount, setVehicleItemsCount] =
    useState<number | undefined>();

  const { isAttachmentsDrawerOpen, isUploadDrawerOpen, handleCloseAll } =
    useContext(AttachmentsContext);

  let hasAllItems = undefined;
  let totalItemsCount = undefined;
  if (freightItemsCount !== undefined && vehicleItemsCount !== undefined) {
    hasAllItems = true;
    totalItemsCount = vehicleItemsCount + freightItemsCount;
  }

  const vehicleLabel = hasAllItems
    ? `Vehicles (${vehicleItemsCount})`
    : 'Vehicles';
  const freightLabel = hasAllItems
    ? `Freight (${freightItemsCount})`
    : 'Freight';

  const tabs = [
    {
      label: renderLabel(vehicleLabel, !hasAllItems),
      children: <VehiclesOverview getItemsCount={setVehicleItemsCount} />,
      searchQuery: InventorySearchQuery.VEHICLES,
    },
    {
      label: renderLabel(freightLabel, !hasAllItems),
      children: <FreightOverview getItemsCount={setFreightItemsCount} />,
      searchQuery: InventorySearchQuery.FREIGHT,
    },
  ];

  const activeTab = getActiveTab(history.location.search);

  return (
    <>
      <OverviewPageHeader
        title="My Inventory"
        itemsCount={totalItemsCount}
        withBorder={false}
      />

      <Tabs minWidth={150} tabs={tabs} />

      <Drawer
        anchor="right"
        open={isAttachmentsDrawerOpen}
        onClose={handleCloseAll}
        width={drawerWidth}
      >
        {getAttachmentsComponent(activeTab)}
      </Drawer>

      <Drawer
        anchor="right"
        open={isUploadDrawerOpen}
        onClose={handleCloseAll}
        width={drawerWidth}
      >
        {getUploadAttachmentsComponent(activeTab)}
      </Drawer>
    </>
  );
};
